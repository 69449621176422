import React, { useState, useEffect, useParams, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { doc, setDoc } from "firebase/firestore";
import {db} from "../firebase_config";
import { FirebaseContext } from "../data/provide_firestore";
import { useAuthValue } from "../AuthContext";

const UserSettings = ({ id, site }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [Company, setCompany] = useState(null);
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { currentUser } = useAuthValue();

  const { userCompany } = useContext(FirebaseContext);

  useEffect(() => {
    if (userCompany) {
      setCompany(userCompany);
    }
  }, [userCompany]);

  const updateFieldChanged = (i) => (e) => {
    setCompany(e.target.value);
  };

  const submitSettings = async (event) => {
    event.preventDefault();

    await setDoc(doc(db, "Company", userCompany.uid), {
      uid: userCompany.uid,
      email: userCompany.email,
      company: Company,
    });
    setMsg("Saved!");
  };

  return (
    <section>
      <div
        className="pen_box"
        style={{ backgroundColor: colors.primary[400], paddingBottom: "50px" }}
      >
        <div>
          <div>
            <h3
              className="settings has-text-centered"
              style={{ color: colors.grey[100] }}
            >
              Settings
            </h3>
            <form name="form_settings" onSubmit={submitSettings}>
              <hr style={{ width: "20%" }}></hr>

              <p className="has-text-centered warning">{msg}</p>

              <div className="controls">
                <h3
                  className="settings"
                  style={{ color: colors.grey[100], marginTop: "20px" }}
                >
                </h3>
                <input
                  type="text"
                  style={{
                    width: "30%",
                    marginBottom: "20px",
                    marginTop: "-20px",
                  }}
                  className="input"
                  name="alias"
                  value={Company}
                  onChange={updateFieldChanged()}
                />
              </div>
              <button className="button-46 is-success is-fullwidth">
                Save
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserSettings;
