import { Switch, useTheme } from "@mui/material";
import { collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import React, { useEffect, useState, useContext } from 'react';
import { FirebaseContext } from "../../data/provide_firestore";
import { functions, db } from "../../firebase_config";
import { tokens } from "../../theme";
import { toast } from 'react-toastify';
import { httpsCallable } from "firebase/functions";
import SwitchManagement from '../../components/Settings_switch';
import WifiSettings from '../../components/Settings_wifi';
import TraceCustomIP from '../../components/Settings_trace';
import AliasSettings from '../../components/Settings_alias';

const Settings = ({ company, id, site, scannerData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [Alias, setAlias] = useState(null);
  const [Email, setEmail] = useState(null);
  const [PSK, setPSK] = useState(null);
  const [UNAME, setUNAME] = useState(null);
  const [UPASS, setUPASS] = useState(null);
  const [SSID, setSSID] = useState(null);
  const [BSS, setBSS] = useState(null);
  const [IP, setIP] = useState("8.8.8.8");
  const [Scanners, setScanners] = useState([]);
  const [msg, setMsg] = useState("");
  const [warning_msg, setwarning_Msg] = useState("");
  const [checked, setChecked] = useState(true);
  const [visible, setVisible] = useState(false);
  const [getConnected, setConnected] = useState(false);
  const [inputSSID, setInputSSID] = useState(true);
  const [APspec, setAPspec] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [SSIDarray, setSSIDarray] = useState([]);
  const [auth, setAuth] = useState("OPEN");

  const { getScannerInfo } = useContext(FirebaseContext);

  console.log(scannerData);

  useEffect(() => {
    fetchData();
    GetAlias();
  }, [id, site, company]);

  useEffect(() => {
    if (checked) {
      if (checked[0] === false) {
        setVisible(false);
      } else {
        setVisible(true);
      }
    }
  }, [checked, id, site]);


  const bigQuery = async () => {
    const doBigQuery = httpsCallable(functions, "get_scan");
    var data = {
      scanner: id,
    };
    const fullResult = await doBigQuery(data);
    return fullResult.data;
  };

// Update fetchData
const fetchData = async () => {
  const response = await bigQuery();
  if (response?.[0]?.[0]?.data) {
    const data = JSON.parse(response[0][0].data);
    data.sort((a, b) => {
      return parseInt(b.signal) - parseInt(a.signal);
    });
    
    const uniqueSSIDs = data.reduce((acc, item) => {
      if (item && item.ssid && !Array.from(acc).find(i => i.ssid === item.ssid)) {
        acc.add({ ssid: item.ssid, auth_suite: item.auth_suite });
      }
      return acc;
    }, new Set());

    const uniqueSSIDArray = Array.from(uniqueSSIDs);
    setSSIDarray(uniqueSSIDArray);
  }
};

const GetAlias = async () => {
  let scanners = [];
  let aliases = [];
  let alerts = [];
  try {
    if (company) {
      for (const doc of scannerData.docs) {
        const y = doc.data();
        if (y.scanner === id) {
          console.log(y);
          alerts.push(
            Boolean(y.alert_on),
            Boolean(y.alert_ms),
            Boolean(y.alert_lost)
          );
          setEmail(y.email ? y.email : "");
          setSSID(y.ssid ? y.ssid : "");
          setConnected(y.ssid ? true : false);
          setPSK(y.psk ? y.psk : "");
          setUNAME(y.uname ? y.uname : "");
          setIP(y.customIP ? y.customIP : "8.8.4.4");
          setBSS(y.bss ? y.bss : "", setAPspec(false));


          if (y.bss) {
            setAPspec(true);
          }
          if (y.psk) {
            setAuth("PSK");
          }
          if (y.uname) {
            setAuth("PEAP");
          }
          aliases = y.alias;
          scanners.push(y.scanner);
        }
      }
      setChecked(alerts);
      setScanners(scanners);
      setAlias(aliases);
    }
  } catch (error) {
    console.error(error);
  }
};


const submitSettings = async (event) => {
  event.preventDefault();

  if (isValid) {
    let payload = {
      alias: Alias,
      email: Email,
      ssid: getConnected ? SSID : null,
      customIP: IP,
      bss: APspec ? BSS : null,
      alert_on: checked[0],
      alert_ms: checked[1],
      alert_lost: checked[2],
    };

    if (auth === 'PSK') {
      payload.psk = getConnected ? PSK : null;
      payload.uname = null;
      payload.upass = null;
    } else if (auth === 'PEAP') {
      if (!UNAME || !UPASS) {
        setwarning_Msg("Username and password can't be empty!");
        setMsg("");
        return;
      }
      payload.psk = null;
      payload.uname = getConnected ? UNAME : null;
      payload.upass = getConnected ? UPASS : null;
    } else if (auth === 'OPEN') {
      payload.psk = null;
      payload.uname = null;
      payload.upass = null;
    }

    await updateDoc(doc(db, "Scanners", id), payload);
    setMsg("Saved!");
    toast.success("Saved!");
    setwarning_Msg("");
  } else {
    setwarning_Msg("Invalid AP!");
    setMsg("");
  }
};



  return (
    <section>
      <div
        className="pen_box"
        style={{
          backgroundColor: colors.primary[400],
          backgroundImage: `url(https://analyzer.hs7.se/HS_white_op2.png)`,
          backgroundSize: "70%",
          backgroundPositionX: "25%",
          backgroundRepeat: "no-repeat",
          paddingBottom: "50px",
        }}
      >
        <div>
          <div >
            <h3
              className="settings has-text-centered"
              style={{ color: colors.greenAccent[100], fontSize: "30px" }}
            >
              Settings
            </h3>
            {checked && (
              <form name="form_settings" onSubmit={submitSettings}>
                <hr style={{ width: "10%", marginTop: "-20px" }}></hr>
                <p className="has-text-centered warning">{warning_msg}</p>
                <p className="has-text-centered valid">{msg}</p>
  
                <div style={{ marginLeft: "50px", marginRight: "50px", width: "50%" }}>
                  <WifiSettings
                    getConnected={getConnected}
                    setConnected={setConnected}
                    inputSSID={inputSSID}
                    setInputSSID={setInputSSID}
                    auth={auth}
                    setAuth={setAuth}
                    SSID={SSID}
                    setSSID={setSSID}
                    SSIDarray={SSIDarray}
                    PSK={PSK}
                    setPSK={setPSK}
                    UNAME={UNAME}
                    setUNAME={setUNAME}
                    UPASS={UPASS}
                    setUPASS={setUPASS}
                    BSS={BSS}
                    setBSS={setBSS}
                    APspec={APspec}
                    setAPspec={setAPspec}
                    isValid={isValid}
                    setIsValid={setIsValid}
                  />
                   <hr style={{ width: "100%", marginTop: 20, borderTop: `3px solid ${colors.blueAccent[100]}` }} />

                  <TraceCustomIP
                    IP={IP}
                    setIP={setIP}
                  />
                  <hr style={{ width: "100%", marginTop: 20, borderTop: `3px solid ${colors.blueAccent[100]}` }} />
                  <AliasSettings
                    Alias={Alias}
                    setAlias={setAlias}
                    id={id}
                  />
                </div>
                <button className="button-46 is-success is-fullwidth">
                    Save
                  </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
export default Settings