import React, { useEffect, useRef, useState } from 'react';
import { Network } from 'vis-network';
import { DataSet } from 'vis-data';
import { Switch, FormControlLabel, IconButton, Typography } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

const NetworkGraph = ({ completeSwitches, setDialogData, highlightedNode, isGraphInitialized, setIsGraphInitialized, setGraphLoadning }) => {
  const networkRef = useRef(null);
  const networkInstance = useRef(null);
  const networkContainerRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showAccessPoints, setShowAccessPoints] = useState(false);
  const [progress, setProgress] = useState(0);
  const nodes = useRef(new DataSet());
  const edges = useRef(new DataSet());
  const knownIps = useRef([]);

  const cleanChassisId = (chassisId) => {
    return chassisId?.toLowerCase().replace(/[:\-]/g, '');
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      networkRef.current.requestFullscreen().catch(err => {
        console.error(`Error attempting to enable fullscreen mode: ${err.message}`);
      });
    } else {
      document.exitFullscreen();
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);



  const initializeGraph = () => {
    knownIps.current = completeSwitches.map(switchNode => cleanChassisId(switchNode?.chassis_id));
    completeSwitches.forEach(switchNode => {
      if (!switchNode.chassis_id) {
        return;
      }

      const cleanedSwitchChassisId = cleanChassisId(switchNode.chassis_id);

      if (!nodes.current.get(cleanedSwitchChassisId)) {
        nodes.current.add({
          ...switchNode,
          id: cleanedSwitchChassisId,
          shape: 'image',
          size: 10,
          image: process.env.PUBLIC_URL + (
            switchNode.status === 'online' ? '/switch-online.png' :
            switchNode.status === 'offline' ? '/switch-offline.png' :
            '/unidentified.png'
          )
        });
      }

      let neighbors = {};
      if (switchNode.neighbors) {
        try {
          neighbors = typeof switchNode.neighbors === 'string' 
            ? JSON.parse(switchNode.neighbors) 
            : switchNode.neighbors;
        } catch (error) {
          console.error('Error parsing neighbors for switch', cleanedSwitchChassisId, ':', error);
        }
      }

      if (typeof neighbors === 'object' && neighbors !== null) {
        Object.keys(neighbors).forEach(neighborType => {
          if (Array.isArray(neighbors[neighborType])) {
            neighbors[neighborType].forEach(neighbor => {
              const cleanedNeighborChassisId = cleanChassisId(neighbor.chassis_id);

              const linkId = `${cleanedSwitchChassisId}-${cleanedNeighborChassisId}`;
              const reverseLinkId = `${cleanedNeighborChassisId}-${cleanedSwitchChassisId}`;

              if (cleanedNeighborChassisId && knownIps.current.includes(cleanedNeighborChassisId)) {
                if (!edges.current.get(linkId) && !edges.current.get(reverseLinkId)) {
                  edges.current.add({
                    id: linkId,
                    from: cleanedSwitchChassisId,
                    to: cleanedNeighborChassisId,
                    label: neighbor.interface
                  });
                }
              } else if (cleanedNeighborChassisId) {
                if (!nodes.current.get(cleanedNeighborChassisId) && (neighborType !== 'WLAN' || showAccessPoints)) {
                  const isWLAN = neighborType === 'WLAN';
                  const icon = isWLAN ? '/ap.png' : '/unidentified.png';
                  const cleanedModel = cleanModelString(neighbor.chassis_description);

                  nodes.current.add({
                    id: cleanedNeighborChassisId,
                    label: neighbor.chassis_name,
                    shape: 'image',
                    name: neighbor.chassis_name,
                    ip: neighbor.mgmt_ip_list,
                    model: cleanedModel,
                    size: isWLAN ? 30 : 10,
                    image: process.env.PUBLIC_URL + icon,
                    title: `Hostname: ${neighbor.chassis_name}\nIP: ${cleanedNeighborChassisId}`,
                    type: 'neighbor'
                  });
                }

                if (!edges.current.get(linkId) && !edges.current.get(reverseLinkId)) {
                  edges.current.add({
                    id: linkId,
                    from: cleanedSwitchChassisId,
                    to: cleanedNeighborChassisId,
                    label: neighbor.interface
                  });
                }
              }
            });
          } else {
            console.warn(`Unexpected data type for ${neighborType} in switch ${cleanedSwitchChassisId}:`, typeof neighbors[neighborType]);
          }
        });
      } else {
        console.warn(`No valid neighbors data for switch ${switchNode.ip}`);
      }
    });

    const data = {
      nodes: nodes.current,
      edges: edges.current
    };

    const options = {
      layout: {
        hierarchical: {
          enabled: false,
          direction: 'UD',
          sortMethod: 'hubsize',
        },
        improvedLayout: false,
      },
      physics: {
        enabled: true,
        solver: 'forceAtlas2Based',
        forceAtlas2Based: {
          theta: 0.5,
          gravitationalConstant: -500,
          centralGravity: 0.04,
          springLength: 2,
          springConstant: 0.3,
          damping: 0.4,
          avoidOverlap: 0.5,
        },
        stabilization: {
          enabled: true,
          iterations: 2000,
          updateInterval: 25,
          onlyDynamicEdges: false,
          fit: true,
        },
      },
      interaction: {
        dragNodes: true,
        dragView: true,
        zoomView: true,
      },
      nodes: {
        shape: 'image',
        font: { size: 16, color: '#000000' },
        borderWidth: 2,
        shapeProperties: {
          interpolation: false,
        },
      },
      edges: {
        width: 2,
        color: { color: '#848484', highlight: '#848484', hover: '#848484' },
        arrows: { to: { enabled: false } },
      },
    };

    networkInstance.current = new Network(networkRef.current, data, options);

    networkInstance.current.on('stabilizationProgress', (params) => {
      const { iterations, total } = params;
      const percentage = Math.floor((iterations / total) * 100);
      setProgress((prevProgress) => (percentage > prevProgress ? percentage : prevProgress));
    });

    networkInstance.current.on('stabilizationIterationsDone', function () {
      setProgress(100);
      networkInstance.current.setOptions({ physics: false });
      setLoading(false);
      setGraphLoadning(false);
    });

    networkInstance.current.once('stabilized', () => {
      setLoading(false);
      setGraphLoadning(false);
      setProgress(0);
    });

    networkInstance.current.on('click', (params) => {
      if (params.nodes.length > 0) {
        const nodeId = params.nodes[0];
        const nodeData = completeSwitches.find((switchNode) => cleanChassisId(switchNode.chassis_id) === nodeId) || nodes.current.get(nodeId);
        const canvasPosition = networkInstance.current.canvasToDOM(networkInstance.current.getPositions([nodeId])[nodeId]);

        setDialogData({
          type: nodeData.type || 'switch',
          ...nodeData,
          x: canvasPosition.x,
          y: canvasPosition.y
        });
      } else {
        setDialogData(null);
      }
    });

    setIsGraphInitialized(true);
  };

  useEffect(() => {
    if (!isGraphInitialized && networkRef.current && completeSwitches.length > 0) {
      initializeGraph();
    }
  }, [completeSwitches, isGraphInitialized]);

  useEffect(() => {
    if (isGraphInitialized) {
      setLoading(true);  // Reset loading state
      setGraphLoadning(true);
      setProgress(0);     // Reset progress
  
      // Use a timeout to delay the reinitialization, giving the browser some time
      const timeoutId = setTimeout(() => {
        requestAnimationFrame(() => {
          initializeGraph();
        });
      }, 100); // Adjust the delay as needed
  
      return () => clearTimeout(timeoutId); // Cleanup on unmount
    }
  }, [showAccessPoints]);
  
  

  useEffect(() => {
    if (highlightedNode && networkInstance.current) {
      const cleanedChassisId = cleanChassisId(highlightedNode);
      networkInstance.current.selectNodes([cleanedChassisId], false);
      const position = networkInstance.current.getPositions([cleanedChassisId])[cleanedChassisId];
      networkInstance.current.moveTo({
        position: { x: position.x, y: position.y },
        scale: 1,
        offset: { x: 0, y: 0 },
      });
    }
  }, [highlightedNode]);

  const handleShowAccessPointsChange = () => {
    setShowAccessPoints(!showAccessPoints);
  };

  return (
<div style={{ position: 'relative', width: '100%', height: '85vh', border: '1px solid gray' }}>
  {loading && (
    <div className="loading-bar-container">
      <div className="loading-bar" style={{ width: `${progress}%` }}></div>
      <div className="loading-bar-text">{progress}%</div>
    </div>
  )}
  <div style={{ position: 'absolute', top: '10px', left: '10px', zIndex: 11, display: 'flex', alignItems: 'center' }}>
    <FormControlLabel
      control={
        <Switch
          checked={showAccessPoints}
          onChange={handleShowAccessPointsChange}
          name="showAccessPoints"
          color="primary"
          disabled={loading} // Disable the switch when loading is true
        />
      }
      label="Show Access Points"
    />
      </div>
      <div 
        style={{ 
          position: 'absolute', 
          top: '10px', 
          right: '10px', 
          zIndex: 11, 
          display: 'flex', 
          alignItems: 'center',
          cursor: 'pointer'
        }} 
        onClick={toggleFullscreen}
      >
        <Typography style={{ marginRight: '5px' }}>Full Screen</Typography>
        <IconButton>
          {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </IconButton>
      </div>
      <div 
        ref={networkRef} 
        style={{ 
          width: '100%', 
          height: isFullscreen ? '100vh' : '100%',
          position: isFullscreen ? 'fixed' : 'relative',
          top: isFullscreen ? 0 : 'auto',
          left: isFullscreen ? 0 : 'auto',
          zIndex: isFullscreen ? 9999 : 'auto',
          backgroundColor: 'white'
        }} 
      />
    </div>
  );
};

const cleanModelString = (model) => {
  return model.replace(/\(.*?\)\)?\s*/g, '').trim();
};

export default NetworkGraph;
