import { useState, useEffect, useCallback, useMemo, useRef } from 'react';

const useOptimizedDataFetching = (id, getScannerInfo) => {
  const [completeSwitches, setCompleteSwitches] = useState([]);
  const [loading, setLoading] = useState(true);
  const isInitialMount = useRef(true);

  const fetchData = useCallback(async (action) => {
    try {
      const response = await fetch('https://europe-west1-scanner-fb148.cloudfunctions.net/get_network', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ action, data: { scanner: id } })
      });
      const data = await response.json();
      return data.result[0];
    } catch (error) {
      console.error(`Error fetching ${action} data:`, error);
    }
  }, [id]);

  const processData = useCallback((items) => {
    return items.map(item => {
      let neighbors = item.neighbors;
      if (typeof neighbors === 'string') {
        try {
          neighbors = JSON.parse(neighbors);
        } catch (error) {
          console.error('Failed to parse neighbors:', error);
          neighbors = {};
        }
      }
      return {
        id: item.id,
        label: item.hostname,
        ip: item.ip,
        chassis_id: item.chassis_id,
        name: item.hostname,
        model: item.model,
        status: item.online ? 'online' : 'offline',
        version: item.version,
        api_access: item.api_enable,
        api_model: item.api_model,
        sw_status: item.status,
        platform: item.platform,
        neighbors: neighbors || {},
        config: item.have_config,
        logs: item.logs,
        have_logs: item.have_logs
      };
    });
  }, []);

  const fetchAllData = useCallback(async () => {
    setLoading(true);
    const response = await fetchData('get_all_data');
    if (response) {
      const newData = processData(response);
      localStorage.setItem('completeSwitches', JSON.stringify(newData));
      setCompleteSwitches(newData);
    } else {
      setCompleteSwitches([]);
    }
    setLoading(false);
  }, [fetchData, processData]);

  // Initial load
  useEffect(() => {
    const cachedData = localStorage.getItem('completeSwitches');
    if (cachedData) {
      setCompleteSwitches(JSON.parse(cachedData));
      setLoading(false);
    } else {
      fetchAllData();
    }
  }, []);

  // Handle subsequent updates
  useEffect(() => {
    if (!isInitialMount.current) {
      if (getScannerInfo?.[id]?.switch_status_update !== undefined) {
        fetchData('get_online_status').then(data => {
          if (data) {
            setCompleteSwitches(prevSwitches => 
              prevSwitches.map(sw => ({
                ...sw,
                status: data.find(item => item.id === sw.id)?.online ? 'online' : 'offline'
              }))
            );
          }
        });
      }
    }
  }, [getScannerInfo?.[id]?.switch_status_update, fetchData, id]);

  useEffect(() => {
    if (!isInitialMount.current) {
      if (getScannerInfo?.[id]?.check_API_status !== undefined) {
        fetchData('get_api_status').then(data => {
          if (data) {
            setCompleteSwitches(prevSwitches => 
              prevSwitches.map(sw => ({
                ...sw,
                api_access: data.find(item => item.id === sw.id)?.api_enable
              }))
            );
          }
        });
      }
    }
  }, [getScannerInfo?.[id]?.check_API_status, fetchData, id]);

  useEffect(() => {
    if (!isInitialMount.current) {
      if (getScannerInfo?.[id]?.updateNeighbors !== undefined) {
        fetchData('get_neighbors_status').then(data => {
          if (data) {
            setCompleteSwitches(prevSwitches => 
              prevSwitches.map(sw => ({
                ...sw,
                neighbors: data.find(item => item.id === sw.id)?.neighbors || {}
              }))
            );
          }
        });
      }
    }
  }, [getScannerInfo?.[id]?.updateNeighbors, fetchData, id]);

  useEffect(() => {
    if (!isInitialMount.current) {
      if (getScannerInfo?.[id]?.switchesFirmware !== undefined ||
          getScannerInfo?.[id]?.updateCheckStatus !== undefined ||
          getScannerInfo?.[id]?.updateRebootSwitch !== undefined) {
        fetchData('get_status_status').then(data => {
          if (data) {
            setCompleteSwitches(prevSwitches => 
              prevSwitches.map(sw => ({
                ...sw,
                sw_status: data.find(item => item.id === sw.id)?.status
              }))
            );
          }
        });
      }
    }
  }, [getScannerInfo?.[id]?.switchesFirmware, getScannerInfo?.[id]?.updateCheckStatus, getScannerInfo?.[id]?.updateRebootSwitch, fetchData, id]);

  useEffect(() => {
    if (!isInitialMount.current) {
      if (getScannerInfo?.[id]?.newSwitchCheck !== undefined ||
          getScannerInfo?.[id]?.manualCheck !== undefined) {
        fetchAllData();
      }
    }
  }, [getScannerInfo?.[id]?.newSwitchCheck, getScannerInfo?.[id]?.manualCheck, fetchAllData, id]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    }
  }, []);

  const manualRefresh = useCallback(() => {
    fetchAllData();
  }, [fetchAllData]);

  const memoizedCompleteSwitches = useMemo(() => completeSwitches, [completeSwitches]);

  return {
    completeSwitches: memoizedCompleteSwitches,
    loading,
    manualRefresh,
    fetchAllData,
    setCompleteSwitches
  };
};

export default useOptimizedDataFetching;