import { Chart } from 'react-google-charts';
import { Box, CircularProgress, useTheme, FormControlLabel, Switch, Typography  } from "@mui/material";
import { collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React, { useContext, useEffect, useState, useCallback } from "react";
import LinkIcon from '@mui/icons-material/Link';
import { useNavigate } from "react-router-dom";
import { FirebaseContext } from "../../data/provide_firestore";
import { functions } from "../../firebase_config";
import {db} from '../../firebase_config';
import { tokens } from "../../theme";
import { AreaChart, Area, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { toast } from 'react-toastify';


const Settings = ({ company, id, site }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  const [liBssid, setBssid] = useState();
  const [liNewScan, setNewScan] = useState([]);
  const [liList, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [liFreq, setFreq] = useState(2);
  const [updateChart, setUpdateChart] = useState(0);
  const [showList, setShowList] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({ ssid: '', BSS: '', auth: 'open' });
  const [psk, setPsk] = useState('');
  const [uname, setUNAME] = useState('');
  const [upass, setUPASS] = useState('');

  const { liWifiScanFire, liWifiDataFire } = useContext(FirebaseContext);

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [liWifiScanFire, id, updateChart]);

useEffect(() =>{
  if(liWifiDataFire?.[id]){
    setBssid(liWifiDataFire[id]?.[liWifiDataFire[id].length - 1]?.wifi?.[0]);
  }
}, [liWifiDataFire])

  const handleButtonClick = useCallback((BSS, ssid, auth) => {
    setModalData({ ssid, BSS, auth });
    setModalOpen(true);
  }, []);

  useEffect(() =>{
  }, [modalData])

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleConnect = () => {
    let updateData = {};
  
    if (modalData.auth === "IEEE") {
      updateData = {
        ssid: modalData.ssid,
        psk: null,
        uname: uname,
        upass: upass,
        bss: modalData.BSS
      };
    } else if (modalData.auth === "PSK") {
      updateData = {
        ssid: modalData.ssid,
        psk: psk,
        uname: null,
        upass: null,
        bss: modalData.BSS
      };
    } else { // if it's open, all are null
      updateData = {
        ssid: null,
        psk: null,
        uname: null,
        upass: null,
        bss: null
      };
    }
  
    updateDoc(doc(db, "Scanners", id), updateData);
    toast.success(`Connecting to ${modalData.ssid}...`, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
    handleModalClose();
  };
  

  const handlePskChange = (event) => {
    setPsk(event.target.value);
  };

  const handleSwitchChange = (event) => {
    setShowList(event.target.checked);
  };

  const fetchData = async () => {


    const response = await bigQuery();
    if (response?.[0]?.[0]?.data) {
      const data = JSON.parse(response[0][0].data);
      data.sort((a, b) => {
        return (
          parseInt(b.signal) - parseInt(a.signal)
        );
      });
      setNewScan(data);
      setLoading(false);
    } else {
      setNewScan(["No SSIDn found"]);
    }
  };

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const updateScan = async (event) => {
    event.preventDefault();
  
    await updateDoc(doc(db, "Scanners", id), {
      updateScan: 1,
    });
  
    setLoading(true);
  
    setTimeout(() => {
      setLoading(false);
      setUpdateChart(getRandomInt(1, 100))
    }, 7000);
  };

  const bigQuery = async () => {
    const doBigQuery = httpsCallable(functions, "get_scan");
    var data = {
      scanner: id,
    };
    const fullResult = await doBigQuery(data);
    return fullResult.data;
  };

  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    setChartData([])
  if (liNewScan?.[0]) {
    var data2 = [];

    if(liFreq === 2){
        data2 = []
        for (let i = -1; i <= 15; i++) {
          data2.push({ name: i });
        }
        // Loop through the database data and create an array for each line
        liNewScan.forEach((index) => {

          const channelNumber = parseInt(index.channel, 10);
          
            if (index.freq === 2) {
          
              if (index["signal"]) {
                const dataIndex = data2.findIndex((item) => item.name === channelNumber);
          
                if (dataIndex !== -1) {
                  data2[dataIndex-2][index.BSS] = -100;
                  data2[dataIndex-1][index.BSS] = null;
                  data2[dataIndex][index.BSS] = index["signal"];
                  data2[dataIndex][`${index.BSS}_ssid`] = 
                    !index.ssid  // If index.ssid is null or empty
                    ? "BSS: " + index.BSS
                    : isNaN(Number(index.ssid.charAt(0)))  // If the first character of index.ssid is not a number
                    ? index.ssid 
                    : "SSID: " + index.ssid;

                  data2[dataIndex+1][index.BSS] = null;
                  data2[dataIndex+2][index.BSS] = -100;                
                } else {
                  // Optional: handle the case when the channel is not found in the data2 array
                }
              }
            }
          
          


        });
      }
      if(liFreq === 5){
        data2 = []
        for (let i = 20; i <= 189; i += 4) {
          if (i >= 76 && i < 88) {
            continue; // Skip channels between 76 and 88 (non-inclusive)
          } else if (i === 144) {
            data2.push({ name: i });
            i = 149 - 4; // Jump from 144 to 149
          } else {
            data2.push({ name: i });
          }
        }
        // Loop through the database data and create an array for each line
        liNewScan.forEach((index) => {
          const channelNumber = parseInt(index.channel, 10);
          
            if (index.freq === 5) {
          
              if (index?.signal) {
                const dataIndex = data2.findIndex((item) => item.name === channelNumber);
          
                // Ensure that the channel is found in the data2 array
                if (dataIndex !== -1) {

                  const chwidthMultiplier = Math.floor(index.chwidth / 20);

                  // Set the start and end null values
                  data2[dataIndex - 2][index.BSS] = -100
                  data2[dataIndex - 1][index.BSS] = null;
                  data2[dataIndex + chwidthMultiplier][index.BSS] = null;
                  data2[dataIndex +1 + chwidthMultiplier][index.BSS] = -100
                  
                  // Fill in the values based on chwidthMultiplier
                  for (let i = 0; i < chwidthMultiplier; i++) {
                    data2[dataIndex + i][index.BSS] = index["signal"];
                    const modifiedSsid = 
                      !index.ssid  // If index.ssid is null or empty
                      ? "BSS: " + index.BSS
                      : isNaN(Number(index.ssid.charAt(0)))  // If the first character of index.ssid is not a number
                      ? index.ssid 
                      : "SSID: " + index.ssid;
                    data2[dataIndex + i][`${index.BSS}_ssid`] = modifiedSsid;

                  }
                  
                } else {
                  // Optional: handle the case when the channel is not found in the data2 array
                }
              }
            }
          
          


        });
      }
        setChartData(data2);
        
      }
    },[liNewScan, liFreq, id])

  const uniqueAddresses = Array.from(
      new Set(chartData.flatMap((item) => Object.keys(item).filter((key) => key !== 'name')))
    );

  const randomColor = () => {
    const min = 100; // Minimum color value (0 to 255)
    const max = 200; // Maximum color value (0 to 255)
    const r = Math.floor(Math.random() * (max - min) + min);
    const g = Math.floor(Math.random() * (max - min) + min);
    const b = Math.floor(Math.random() * (max - min) + min);
    return `rgb(${r}, ${g}, ${b})`;
  };
    

    const CustomXAxisTick = ({ x, y, payload, data }) => {
      // Find the data point corresponding to the current tick
      const dataPoint = data[payload.index];
    
      // Check if there is a non-null, non-undefined, and non-100 value in the data point
      const hasValidValue = Object.entries(dataPoint).some(([key, value]) => {
        if (key === 'name') {
          return false;
        }
        return value !== null && value !== undefined && value !== -100 && !isNaN(value);
      });
    
      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={16}
            textAnchor="end"
            fill={hasValidValue ? colors.grey[100] : colors.grey[400]}
          >
            {payload.value}
          </text>
        </g>
      );
    };
    
    

    const CustomTooltipContent = (props) => {
      if (props.active && props.payload && props.payload.length) {
        const filteredPayload = props.payload.filter((entry) => entry.value > -99);
        if (filteredPayload.length === 0) {
          return null;
        }
        return (
          <div className="custom-tooltip" style={{ background: 'white', padding: '10px', border: '1px solid #ccc' }}>
            <p className="label">{props.label}</p>
            {filteredPayload.map((entry, index) => (
              <p key={`payload-${index}`} style={{ color: entry.color }}>
                {entry.name}: {entry.value} ({props.payload[0].payload[`${entry.dataKey}_ssid`]})
              </p>
            ))}
          </div>
        );
      }
      return null;
    };
    
    const handleChange = (event, newValue) => {
      if (newValue !== null) {
        setFreq(newValue);
      }
    };
  
    const renderLines = () => {
      return uniqueAddresses.map((address, index) => {
        const color = randomColor(); // Store the random color in a variable
        const fillColor = color.replace('rgb', 'rgba').replace(')', ', 0.1)'); // Replace 'rgb' with 'rgba' and add opacity value 0.2
    
        return (
          <Area
            key={address}
            type="monotone"
            dataKey={address}
            stroke={color} // Use the random color for the stroke
            strokeWidth={2}
            activeDot={{ r: 8 }}
            baseValue={-100}
            fill={fillColor} // Use the same color as the stroke with opacity
            connectNulls={true}
          />
        );
      });
    };
   
useEffect(() => {
var items = <tr></tr>

if (liNewScan?.[0]) {
  items = liNewScan.map((key) => {
    if (Math.floor(Number(liFreq)) === Number(key.freq)) {
      return (
        <tr>
      <td align="center" className={key.settings} style={{ color: colors.grey[100] }}>
        <h4
         
          style={{
            color: liBssid === key.BSS ? colors.greenAccent[400] : colors.grey[100],
          }}
        >
          {key.BSS}
        </h4>
      </td>
          <td align="center" className={key.settings} style={{ color: colors.grey[100] }}>
            <h4 style={{ color: colors.grey[100] }}>
              {key.ssid}
            </h4>
          </td>
          <td align="center" className={key.settings} style={{ color: colors.grey[100] }}>
            <h4 style={{ color: colors.grey[100] }}>
            {key.freq === 5 ? "5 GHz" : "2,4 GHz"}
            </h4>
          </td>
          <td align="center" className={key.settings} style={{ color: colors.grey[100] }}>
            <h4 style={{ color: colors.grey[100] }}>
              {key.channel}
            </h4>
          </td>
          <td align="center" className={key.settings} style={{ color: colors.grey[100] }}>
            <h4 style={{ color: colors.grey[100] }}>
              {key.signal}
            </h4>
          </td>
          <td align="center" className={key.settings} style={{ color: colors.grey[100] }}>
            <h4 style={{ color: colors.grey[100] }}>
              {key.auth_suite === "IEEE" ? "802.1x" : key.auth_suite}
            </h4>
          </td>

          <td
            style={{
              position: 'relative',
              height: '100%',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '40px',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => handleButtonClick(key.BSS, key.ssid, key.auth_suite)}
            >
              <LinkIcon
              className="icon-scale"
                style={{
                  fontSize: '24px',
                  color: colors.greenAccent[400],
                }}
              />
            </div>
          </td>

        </tr>
      );
    }
  });
}
setList(items)
},[liNewScan, liFreq, theme])

return (
<section >
  <div
    className="pen_box"
    style={{
      backgroundColor: colors.primary[400],
      backgroundImage: `url(https://analyzer.hs7.se/HS_white_op2.png)`,
      backgroundSize: "70%",
      backgroundPositionX: "25%",
      backgroundRepeat: "no-repeat",
      paddingBottom: "50px",
      height: "100%"
    }}
  >
        <h3
          className="settings has-text-centered"
          style={{ color: colors.grey[100] }}
        >
          WiFi Scan
        </h3>
        <hr style={{ width: "20%" }}></hr>
        {loading ? (
          <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "rgba(0,0,0,0)",
              height: "50vh",
            }}
          >
            <CircularProgress sx={{ color: colors.greenAccent[500] }} />
            <h3
                  className="settings has-text-centered"
                  style={{ color: colors.grey[100] }}
                >
                  Scanning...
                </h3>
          </Box>

                </>
        ) : (
          <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginLeft: '0px',
              marginRight: '0px',
            }}
          >
            <ToggleButtonGroup
              value={liFreq}
              exclusive
              onChange={handleChange}
              aria-label="Frequency selection"
            >
              <ToggleButton
                value={2}
                aria-label="2.4 GHz"
                style={{ marginBottom: '20px' }}
              >
                2.4 GHz
              </ToggleButton>
              <ToggleButton value={5} aria-label="5 GHz">
                5 GHz
              </ToggleButton>
            </ToggleButtonGroup>
            <ToggleButton onClick={updateScan} style={{ marginBottom: '20px' }}>
              Scan
            </ToggleButton>
          </div>
          <div
style={{
  backgroundColor: 'rgba(211, 211, 211, 0)', // Opacity 0.2 with RGB for grey[100]
  height: '500px',
  borderRadius: '10px', // 10px radius for rounded corners
  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)', // horizontal offset, vertical offset, blur radius, color
}}

  >
            <ResponsiveContainer width="100%" height="100%" >
              <AreaChart
                width={500}
                height={300}
                data={chartData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                style={{ backgroundColor: "rgba(0,0,100,0.05)",  
                borderRadius: '10px', }}
              >
                <CartesianGrid
                  strokeDasharray="3 3"
                  vertical={false}
                  horizontal={false}
                />
                <XAxis
                  dataKey="name"
                  tick={<CustomXAxisTick data={chartData} />}
                />
                <YAxis tick={{ fill: colors.grey[100] }} domain={[-100, 0]} />
                <Tooltip content={<CustomTooltipContent />} />
                {renderLines()}
              </AreaChart>
            </ResponsiveContainer>
            </div>

            <div style={{marginTop: "20px"}}>
      <FormControlLabel
        control={
          <Switch
            checked={showList}
            onChange={handleSwitchChange}
            name="showListSwitch"
            color="primary"
          />
        }
        label={
          <Typography style={{ color: colors.grey[100] }}>Show List</Typography>
        }
      />
      {showList && <div> 
    

            <table style={{backgroundColor: "rgba(0,0,0,0.05)", width:"100%", borderRadius: '10px',}}>
  <tr>
    <th className='settings'><h4 style={{color: colors.grey[100], fontSize : "18px"}}>AP</h4></th>
    <th className='settings'><h4 style={{color: colors.grey[100],fontSize : "18px"}}>SSID</h4></th>
    <th className='settings'><h4 style={{color: colors.grey[100],fontSize : "18px"}}>Frequency</h4></th>
    <th className='settings'><h4 style={{color: colors.grey[100],fontSize : "18px"}}>Channel</h4></th>
    <th className='settings'><h4 style={{color: colors.grey[100],fontSize : "18px"}}>Signal Strenght</h4></th>
    <th className='settings'><h4 style={{color: colors.grey[100],fontSize : "18px"}}>Authentication</h4></th>
    <th className='settings'><h4 style={{color: colors.grey[100],fontSize : "18px"}}>Connect to AP</h4></th>
  </tr>
{liList}
                
                </table>
                </div>}
                </div>
          </>
        )}
        </div>
        <Dialog open={modalOpen} onClose={handleModalClose}>
        <DialogTitle>Connect</DialogTitle>
        
        <DialogContent>
  <h5 style={{color: "red"}}>WARNING - This will connect to a specific AP and not roam!!</h5>
  <TextField
    label="SSID"
    value={modalData.ssid}
    fullWidth
    InputProps={{ readOnly: true }}
  />
  <TextField
    label="AP / BSSID"
    value={modalData.BSS}
    fullWidth
    InputProps={{ readOnly: true }}
  />
  
  {modalData.auth === "IEEE" && (
    <>
      <TextField
        label="Username"
        value={uname}
        onChange={event => setUNAME(event.target.value)}
        fullWidth
      />
      <TextField
        label="Password"
        type="password"
        value={upass}
        onChange={event => setUPASS(event.target.value)}
        fullWidth
      />
    </>
  )}

{modalData.auth === "PSK" && (
  <TextField
    label="Pre-shared Key"
    value={psk}
    onChange={event => setPsk(event.target.value)}
    fullWidth
  />
)}

</DialogContent>

        <DialogActions>
          <Button onClick={handleModalClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConnect} color="primary">
            Connect
          </Button>
        </DialogActions>
      </Dialog>
        </section>
    );
    
}

export default Settings;
