import { useTheme } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import React, { useEffect, useState } from 'react';
import { tokens } from "../theme";

const LineChart = ({ isCustomLineColors = false, isDashboard = false, sqlData, setGoogleNow }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [liSql, setSql] = useState([{"id": "WAN", color: "hsl(149, 70%, 50%)", "data": [{"x": 0, "y": 0}]}]);
  const [liMax, setMax] = useState(10);
  const [liMin, setMin] = useState(0);

  const generateLast15Timestamps = () => {
    const now = new Date();
    
    // If the current minute is even, step back 2 minutes.
    // If it's odd, step back just 1 minute.
    now.setMinutes(now.getMinutes() - (now.getMinutes() % 2 === 0 ? 2 : 1));

    const timestamps = [];
    for (let i = 0; i < 15; i++) {
        const time = new Date(now.getTime() - i * 2 * 60 * 1000); // 2 minutes step backwards
        const formattedTime = `${time.getHours().toString().padStart(2, '0')}:${time.getMinutes().toString().padStart(2, '0')}`; // Format as HH:mm
        timestamps.unshift(formattedTime); // Add at the beginning to have them in ascending order
    }
    return timestamps;
};



  

  useEffect(() => {
    const fetchData = async () => {
      let waitdata = await sqlData;
      if(waitdata){
        const last15Timestamps = generateLast15Timestamps();
        const GoogleData = {};
        
        // Populate the GoogleData with fetched data
        waitdata.forEach(sqlrows => {
          const time = sqlrows?.dt[1];
          const ping = sqlrows?.ping[4];
          GoogleData[time] = ping;
        });
  
        // Create the data array for the graph
        const data = last15Timestamps.map(time => ({
          x: time,
          y: GoogleData[time] || 0 // If no data for this time, default to 0
        }));
  
        const GoogleValues = Object.values(GoogleData);
        const max = Math.max(...GoogleValues);
        const min = Math.min(...GoogleValues);
        const diff = max * 0.5;
        setMin(min - diff);
        setMax(max * 1.5);
  
        const test = [
          {
            "id": "Google", 
            color: tokens("dark").blueAccent[300], 
            "data": data
          }
        ];
        console.log(test[0]?.data[14]?.y);
        setGoogleNow(test[0]?.data[14]?.y)
        setSql(test);
      }
    }
    
    fetchData()
      .catch(console.error);
  }, [sqlData]);
  

  return (
    <ResponsiveLine
      data={liSql}
          theme={{
            axis: {
              domain: {
                line: {
                  stroke: colors.grey[100],
                },
              },
              legend: {
                text: {
                  fill: colors.grey[100],
                },
              },
              ticks: {
                line: {
                  stroke: colors.grey[100],
                  strokeWidth: 1,
                },
                text: {
                  fill: colors.grey[100],
                },
              },
            },
            legends: {
              text: {
                fill: colors.grey[100],
              },
            },
            tooltip: {
              container: {
                color: colors.primary[500],
              },
            },
          }}
          colors={isDashboard ? { datum: "color" } : { scheme: "nivo" }} // added
          margin={{ top: 50, right: 30, bottom: 50, left: 30 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: 0,
            max: liMax,
            stacked: true,
            reverse: false,
          }}
          yFormat=" >-.2f"
          curve="monotoneX"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: "bottom",
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            legend: isDashboard ? undefined : "transportation", // added
            legendOffset: 36,
            legendPosition: "middle",
          }}
          axisLeft={{
            orient: "left",
            tickValues: 5, // added
            tickSize: 3,
            tickPadding: 5,
            tickRotation: 0,
            legend: isDashboard ? undefined : "count", // added
            legendOffset: -40,
            legendPosition: "middle",
          }}
          enableGridX={false}
          enableGridY={false}
          pointSize={0}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh={true}
          legends={[]}
          animate={false}
        />
      );
    };
    
    export default LineChart;