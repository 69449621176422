import { useTheme } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import React, { useEffect, useState } from 'react';
import { tokens } from "../theme";

const LineChart_Small = ({
  chdata,
  isCustomLineColors = false,
  isDashboard = true,
  sqlData,
  setGateway,
  setCustom,
  setStockholm,
  setNewyork
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [liSql, setSql] = useState([
    { id: "WAN", color: "hsl(149, 70%, 50%)", data: [{ x: 0, y: 0 }] },
  ]);
  const [liMax, setMax] = useState(10);
  const [liMin, setMin] = useState(0);

  const generateLast15Timestamps = () => {
    const now = new Date();
    
    // If the current minute is even, step back 2 minutes.
    // If it's odd, step back just 1 minute.
    now.setMinutes(now.getMinutes() - (now.getMinutes() % 2 === 0 ? 2 : 1));

    const timestamps = [];
    for (let i = 0; i < 15; i++) {
        const time = new Date(now.getTime() - i * 2 * 60 * 1000); // 2 minutes step backwards
        const formattedTime = `${time.getHours().toString().padStart(2, '0')}:${time.getMinutes().toString().padStart(2, '0')}`; // Format as HH:mm
        timestamps.unshift(formattedTime); // Add at the beginning to have them in ascending order
    }
    return timestamps;
};


useEffect(() => {
  const fetchData = async () => {
    let waitdata = await sqlData;
    if (waitdata) {
      const last15Timestamps = generateLast15Timestamps();

      const Gateway = {};
      const Stockholm = {};
      const Custom = {};
      const Newyork = {};

      // Populate the location data with fetched data
      waitdata.forEach((sqlrows) => {
        const time = sqlrows.dt[1];
        Gateway[time] = sqlrows.ping[5];
        Stockholm[time] = sqlrows.ping[0];
        Custom[time] = sqlrows.ping[1];
        Newyork[time] = sqlrows.ping[3];
      });

      let data;
      if (chdata === "Gateway") {
        data = last15Timestamps.map(time => ({
          x: time,
          y: Gateway[time] || 0,
        }));
        setGateway(data[14]?.y)
      } else if (chdata === "Stockholm") {
        data = last15Timestamps.map(time => ({
          x: time,
          y: Stockholm[time] || 0,
        }));
        setStockholm(data[14]?.y)
      } else if (chdata === "Newyork") {
        data = last15Timestamps.map(time => ({
          x: time,
          y: Newyork[time] || 0,
        }));
        setNewyork(data[14]?.y)
      } else {
        data = last15Timestamps.map(time => ({
          x: time,
          y: Custom[time] || 0,
        }));
        setCustom(data[14]?.y)
      }

      const dataValues = data.map(d => d.y);
      const max = Math.max(...dataValues);
      const min = Math.min(...dataValues);
      const diff = max * 0.5;
      setMin(min - diff);
      setMax(max * 1.5);

      const lineColor = tokens("dark").blueAccent[300];
      let lineData = [{ id: chdata, color: lineColor, data: data }];
      setSql(lineData);
    }
  };

  fetchData().catch(console.error);
}, [sqlData]);


  return (
    <ResponsiveLine
      data={liSql}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[0],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[400],
              strokeWidth: 0,
              display: false,
            },
          },
        },
        tooltip: {
          container: {
            color: colors.primary[500],
            display: false,
          },
        },
      }}
      colors={isDashboard ? { datum: "color" } : { scheme: "nivo" }}
      margin={{ top: 10, right: 0, bottom: 10, left: 0 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: liMin,
        max: liMax,
        stacked: false,
        reverse: false,
      }}
      yFormat=" >-.2f"
      curve="catmullRom"
      axisTop={null}
      axisRight={null}
      axisLeft={{
        orient: "left",
        tickValues: 0, // added
        tickSize: 0,
        tickPadding: 0,
        tickRotation: 0,
        legendOffset: -40,
        legendPosition: "middle",
      }}
      enableGridX={false}
      enableGridY={false}
      pointSize={0}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointLabelYOffset={-12}
      useMesh={true}
      animate={false}
    />
  );
};

export default LineChart_Small;
