import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import TextField from '@mui/material/TextField';
import { collection, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthValue } from '../AuthContext';
import { FirebaseContext } from '../data/provide_firestore';
import { db } from '../firebase_config';
import { auth } from "../firebase_config";
import { signOut } from 'firebase/auth';

const inputProps = {
  style: {
    color: "black", // change the color to black
    backgroundcolor: "black",
  },
};

export default function FormDialog({ setCompanyModal, uid, email }) {
  const [open, setOpen] = useState(true);
  const [getCompany, setCompany] = useState(null);
  const [getCloseCompany, setCloseCompany] = useState(null);
  const navigate = useNavigate();
  const { currentUser } = useAuthValue();
  const { userCompany } = useContext(FirebaseContext);

  useEffect(() => {
    GetCompany();
  }, []);

  const GetCompany = async (event) => {
    const dataRef = query(
      collection(db, "Company"),
      where("uid", "==", currentUser?.uid)
    );
    const dataSnap = await getDocs(dataRef);
    setCompany(dataSnap.docs[0]?.data().company);
    setCloseCompany(dataSnap.docs[0]?.data().company);
  };

  const updateFieldChanged = (i) => (e) => {
    setCompany(e.target.value);
  };

  const submitSettings = async (event) => {
    event.preventDefault();

    await setDoc(doc(db, "Company", currentUser.uid), {
      uid: currentUser.uid,
      email: currentUser.email,
      company: getCompany,
    });
    setOpen(false);
    setCompanyModal(false);
    window.location.reload();
    navigate("/dashboard");
  };

  const handleClose = () => {
    if (getCloseCompany) {
      setCompanyModal(false);
      setOpen(false);
    }
  };
  const Logout = async () => {
    try {
      signOut(auth);
      navigate("/");
    } catch (error) {
    }
  };

  return (
    <ScopedCssBaseline>
      <div>
        
        <Dialog open={open} onClose={handleClose}>
        <h4 style={{fontSize:"20px", marginLeft:"10px", marginTop:"5px", marginBottom:"5px"}}>Company</h4>
          <DialogTitle style={{ backgroundColor: "white", color: "black" }}>
            Add/Change Company to account
          </DialogTitle>
          <DialogContent style={{ backgroundColor: "white", color: "black" }}>
            <TextField
              sx={{
                input: { color: "black" },

                "& .MuiInputLabel-root": { color: "black" },
                borderBottom: "1px solid black",
              }}
              InputProps={{ disableUnderline: true }}
              variant="standard"
              value={getCompany}
              fullWidth
              onChange={updateFieldChanged()}
            />
          </DialogContent>
          <DialogActions style={{ backgroundColor: "white" }}>
            <Button onClick={Logout}>Log Out</Button>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={submitSettings}>Add</Button>
          </DialogActions>
        </Dialog>
      </div>
    </ScopedCssBaseline>
  );
}
