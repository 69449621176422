import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import { useNavigate } from 'react-router-dom'; 
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import SummarizeIcon from '@mui/icons-material/Summarize';
import LanIcon from '@mui/icons-material/Lan';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import MediationOutlinedIcon from '@mui/icons-material/MediationOutlined';
import { Box, IconButton, Typography, useTheme, Tooltip } from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import moment from "moment";
import React, { useContext, useEffect, useState } from 'react';
import { Menu, MenuItem, ProSidebar, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link, useLocation } from "react-router-dom";
import { useAuthValue } from '../../AuthContext';
import FormAddScanner from '../../components/modal_addScanner';
import { FirebaseContext } from '../../data/provide_firestore';
import { db } from '../../firebase_config';
import { tokens } from "../../theme";
import Wireshark from '../../components/modal_wireshark';
import { ToastContainer } from 'react-toastify';
import { grey } from '@mui/material/colors';

const Sidebar = ({ probes, name, company, id, swManagers }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const [iconColor, setIconColor] = useState([]);
  const [getAddScannerModal, setAddScannerModal] = useState(false);
  const [getWiresharkModal, setWiresharkModal] = useState(false);
  const [getCompany, setCompany] = useState(null);
  const [getWiresharkID, setWiresharkID] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(0);
  const { liDataFire, liUptimeWireFire, liUptimeWifiFire, liWifiDataFire, trigger } = useContext(FirebaseContext);
  const { currentUser } = useAuthValue();
  const [openSubmenu, setOpenSubmenu] = useState("network-analyzer");

  const navigate = useNavigate(); // Initialize useNavigate hook


  console.log(selected, swManagers);

  const handleOpenSubMenu = (menuName) => {
    if (menuName === "switch-management"){
      setActiveSubMenu(null);
    }
    setOpenSubmenu((prevMenu) => (prevMenu === menuName ? null : menuName));
  };

  const Item = ({ title, to, icon, select_data, selected, setSelected }) => {
    const isActive = selected === select_data;

    const handleClick = () => {
      setSelected(select_data);
      if (select_data === 'Dashboard') {
        setActiveSubMenu(null);
      }
    };

    return (
      <MenuItem
        active={isActive}
        style={{ color: colors.grey[100] }}
        onClick={handleClick}
        icon={icon}
      >
        <Typography
          variant="h6"
          sx={{
            m: "5px 0 5px 0px",
            fontWeight: isActive ? 'bold' : 'normal',
            fontSize: isActive ? '16px' : 'inherit'
          }}
        >
          {title}
        </Typography>
        <Link to={to} />
      </MenuItem>
    );
  };

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    console.log(pathParts);
    if (pathParts.length > 2) {
      probes.forEach((probe, index) => {
        console.log(index);
        if (probe.scanner === pathParts[1]) {
          if (pathParts[2] === "swmgmt"){
            setActiveSubMenu(null);
          }
          else{
            setActiveSubMenu(index);
            setSelected(pathParts[1] + "-" + pathParts[2]);
          }
        }
      });
    } else {
      if (pathParts[1] === "dashboard") {
        setActiveSubMenu(null);
        setSelected("Dashboard");
      }
    }
  }, [location.pathname, probes]);

  const maxChar = 17; // Maximum number of characters you want to display

  const displayText = (text) => {
    return text && text.length > maxChar
      ? text.slice(0, maxChar) + "..."
      : text;
  };

  const GetCompany = async () => {
    const dataRef = query(
      collection(db, "Company"),
      where("uid", "==", currentUser?.uid)
    );
    const dataSnap = await getDocs(dataRef);
    setCompany(dataSnap.docs[0]?.data().company);
  };

  const handleOpenChange = (index, scanner) => {
    if (activeSubMenu === index) {
      setActiveSubMenu(null);
    } else {
      setActiveSubMenu(index);
      setSelected(scanner + "-eth");
    }
  };

  async function fetchLink() {
    if (probes) {
      const AllData = await Promise.all(
        probes.map((probe) => {
          var Color = [];
          let WifiTime = parseInt(liUptimeWifiFire?.[probe?.scanner], 10);
          let WireTime = parseInt(liUptimeWireFire?.[probe?.scanner], 10);
          if (Number.isNaN(WifiTime)) {
            WifiTime = 0;
          }
          if (Number.isNaN(WireTime)) {
            WireTime = 0;
          }
          let wire_waitdata = liDataFire?.[probe.scanner];
          let wifi_waitdata = liWifiDataFire?.[probe.scanner];
          const currentTime = Number(
            moment().subtract(3, "minutes").format("YYYYMMDDHHmm")
          );

          if (
            wire_waitdata?.[wire_waitdata.length - 1]?.dt?.length ||
            wifi_waitdata?.[wifi_waitdata.length - 1]?.dt?.length
          ) {
            let ethcolor = { color: colors.redAccent[500] };
            let wificolor = { color: colors.redAccent[500] };
            let mainColor = { color: colors.redAccent[500] };

            if (WireTime !== 0 && WireTime >= currentTime) {
              ethcolor = { color: colors.greenAccent[500] };
            }
            if (WifiTime !== 0 && WifiTime >= currentTime) {
              wificolor = { color: colors.greenAccent[500] };
            }

            if (
              (ethcolor.color === colors.greenAccent[500]) ||
              (wificolor.color === colors.greenAccent[500])
            ) {
              mainColor = { color: colors.greenAccent[500] };
            }
            Color.push({
              color: mainColor,
              ethcolor: ethcolor,
              wificolor: wificolor,
              alias: probe.alias,
              scanner: probe.scanner,
            });
          } else {
            Color.push({
              color: { color: colors.redAccent[500] },
              ethcolor: { color: colors.redAccent[500] },
              wificolor: { color: colors.redAccent[500] },
              fontSize: "22px",
              alias: probe.alias,
              scanner: probe.scanner,
            });
          }
          return Color;
        })
      );
      setIconColor(AllData);
    }
  }

  useEffect(() => {
    GetCompany();
    fetchLink();
  }, [trigger]);

  useEffect(() => {
    if (id) {
      probes.map((rows) => {
        if (rows.scanner === id) {
          setSelected(rows.scanner);
        }
      });
    }
  }, []);

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 5px 5px 0px !important",
          marginLeft: "-10px"
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          fontWeight: "bold",
          fontSize: "14px",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          {!isCollapsed && (
            <Box mb="20px">
              <Box textAlign="center">
                <img
                  src="https://analyzer.hs7.se/loggo 2.png"
                  alt="Network Analyzer"
                  width="180"
                />
              </Box>
            </Box>
          )}

          <Box
            paddingLeft={isCollapsed ? undefined : "10%"}
            style={{
              backgroundImage: `url(https://analyzer.hs7.se/HS_white_op3.png)`,
              backgroundSize: "250%",
              backgroundPositionX: "left",
              backgroundPositionY: "top",
              backgroundRepeat: "no-repeat",
            }}
          >
        <SubMenu
          key="network-analyzer"
          title={
            <Typography
            variant="body1"
            sx={{
              fontWeight: openSubmenu === "network-analyzer" ? 'bold' : 'normal',  // Set bold if open
              color: grey[800],
              fontSize: '17px',
              transition: 'font-weight 0.3s ease',  // Smooth transition
              '&:hover': {
                fontWeight: 'bold',
              }
            }}
          >
              Network Analyzer
            </Typography>
          }
          icon={<SsidChartIcon sx={{color: colors.primary[300]}} />}
          // Open this submenu if `openSubmenu` is "network-analyzer"
          open={openSubmenu === "network-analyzer"}
          // Toggle the state when the submenu is clicked
          onOpenChange={() => {handleOpenSubMenu("network-analyzer");
            setSelected("Dashboard"); // Set the first item as selected
            navigate("/Dashboard"); // Programmatically navigate to the first item
        }}
        >
        <Item
          key="Dashboard"
          select_data="Dashboard"
          title="Dashboard"
          to="/dashboard"
          icon={<HomeOutlinedIcon />}
          selected={selected}
          setSelected={setSelected}
        />
  
  {iconColor.map((y, index) => (
    <div key={y[0].scanner + "-network-items"}>
      <SubMenu
        key={y[0].scanner + "3"}
        open={activeSubMenu === index}
        title={
          <Link to={"/" + y[0].scanner + "/eth"} style={{ textDecoration: 'none' }}>
            <Typography
              variant="body1"
              sx={{
                fontWeight: activeSubMenu === index ? 'bold' : 'normal',
                color: grey[800],
                fontSize: '17px',
                transition: 'font-weight',
                '&:hover': {
                  fontWeight: 'bold',
                }
              }}
            >
              <span title={y[0].alias ? y[0].alias : y[0].scanner}>
                {displayText(y[0].alias ? y[0].alias : y[0].scanner)}
              </span>
            </Typography>
          </Link>
        }
        icon={<SsidChartIcon sx={y[0].color} />}
        className={activeSubMenu === index ? 'active-submenu' : 'inactive-submenu'}
        onOpenChange={() => handleOpenChange(index, y[0].scanner)}
      >
      {/* All items except Switch Management */}
      <Item
        key={y[0].scanner + "-eth"}
        select_data={y[0].scanner + "-eth"}
        title="Ethernet Data"
        to={"/" + y[0].scanner + "/eth"}
        icon={<SettingsEthernetIcon sx={y[0].ethcolor} />}
        selected={selected}
        setSelected={setSelected}
      />
      <Item
        key={y[0].scanner + "-wifi"}
        select_data={y[0].scanner + "-wifi"}
        title="WiFi Data"
        to={"/" + y[0].scanner + "/wifi"}
        icon={<WifiIcon sx={y[0].wificolor} />}
        selected={selected}
        setSelected={setSelected}
      />
      <Item
        key={y[0].scanner + "-wifiscan"}
        select_data={y[0].scanner + "-wifiscan"}
        title="WiFi Scan"
        to={"/" + y[0].scanner + "/scan"}
        icon={<WifiTetheringIcon />}
        selected={selected}
        setSelected={setSelected}
      />
      <Item
        key={y[0].scanner + "-history"}
        select_data={y[0].scanner + "-history"}
        title="History"
        to={"/" + y[0].scanner + "/history"}
        icon={<SummarizeIcon />}
        selected={selected}
        setSelected={setSelected}
      />
      <Item
        key={y[0].scanner + "-pentest"}
        select_data={y[0].scanner + "-pentest"}
        title="Pentest Data"
        to={"/" + y[0].scanner + "/pentest"}
        icon={<AnalyticsOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      />
      <Item
        key={y[0].scanner + "-settings"}
        select_data={y[0].scanner + "-settings"}
        title="Settings"
        to={"/" + y[0].scanner + "/settings"}
        icon={<SettingsIcon />}
        selected={selected}
        setSelected={setSelected}
      />
      </SubMenu>
      {getCompany && (
              <MenuItem
                key="addScanner"
                style={{
                  color: colors.grey[100],
                }}
                onClick={() => {
                  setAddScannerModal(true);
                }}
                icon={<AnalyticsOutlinedIcon />}
              >
                <Typography variant="h6" sx={{ m: "5px 0 5px 0px" }}>
                  + Add Analyzer
                </Typography>
              </MenuItem>
            )}
    </div>
  ))}

  {/* Add Analyzer */}
  {!iconColor.length && (
    <MenuItem
      key="addScanner"
      onClick={() => setAddScannerModal(true)}
      icon={<AnalyticsOutlinedIcon />}
    >
      + Add Analyzer
    </MenuItem>
  )}
</SubMenu>
<SubMenu
  key="switch-management"
  
  title={
    <Typography
      variant="body1"
      sx={{
        fontWeight: openSubmenu === "switch-management" ? 'bold' : 'normal',  // Set bold if open
        color: grey[800],
        fontSize: '17px',
        transition: 'font-weight 0.3s ease',  // Smooth transition
        '&:hover': {
          fontWeight: 'bold',
        }
      }}
    >
      Switch Management
    </Typography>
  }
  icon={<AnalyticsOutlinedIcon sx={{ color: colors.primary[300] }} />}
  // Open this submenu if `openSubmenu` is "switch-management"
  open={openSubmenu === "switch-management"}
  // Toggle the state when the submenu is clicked
  onOpenChange={() => {
    handleOpenSubMenu("switch-management");

    // Check if the first item is not selected yet, and the submenu is being opened
    if (swManagers.length > 0) {
      setSelected(swManagers[0]?.name + "-swmgmt"); // Set the first item as selected
      navigate("/" + swManagers[0]?.name + "/swmgmt"); // Programmatically navigate to the first item
    }
  }}
>
  {swManagers.map((y, index) => (
    <>
    <Item
      key={y?.name + "-swmgmt"}
      select_data={y?.name + "-swmgmt"}
      title={
        <span title={y?.alias ? y?.alias : y?.name}>
          {displayText(y?.alias ? y?.alias : y?.name)}
        </span>
      }
      to={"/" + y?.name + "/swmgmt"}  // This handles the navigation
      icon={<LanIcon />}
      selected={selected}
      setSelected={setSelected}
    />
    <Item
    key={y?.name + "-switchSettings"}
    select_data={y?.name + "-switchSettings"}
    title="Settings"
    to={"/" + y?.name + "/switchSettings"}
    icon={<SettingsIcon />}
    selected={selected}
    setSelected={setSelected}
  />
  </>
  ))}
    {getCompany && swManagers.length === 0 && (
          <MenuItem
            key="addScanner"
            style={{
              color: colors.grey[100],
            }}
            onClick={() => {
              setAddScannerModal(true);
            }}
            icon={<AnalyticsOutlinedIcon />}
          >
            <Typography variant="h6" sx={{ m: "5px 0 5px 0px" }}>
              + Add Switch Manager
            </Typography>
          </MenuItem>
        )}
</SubMenu>




    </Box>
        </Menu>
        {getAddScannerModal && (
          <FormAddScanner setAddScannerModal={setAddScannerModal} />
        )}
        {getWiresharkModal && (
          <Wireshark setWiresharkModal={setWiresharkModal} id={id} wid={getWiresharkID} />
        )}

        <div
          style={{
            backgroundImage: `url(https://analyzer.hs7.se/HS_white_op1.png)`,
            backgroundSize: "70%",
            backgroundPositionX: "25%",
            backgroundRepeat: "no-repeat",
            position: "absolute",
            bottom: "20px",
            left: "20px",
          }}
        ></div>
      </ProSidebar>
      <IconButton
        onClick={() => setIsCollapsed(!isCollapsed)}
        sx={{
          position: 'absolute',
          top: '10px',
          left: isCollapsed ? '10px' : '220px',
          zIndex: 1000,
          backgroundColor: colors.primary[400],
          color: 'white',
        }}
      >
        <MenuOutlinedIcon />
      </IconButton>
    </Box>
  );
};

export default Sidebar;
