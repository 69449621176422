import React, { useState } from 'react';
import { 
  Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tooltip, Typography, TablePagination, IconButton, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button,
  Popper, Grow, Paper, ClickAwayListener
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CircularProgress from '@mui/material/CircularProgress';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CircleIcon from '@mui/icons-material/Circle';

const SwitchTable = ({
  sortedFilteredSwitches, 
  handleRequestSort, 
  orderBy, 
  order, 
  headerToPropertyMap, 
  getApiAccessStatus, 
  isUpgradeAvailable, 
  handleRebootClick, 
  handleRowClick, 
  selectedSwitchInTable, 
  handleUpgradeClick, 
  isUpgradeDisabled, 
  fetchConfigAndDownload, 
  setIsDialogOpen, 
  setErrorDialogOpen, 
  setSelectedFailedSwitch,
  availableVersions,
  newSwitchLoading,
  get_loggs,
  delete_id,
  updateApi,
  id,
  graphLoading,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedSwitchNode, setSelectedSwitchNode] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isVersionLessThan = (version, target) => {
    const versionParts = version.slice(3).split('.').map(Number);
    const targetParts = target.split('.').map(Number);

    for (let i = 0; i < versionParts.length; i++) {
      if (versionParts[i] < targetParts[i]) {
        return true;
      }
      if (versionParts[i] > targetParts[i]) {
        return false;
      }
    }
    return false;
  };

  const handleOpenMenu = (event, switchNode) => {
    console.log(switchNode);
    setAnchorEl(event.currentTarget);
    setSelectedSwitchNode(switchNode);
    setOpenMenu(true);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
    handleCloseMenu();
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const paginatedRows = sortedFilteredSwitches.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <Table style={{ backgroundColor: "rgba(0,0,0,0.05)", width: "100%", borderRadius: '10px', border: '1px solid black' }}>
        <TableHead>
          <TableRow>
            {['NAME', 'IP', 'VERSION', 'MODEL', 'PLATFORM', 'STATUS', 'APIACCESS', 'Options'].map((header) => (
              <TableCell
                key={header}
                onClick={() => handleRequestSort(header)}
                style={{
                  cursor: 'pointer',
                  backgroundColor: 'white',
                  color: 'black',
                  fontWeight: orderBy === headerToPropertyMap[header] ? 'bold' : 'normal',
                  fontSize: '16px',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                <TableSortLabel
                  active={orderBy === headerToPropertyMap[header]}
                  direction={orderBy === headerToPropertyMap[header] ? order : 'asc'}
                  IconComponent={orderBy === headerToPropertyMap[header] ? (order === 'asc' ? ArrowUpwardIcon : ArrowDownwardIcon) : null}
                >
                  {header}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {paginatedRows.map((switchNode, index) => {
            const apiAccess = getApiAccessStatus(switchNode.api_access);
            const { available: upgradeAvailable, latestVersion } = isUpgradeAvailable(switchNode.version, availableVersions);
            let versionCheck = switchNode.version.startsWith('WC') && isVersionLessThan(switchNode.version, '16.10.0011');
            const showUpgradeIcon = upgradeAvailable && latestVersion && switchNode.sw_status === 0 && switchNode.api_access === 1;

            let statusText = switchNode.statusText;
            let isReboot = false;
            let statusColor = 'inherit';
            let statusContent;

            switch (switchNode.sw_status) {
              case 1:
                statusText = "Image Upgrade initiating...";
                break;
              case 2:
                statusText = "Upgrade in Progress...";
                break;
              case 3:
                statusText = (
                  <Tooltip placement="top" title={<Typography fontSize="12px">Click here to reboot switch, to finalize the upgrade</Typography>}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }}>
                      <img
                        src={process.env.PUBLIC_URL + '/reboot_icon.png'}
                        alt="Reboot Icon"
                        style={{ width: '20px', height: '20px', marginRight: '5px' }}
                      />
                      Reboot
                    </div>
                  </Tooltip>
                );
                isReboot = true;
                break;
              case 4:
                statusText = "Rebooting...";
                break;
              case 10:
                statusText = (
                  <div style={{ cursor: 'pointer', color: 'red' }} onClick={() => {
                    setSelectedFailedSwitch(switchNode);
                    setErrorDialogOpen(true);
                  }}>
                    Upgrade failed. Click for info
                  </div>
                );
                break;
              default:
                statusText = switchNode.status === 'online' ? 'Online' : 'Offline';
                statusColor = switchNode.status === 'online' ? 'green' : 'red';
            }

            if (isReboot) {
              statusContent = (
                <TableCell style={{ textAlign: 'center', cursor: 'pointer', color: statusColor }} onClick={() => handleRebootClick(switchNode)}>
                  {statusText}
                </TableCell>
              );
            } else {
              statusContent = (
                <TableCell style={{ textAlign: 'center', color: statusColor }}>
                  {statusText}
                </TableCell>
              );
            }

            return (
              <TableRow 
                key={index} 
                style={{ fontWeight: selectedSwitchInTable === switchNode.ip ? 'bold' : 'normal' }}
              >
              <TableCell
                onClick={switchNode.chassis_id && !graphLoading ? () => handleRowClick(switchNode.chassis_id) : undefined}
                style={{
                  cursor: switchNode.chassis_id && !graphLoading ? 'pointer' : 'default',
                  textAlign: 'center',
                  fontWeight: selectedSwitchInTable === switchNode.ip ? 'bold' : 'normal',
                  color: switchNode.chassis_id && !graphLoading ? 'inherit' : 'grey', // Grey out text if chassis_id is null or if graph is loading
                }}
              >
                {switchNode.name} 
              </TableCell>

                <TableCell style={{ textAlign: 'center' }}>{switchNode.ip}</TableCell>
                <TableCell style={{ textAlign: 'center', padding: 0 }}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <Tooltip
                      placement="top"
                      title={
                        versionCheck ? (
                          <Typography fontSize="12px">
                            Upgrade disable for 2930F &lt; WC.16.10.0011, API issues
                          </Typography>
                        ) : (
                          ''
                        )
                      }
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: versionCheck ? 'red' : 'inherit',
                          cursor: versionCheck ? 'not-allowed' : 'default',
                        }}
                      >
                        <span>{switchNode.version}</span>
                        {versionCheck && <InfoOutlinedIcon style={{ marginLeft: '5px' }} />}
                      </div>
                    </Tooltip>
                    {showUpgradeIcon && (
                      <Tooltip
                        placement="top"
                        title={<Typography fontSize="12px">New image version available: {latestVersion.sw_version}</Typography>}
                      >
                        <span>
                          <img
                            src={process.env.PUBLIC_URL + '/upgrade_icon.png'}
                            alt="Upgrade Available"
                            style={{
                              width: '20px',
                              height: '20px',
                              cursor: isUpgradeDisabled ? 'not-allowed' : 'pointer',
                              opacity: isUpgradeDisabled ? 0.5 : 1,
                              marginLeft: '5px',
                            }}
                            onClick={isUpgradeDisabled ? null : () => handleUpgradeClick(switchNode, latestVersion.sw_version)}
                          />
                        </span>
                      </Tooltip>
                    )}
                  </div>
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>{switchNode.model}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{switchNode.platform}</TableCell>
                {statusContent}
                {apiAccess.tooltip ? (
                  <Tooltip 
                    placement="top" 
                    title={<Typography fontSize="12px">{apiAccess.tooltip}</Typography>}
                  >
                    <TableCell 
                      style={{ 
                        textAlign: 'center', 
                        color: apiAccess.color, 
                        cursor: [5, 6, 10, 11].includes(apiAccess.status) ? 'pointer' : 'default', 
                      }}
                      onClick={() => setIsDialogOpen(true)}
                    >
                      {apiAccess.status === 2 && newSwitchLoading ? <CircularProgress size={24} /> : apiAccess.text}
                    </TableCell>
                  </Tooltip>
                ) : (
                  <TableCell 
                    style={{ 
                      textAlign: 'center', 
                      color: apiAccess.color, 
                      cursor: [5, 6, 10, 11].includes(apiAccess.status) ? 'pointer' : 'default', 
                    }}
                    onClick={() => setIsDialogOpen(true)}
                  >
                    {apiAccess.status === 2 && newSwitchLoading ? <CircularProgress size={24} /> : apiAccess.text}
                  </TableCell>
                )}<TableCell style={{ textAlign: 'center', position: 'relative' }}>
                <Tooltip
                  placement="top"
                  title={
                    switchNode.have_logs ? (
                      <Typography fontSize="12px">Logs are available</Typography>
                    ) : (
                      ''
                    )
                  }
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: switchNode.have_logs ? 'orange' : 'inherit',
                      position: 'relative',
                    }}
                  >
                    <IconButton
                      aria-haspopup="true"
                      onClick={(event) => handleOpenMenu(event, switchNode)}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    {switchNode.have_logs ? (
                      <WarningAmberIcon style={{ position: 'absolute', right: '0px' }} />
                    ) : (
                      ''
                    )}
                  </div>
                </Tooltip>
                  <Popper
                    open={openMenu && selectedSwitchNode?.id === switchNode.id}
                    anchorEl={anchorEl}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleCloseMenu}>
                            <Menu
                              anchorEl={anchorEl}
                              open={openMenu}
                              onClose={handleCloseMenu}
                            >
                              <MenuItem 
                                onClick={() => {
                                  fetchConfigAndDownload(selectedSwitchNode.ip, id, selectedSwitchNode);
                                  handleCloseMenu();
                                }}
                                disabled={selectedSwitchNode && selectedSwitchNode.config != 1}
                              >
                                Download Config
                              </MenuItem>
                              <MenuItem 
                                onClick={() => {
                                  updateApi(switchNode.ip);
                                  handleCloseMenu();
                                }}
                                disabled={selectedSwitchNode && selectedSwitchNode.api_access == 1}
                              >
                                Check API
                              </MenuItem>
                              <MenuItem 
                                onClick={() => {
                                  get_loggs(selectedSwitchNode.id);
                                  handleCloseMenu();
                                }}
                                disabled={selectedSwitchNode && selectedSwitchNode.have_logs == 0}
                              >
                                Show Logs                     
                                {switchNode.have_logs ? (
                      <CircleIcon fontSize="small" style={{ marginLeft: '5px', color: 'red' }} />
                    ) : (
                      ''
                    )}
                              </MenuItem>
                              <MenuItem 
                              onClick={handleOpenConfirmDialog}>
                                Delete
                              </MenuItem>
                            </Menu>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={sortedFilteredSwitches.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={() => {
            delete_id(selectedSwitchNode.id);
            handleCloseConfirmDialog();
          }} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SwitchTable;