import React, { useContext, useState, useEffect } from "react";
import { Box, IconButton, Menu, MenuItem, useTheme, Typography } from "@mui/material";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MenuIcon from '@mui/icons-material/Menu';
import { signOut } from 'firebase/auth';
import { useNavigate } from "react-router-dom";
import FormDialog from "../../components/modal_company";
import { auth } from "../../firebase_config";
import { ColorModeContext, tokens } from "../../theme";
import { FirebaseContext } from "../../data/provide_firestore";

const Topbar = ({ setIsSidebar, toggleSidebar }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate();
  const [getCompanyModal, setCompanyModal] = useState(false);
  const [getEmail, setEmail] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const { userCompany } = useContext(FirebaseContext);
  
  function getAuth() {
    auth.onAuthStateChanged(user => {
      if (user) {
        setEmail(user.email)
      } else {
        // No user is signed in.
      }
    });
  }

  useEffect(() => {
    getAuth()
  }, [])

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const Logout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };


  return (
    <Box 
      display="flex" 
      justifyContent="space-between" 
      alignItems="center" 
      p={1}
      position="relative"  // Add this
    >
      {/* Left side */}
      <Box>
        <IconButton onClick={toggleSidebar} sx={{ marginRight: 1 }}>
          <MenuIcon />
        </IconButton>
      </Box>

      {/* Center */}
      <Box 
        position="absolute"
        left="50%"
        sx={{ transform: 'translateX(-50%)' }}
      >
        <Typography variant="h6">
          Company: {userCompany}
        </Typography>
      </Box>

      {/* Right side */}
      <Box display="flex" alignItems="center">
        <Box mr={2}>
          {getEmail}
        </Box>

        <Box display="flex">
          {getCompanyModal && <FormDialog setCompanyModal={setCompanyModal} />}
          <IconButton
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            onMouseOver={handleClick}
          >
            <PersonOutlinedIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{ onMouseLeave: handleClose }}
          >
            <MenuItem onClick={() => setCompanyModal(true)}>Set Company</MenuItem>
            <MenuItem onClick={Logout}>Logout</MenuItem>
          </Menu>
        </Box>
      </Box>
    </Box>
  );
}

export default Topbar;