import { Switch, useTheme } from "@mui/material";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { FormControl, InputLabel, OutlinedInput, FormHelperText, FormControlLabel, Typography, FormLabel, RadioGroup, Radio, Select, MenuItem } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthValue } from '../AuthContext';
import { FirebaseContext } from '../data/provide_firestore';
import { db } from '../firebase_config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../customDatePicker.css';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import enGB from 'date-fns/locale/en-GB';
import { styled } from '@mui/system';
import { tokens } from "../theme";
import { toast } from 'react-toastify';
import { storage } from "../firebase_config";
import { ref, getDownloadURL } from 'firebase/storage';

const CustomInput = ({ value, onClick }) => (
  <div className="react-datepicker__input-container">
    <input type="text" value={value} onClick={onClick} readOnly />
  </div>
);

const StyledRadio = styled(Radio)(({ theme }) => ({
  '&.Mui-checked': {
    color: "black",
  },
  color: theme.palette.grey[200],
}));

export default function Wireshark({ setWiresharkModal, uid, email, id, wid }) {

  const defaultStartTime = () => {
    let time = new Date(); // get current date
    time.setMilliseconds(0); // reset milliseconds
    time.setSeconds(0); // reset seconds
  
    // round up minutes to the nearest 15
    let m = time.getMinutes();
    let remain = 15 - (m % 15);
    if (remain !== 15) {
      time.setMinutes(m + remain);
    }
  
    return time;
  };
  
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const { currentUser } = useAuthValue();
  const { userCompany } = useContext(FirebaseContext);

  const [startTime, setStartTime] = useState(defaultStartTime());
  const [endTime, setEndTime] = useState(new Date(startTime.getTime() + 15 * 60 * 1000));
  const [WSTime, setWSTime] = useState(15);
  const [currentWSTime, setCurrentWSTime] = useState(15);
  const [currentStartTime, setCurrentStartTime] = useState(null);
  const [url, setUrl] = useState(null);

  
  registerLocale('en-GB', enGB);
  setDefaultLocale('en-GB');

  const handleWSTimeChange = (event) => {
    const number = parseInt(event.target.value, 10);
    setWSTime(number);
};

useEffect(() => {
  // create a storage reference to your file
  const storageRef = ref(storage, id + ".pcap");

  // get the download URL and save it in state
  getDownloadURL(storageRef)
    .then((downloadURL) => {
      setUrl(downloadURL);
    })
    .catch((error) => {
      console.error(`Failed to get download URL: ${error}`);
    });
}, []);

const handleDateChange = (date) => {
  const newStartTime = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    startTime.getHours(),
    startTime.getMinutes()
  );
  setStartTime(newStartTime);
};

const handleTimeChange = (time) => {
  const newStartTime = new Date(
    startTime.getFullYear(),
    startTime.getMonth(),
    startTime.getDate(),
    time.getHours(),
    time.getMinutes()
  );
  setStartTime(newStartTime);
};

function formatDatetime(datetime) {
  const year = "20" + datetime.substring(0, 2);
  const month = datetime.substring(2, 4);
  const day = datetime.substring(4, 6);
  const hour = datetime.substring(7, 9);
  const minute = datetime.substring(9, 11);

  return `${year}-${month}-${day} ${hour}:${minute}`;
}

  const formatDate = (date) => {
    const YY = String(date.getFullYear()).slice(-2);
    const MM = String(date.getMonth() + 1).padStart(2, '0');
    const DD = String(date.getDate()).padStart(2, '0');
    const hh = String(date.getHours()).padStart(2, '0');
    const mm = String(date.getMinutes()).padStart(2, '0');

    return `${YY}${MM}${DD}T${hh}${mm}`;
  };

  const formattedStartTime = formatDate(startTime);
  const formattedEndTime = formatDate(endTime);


  useEffect(() => {
    GetAlias()
    
  }, []);

  const GetAlias = async () => {
    try {
        var dataRef = [];
          dataRef = query(
            collection(db, "Scanners"),
            where("scanner", "==", wid.toLowerCase())
          );
        const dataSnap = await getDocs(dataRef);
        dataSnap.docs.map((y) => {
          if (y.data().scanner === wid) {
            if (y.data().ws_start){
              const datetimeformated = formatDatetime(y.data().ws_start)
              setCurrentStartTime(datetimeformated)
              setCurrentWSTime(y.data().ws_time)
            }
            
          }
        });
    } catch (error) {
      console.error();
    }
  };

  const SetWireshark = async (event) => {
    toast.success(`Saved!`, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
    let payload = {
      ws_time: WSTime,
      ws_start: formattedStartTime,
    };
    await updateDoc(doc(db, "Scanners", wid), payload);
    setWiresharkModal(false);
    setOpen(false);
  };

  const handleClose = () => {
    setWiresharkModal(false);
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle style={{ backgroundColor: "white", color: "black" }}>
        <h3 style={{ backgroundColor: "white", color: "black"}}>Schedule Wireshark for Ethernet interface</h3>
        <hr></hr>
        <h4 style={{ backgroundColor: "white", color: "black" }}>Start Time</h4>
        
       
        <div style={{ backgroundColor: "white", color: "black" }}>
      
        <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem', alignItems: 'flex-start' }}>
          <div style={{ display: 'inline-block', height: '100%' }}>
            <DatePicker
              selected={startTime}
              onChange={handleDateChange}
              dateFormat="yyyy-MM-dd"
              customInput={<CustomInput />}
              inline
              weekStartsOn={0}
            />
          </div>

          <div style={{ display: 'inline-block', height: '100%' }}>
            <DatePicker
              selected={startTime}
              onChange={handleTimeChange}
              showTimeSelect
              showTimeSelectOnly
              timeFormat="HH:mm"
              timeIntervals={15}
              customInput={<CustomInput />}
              inline
            />
          </div>
        </div>

      
        <FormControl>
    <FormLabel style={{ color: "black", marginBottom: "0px", }} id="auth-radio-buttons-group-label">Duration:</FormLabel>
    <RadioGroup
      row
      aria-labelledby="demo-row-radio-buttons-group-label"
      name="row-radio-buttons-group"
      value={WSTime}
      onChange={handleWSTimeChange}
    >
  <FormControlLabel style={{ color: "black",  marginLeft: "0px" }} value="15" control={<StyledRadio />} label="15min" />
  <FormControlLabel style={{ color: "black" }} value="30" control={<StyledRadio />} label="30min" />
  <FormControlLabel style={{ color: "black" }} value="45" control={<StyledRadio />} label="45min" />
  <FormControlLabel style={{ color: "black" }} value="60" control={<StyledRadio />} label="60min" />

    </RadioGroup>
  </FormControl>

    </div>
    {currentStartTime && (
  
  <div>
     <hr></hr>
    <span style={{ color: 'black' }}>Current activation: </span>
    <span style={{ color: 'green' }}> {currentStartTime}</span>
    <br />
    <span style={{ color: 'black' }}> Duration: </span>
    <span style={{ color: 'green' }}>{ currentWSTime}</span>
    <br style={{ marginTop: "40px" }}></br>
    {url ? (
    <a href={url} download>
     <Button style={{ marginTop: "10px" }} variant="outlined" color="primary" >Download</Button>
    </a>
  ) : (
    <button variant="outlined" color="primary" disabled>Download</button>
  )}
  </div>  
)}

    </DialogTitle>
    
        <DialogActions style={{ backgroundColor: "white" }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={SetWireshark}>Set</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
