import { Switch, useTheme } from "@mui/material";
import { collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import React, { useEffect, useState, useContext } from 'react';
import { FirebaseContext } from "../../data/provide_firestore";
import { functions, db } from "../../firebase_config";
import { tokens } from "../../theme";
import { toast } from 'react-toastify';
import { httpsCallable } from "firebase/functions";
import SwitchManagement from '../../components/Settings_switch';
import AliasSettings from '../../components/Settings_alias';

const SwitchSettings = ({ company, id, site, swData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [Alias, setAlias] = useState('');
  const [Email, setEmail] = useState(null);
  const [msg, setMsg] = useState("");
  const [warning_msg, setwarning_Msg] = useState("");
  const [checked, setChecked] = useState(null);
  const [visible, setVisible] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const [apiUsername, setApiUsername] = useState('admin');
  const [apiPassword, setApiPassword] = useState('');
  const [useSameForAOS, setuseSameForAOS] = useState(true);
  const [apiUsernameAOS, setApiUsernameAOS] = useState('');
  const [apiPasswordAOS, setApiPasswordAOS] = useState('');
  const [sshUsername, setSshUsername] = useState('');
  const [sshPassword, setSshPassword] = useState('');
  const [useSameForSSH, setUseSameForSSH] = useState(true);
  const [snmpCommunity, setSnmpCommunity] = useState('');

  const { getScannerInfo } = useContext(FirebaseContext);

  useEffect(() => {
    GetAlias();
  }, [id, site, company]);

  useEffect(() => {
    if (checked) {
      if (checked[0] === false) {
        setVisible(false);
      } else {
        setVisible(true);
      }
    }
  }, [checked, id, site]);

  const encryptPassword = async (password) => {
    const encryptFunction = httpsCallable(functions, 'encryptPassword');
    try {
      const result = await encryptFunction({ password });
      return result.data;
    } catch (error) {
      console.error('Error encrypting password:', error);
    }
  };
  
  const decryptPassword = async (iv, encryptedData) => {
    const decryptFunction = httpsCallable(functions, 'decryptPassword');
    try {
      const result = await decryptFunction({ iv, encryptedData }); // Adjust the payload structure
      console.log("Decryption Result:", result.data); // Log decryption result
      return result.data;
    } catch (error) {
      console.error('Error decrypting password:', error);
    }
  };


const GetAlias = async () => {
  for (const doc of swData.docs) {
    const y = doc.data();
    console.log(y);
    if (y.name === id) {
          const decryptedSshPassword = y.ssh_password_enc ? await decryptPassword(y.ssh_password_enc.iv, y.ssh_password_enc.encryptedData) : "";
          const decryptedApiPasswordCX = y.api_password_cx_enc ? await decryptPassword(y.api_password_cx_enc.iv, y.api_password_cx_enc.encryptedData) : "";
          const decryptedApiPasswordAOS = y.api_password_aos_enc ? await decryptPassword(y.api_password_aos_enc.iv, y.api_password_aos_enc.encryptedData) : "";

          setSshPassword(decryptedSshPassword.password);
          setApiPassword(decryptedApiPasswordCX.password);
          setApiPasswordAOS(decryptedApiPasswordAOS.password);

          if (decryptedSshPassword.password !== decryptedApiPasswordCX.password){
            setUseSameForSSH(false);
          }
          if (decryptedApiPasswordAOS.password !== decryptedApiPasswordCX.password){
            setuseSameForAOS(false);
          }

          setSshUsername(y.ssh_username ? y.ssh_username : "");
          setApiUsername(y.api_username_cx ? y.api_username_cx : "");
          setApiUsernameAOS(y.api_username_aos ? y.api_username_aos : "");
          setSnmpCommunity(y.snmp ? y.snmp : "");

          setAlias(y.alias);
        }
      }
  };


const submitSettings = async (event) => {
  event.preventDefault();

  if (isValid) {
    const finalApiUsernameAOS = useSameForAOS ? apiUsername : (apiUsernameAOS || (await decryptPassword(getScannerInfo?.[id]?.api_password_aos_enc.iv, getScannerInfo?.[id]?.api_password_aos_enc.encryptedData)));
    const finalApiPasswordAOS = useSameForAOS ? apiPassword : (apiPasswordAOS || (await decryptPassword(getScannerInfo?.[id]?.api_password_aos_enc.iv, getScannerInfo?.[id]?.api_password_aos_enc.encryptedData)));
    const finalSshUsername = useSameForSSH ? apiUsername : (sshUsername || getScannerInfo?.[id]?.ssh_username);
    const finalSshPassword = useSameForSSH ? apiPassword : (sshPassword || (await decryptPassword(getScannerInfo?.[id]?.ssh_password_enc.iv, getScannerInfo?.[id]?.ssh_password_enc.encryptedData)));

    // Encrypt passwords if they are in plain text and not already encrypted
    const encryptedSshPassword = useSameForSSH ? await encryptPassword(apiPassword) : (sshPassword ? await encryptPassword(finalSshPassword) : getScannerInfo?.[id]?.ssh_password_enc);
    const encryptedApiPasswordCX = useSameForSSH ? await encryptPassword(apiPassword) : (apiPassword ? await encryptPassword(apiPassword) : getScannerInfo?.[id]?.api_password_cx_enc);
    const encryptedApiPasswordAOS = useSameForAOS ? await encryptPassword(apiPassword) : (apiPasswordAOS ? await encryptPassword(finalApiPasswordAOS) : getScannerInfo?.[id]?.api_password_aos_enc);
    console.log(apiPassword);
    let payload = {
      alias: Alias,
      ssh_username: finalSshUsername,
      ssh_password_enc: {
        iv: encryptedSshPassword.iv,
        encryptedData: encryptedSshPassword.encryptedData
      },
      snmp: snmpCommunity,
      api_username_cx: apiUsername || getScannerInfo?.[id]?.api_username_cx,
      api_password_cx_enc: {
        iv: encryptedApiPasswordCX.iv,
        encryptedData: encryptedApiPasswordCX.encryptedData
      },
      api_username_aos: finalApiUsernameAOS,
      api_password_aos_enc: {
        iv: encryptedApiPasswordAOS.iv,
        encryptedData: encryptedApiPasswordAOS.encryptedData
      },
    };


    await updateDoc(doc(db, "SwitchMgmt", id), payload);
    setMsg("Saved!");
    toast.success("Saved!");
    setwarning_Msg("");
  } else {
    setwarning_Msg("Invalid AP!");
    setMsg("");
  }
};



  return (
    <section>
      <div
        className="pen_box"
        style={{
          backgroundColor: colors.primary[400],
          backgroundImage: `url(https://analyzer.hs7.se/HS_white_op2.png)`,
          backgroundSize: "70%",
          backgroundPositionX: "25%",
          backgroundRepeat: "no-repeat",
          paddingBottom: "50px",
        }}
      >
        <div>
          <div >
            <h3
              className="settings has-text-centered"
              style={{ color: colors.greenAccent[100], fontSize: "30px" }}
            >
              Switch Management Settings
            </h3>
              <form name="form_settings" onSubmit={submitSettings}>
                <hr style={{ width: "10%", marginTop: "-20px" }}></hr>
                <p className="has-text-centered warning">{warning_msg}</p>
                <p className="has-text-centered valid">{msg}</p>
  
                <div style={{ marginLeft: "50px", marginRight: "50px", width: "50%" }}>
                  <SwitchManagement
                    apiUsername={apiUsername}
                    setApiUsername={setApiUsername}
                    apiPassword={apiPassword}
                    setApiPassword={setApiPassword}
                    useSameForAOS={useSameForAOS}
                    setuseSameForAOS={setuseSameForAOS}
                    apiUsernameAOS={apiUsernameAOS}
                    setApiUsernameAOS={setApiUsernameAOS}
                    apiPasswordAOS={apiPasswordAOS}
                    setApiPasswordAOS={setApiPasswordAOS}
                    sshUsername={sshUsername}
                    setSshUsername={setSshUsername}
                    sshPassword={sshPassword}
                    setSshPassword={setSshPassword}
                    useSameForSSH={useSameForSSH}
                    setUseSameForSSH={setUseSameForSSH}
                    snmpCommunity={snmpCommunity}
                    setSnmpCommunity={setSnmpCommunity}
                  />
                  <hr style={{ width: "100%", marginTop: 20, borderTop: `3px solid ${colors.blueAccent[100]}` }} />
                  <hr style={{ width: "100%", marginTop: 20, borderTop: `3px solid ${colors.blueAccent[100]}` }} />
                  <AliasSettings
                    Alias={Alias}
                    setAlias={setAlias}
                    id={id}
                  />
                </div>
                <button className="button-46 is-success is-fullwidth">
                    Save
                  </button>
              </form>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SwitchSettings