import { useTheme } from "@mui/material";
import { collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {db} from '../../firebase_config';
import { tokens } from "../../theme";
import { toast } from 'react-toastify';

const AddScanner = ({ company, id, site }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [liid, setId] = useState("");
  const [serial, setSerial] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  const AddScanner = async (e) => {
    e.preventDefault();
    try {
      const dataRef = query(
        collection(db, "Scanners"),
        where("scanner", "==", liid.toLowerCase()),
        where("serial", "==", serial.toUpperCase())
      );
      const dataSnap = await getDocs(dataRef);

      if (dataSnap.docs.length === 0) {
        setMsg("Wrong ID or Serial");
      } else if (dataSnap?.docs[0]?.data()?.company === null) {
        await updateDoc(doc(db, "Scanners", liid), {
          company: company.toLowerCase(),
        });
        toast.success("Network Analyzer Added!");
        setTimeout(() => {
          navigate("/dashboard");
        }, 2000); // Change the delay as needed
        
      } else {
        setMsg("Scanner already registed!");
      }
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <section>
      <div
        className="pen_box"
        style={{ backgroundColor: colors.primary[400], paddingBottom: "50px" }}
      >
        <div>
          <div>
            <form onSubmit={AddScanner}>
              <h3
                style={{ color: colors.grey[100] }}
                className="settings has-text-centered"
              >
                Add Scanner
              </h3>
              <p className="has-text-centered warning">{msg}</p>
              <div className="field mt-5">
                <label style={{ color: colors.grey[100] }} className="label">
                  ID:
                </label>
                <div className="controls">
                  <input
                    type="text"
                    className="input"
                    placeholder="eg. HS1000"
                    value={liid}
                    onChange={(e) => setId(e.target.value)}
                  />
                </div>
              </div>
              <div className="field mt-5">
                <label style={{ color: colors.grey[100] }} className="label">
                  Serial Number:
                </label>
                <div className="controls">
                  <input
                    type="text"
                    className="input"
                    placeholder="S/N"
                    value={serial}
                    onChange={(e) => setSerial(e.target.value)}
                  />
                </div>
              </div>
              <div className="field mt-5">
                <button
                  className="button-46 is-success is-fullwidth"
                  style={{ float: "left" }}
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddScanner;
