import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, OutlinedInput, FormHelperText, FormControlLabel, Typography, FormLabel, RadioGroup, Radio, Select, MenuItem, Switch } from '@mui/material';
import { tokens } from "../theme";
import { styled, useTheme } from '@mui/system';

const WifiSettings = ({ getConnected, setConnected, inputSSID, setInputSSID, auth, setAuth, SSID, setSSID, SSIDarray, PSK, setPSK, UNAME, setUNAME, UPASS, setUPASS, BSS, setBSS, APspec, setAPspec, isValid, setIsValid }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const StyledRadio = styled(Radio)(() => ({
    '&.Mui-checked': {
      color: colors.blueAccent[100],
    },
    color: colors.grey[600],
  }));

  const handleAuthChange = (event) => {
    setAuth(event.target.value);
  };
  
  const handleInputSSIDChange = (event) => {
    setInputSSID(event.target.value === 'true');
  };
  const handleSetSSIDChange = (event) => {
    const selectedSSID = event.target.value;
    
    // Find the selected object in the array
    const selectedObject = SSIDarray.find(item => item.ssid === selectedSSID);
    
    if (selectedObject) {
      if (selectedObject.auth_suite.toUpperCase() === "IEEE") {
        setAuth("PEAP");
      } else {
        setAuth(selectedObject.auth_suite.toUpperCase());
      }
    }
    
    setSSID(selectedSSID);
  };

  const updateFieldChanged = (name, value) => {
    switch (name) {
      case 'SSID':
        setSSID(value);
        break;
      case 'PSK':
        setPSK(value);
        break;
      case 'UNAME':
        setUNAME(value);
        break;
      case 'UPASS':
        setUPASS(value);
        break;
      case 'BSS':
        setBSS(value);
        setIsValid(validateInput(value));
        break;
      default:
        break;
    }
  };

  function validateInput(value) {
    const macAddressRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
    return macAddressRegex.test(value) || value === '';
  }

  return (
    <div>
        <div style={{ textAlign: 'left', marginBottom: "10px" }}>
        <h3 className="settings" style={{ color: colors.blueAccent[100], marginBottom:"0px", marginTop:"20px" }}>
            WiFi
        </h3>
        <hr style={{ width: "50px", margin: 0, borderTop: `2px solid ${colors.blueAccent[100]}` }} />
        </div>
      <FormControlLabel style={{ marginTop: "10px" }}
        control={
          <Switch
            checked={getConnected}
            onChange={() => setConnected(!getConnected)}
            name="connect"
            color="primary"
            sx={{
              '& .MuiSwitch-thumb': {
                backgroundColor: getConnected ? colors.greenAccent[400] : colors.grey[400],
              },
              '& .MuiSwitch-track': {
                backgroundColor: getConnected ? colors.redAccent[400] : colors.grey[400],
              },
              '&:hover .MuiSwitch-thumb': {
                backgroundColor: getConnected ? colors.greenAccent[400] : colors.grey[400],
              },
              '&:hover .MuiSwitch-track': {
                backgroundColor: getConnected ? colors.grey[100] : colors.grey[400],
              },
            }}
          />
        }
        label={<Typography style={{ color: colors.grey[100] }}>Connect to Wifi</Typography>}
      />
      <Typography
        style={{
          color: getConnected ? colors.greenAccent[100] : colors.redAccent[100], marginBottom: "7px"
        }}
      >
        {getConnected ? '' : 'Disconnected'}
      </Typography>
      {getConnected && (
        <>
          <FormControl>
            <FormLabel style={{ color: colors.grey[100], marginBottom: "10px" }} id="auth-radio-buttons-group-label"></FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={inputSSID}
              onChange={handleInputSSIDChange}
            >
              <FormControlLabel style={{ color: colors.grey[200], marginLeft: "0px" }} value="true" control={<StyledRadio />} label="Auto" />
              <FormControlLabel style={{ color: colors.grey[200] }} value="false" control={<StyledRadio />} label="Manual" />
            </RadioGroup>
          </FormControl>
          {inputSSID ? (
            <FormControl sx={{ marginTop: 1, width: "100%" }} variant="outlined">
              <InputLabel
                htmlFor="ssid"
                sx={{
                  color: colors.grey[100],
                  '&.Mui-focused': {
                    color: colors.grey[100],
                  },
                  '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                    color: colors.grey[100],
                  },
                  '&.MuiInputLabel-outlined': {
                    color: 'grey',
                  },
                }}
              >
                SSID
              </InputLabel>
              <Select
                value={SSID}
                onChange={handleSetSSIDChange}
                label="SSID"
                id="ssid"
                sx={{
                  "& .MuiOutlinedInput-input": {
                    color: colors.grey[100],
                  },
                  '& .MuiOutlinedInput-input::placeholder': {
                    color: colors.grey[100],
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.grey[100],
                  },
                  '&:hover > .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.grey[100],
                  },
                  '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.grey[100],
                  },
                  '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                    color: colors.grey[100],
                  },
                }}
              >
                {SSIDarray.map((option, index) => (
                  <MenuItem key={index} value={option.ssid}>
                    {`${option.ssid} (${option.auth_suite === "IEEE" ? "802.1x" : option.auth_suite})`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <>
              <FormControl sx={{ marginTop: 1, width: "100%" }} variant="outlined">
                <InputLabel
                  htmlFor="ssid"
                  sx={{
                    color: colors.grey[100],
                    '&.Mui-focused': {
                      color: colors.grey[100],
                    },
                    '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                      color: colors.grey[100],
                    },
                    '&.MuiInputLabel-outlined': {
                      color: 'grey',
                    },
                  }}
                >
                  SSID
                </InputLabel>
                <OutlinedInput
                  id="SSID"
                  name="SSID"
                  type='text'
                  value={SSID}
                  onChange={(event) => updateFieldChanged('SSID', event.target.value)}
                  label="SSID"
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      color: colors.grey[100],
                    },
                    '& .MuiOutlinedInput-input::placeholder': {
                      color: colors.grey[100],
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: colors.grey[100],
                    },
                    '&:hover > .MuiOutlinedInput-notchedOutline': {
                      borderColor: colors.grey[100],
                    },
                    '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
                      borderColor: colors.grey[100],
                    },
                    '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                      color: colors.grey[100],
                    },
                  }}
                />
                <FormHelperText sx={{ color: colors.grey[100], fontSize: "16px" }}>
                </FormHelperText>
              </FormControl>
              <div style={{ marginTop: "0px" }}></div>
              <FormControl>
                <FormLabel style={{ color: colors.grey[100], marginBottom: "10px" }} id="auth-radio-buttons-group-label"></FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={auth}
                  onChange={handleAuthChange}
                >
                  <FormControlLabel style={{ color: colors.grey[200], marginLeft: "0px" }} value="OPEN" control={<StyledRadio />} label="OPEN" />
                  <FormControlLabel style={{ color: colors.grey[200] }} value="PSK" control={<StyledRadio />} label="PSK" />
                  <FormControlLabel style={{ color: colors.grey[200] }} value="PEAP" control={<StyledRadio />} label="PEAP" />
                </RadioGroup>
              </FormControl>
            </>
          )}
          {auth === "PSK" && (
            <>
              <div style={{ marginTop: "10px" }}></div>
              <FormControl sx={{ marginTop: 1, width: "100%" }} variant="outlined">
                <InputLabel
                  htmlFor="psk"
                  sx={{
                    color: colors.grey[100],
                    '&.Mui-focused': {
                      color: colors.grey[100],
                    },
                    '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                      color: colors.grey[100],
                    },
                    '&.MuiInputLabel-outlined': {
                      color: 'grey',
                    },
                  }}
                >
                  PSK
                </InputLabel>
                <OutlinedInput
                  id="psk"
                  name="psk"
                  type={PSK}
                  value={PSK}
                  onChange={(event) => updateFieldChanged('PSK', event.target.value)}
                  label="PSK"
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      color: colors.grey[100],
                    },
                    '& .MuiOutlinedInput-input::placeholder': {
                      color: colors.grey[100],
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: colors.grey[100],
                    },
                    '&:hover > .MuiOutlinedInput-notchedOutline': {
                      borderColor: colors.grey[100],
                    },
                    '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
                      borderColor: colors.grey[100],
                    },
                    '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                      color: colors.grey[100],
                    },
                  }}
                />
                <FormHelperText sx={{ color: colors.grey[100], fontSize: "16px" }}>
                </FormHelperText>
              </FormControl>
            </>
          )}
          {auth === "PEAP" && (
            <>
              <div style={{ marginTop: "10px" }}></div>
              <FormControl sx={{ marginTop: 1, width: "100%" }} variant="outlined">
                <InputLabel
                  htmlFor="uname"
                  sx={{
                    color: colors.grey[100],
                    '&.Mui-focused': {
                      color: colors.grey[100],
                    },
                    '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                      color: colors.grey[100],
                    },
                    '&.MuiInputLabel-outlined': {
                      color: 'grey',
                    },
                  }}
                >
                  Username
                </InputLabel>
                <OutlinedInput
                  id="uname"
                  name="uname"
                  type='text'
                  value={UNAME}
                  onChange={(event) => updateFieldChanged('UNAME', event.target.value)}
                  label="Username"
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      color: colors.grey[100],
                    },
                    '& .MuiOutlinedInput-input::placeholder': {
                      color: colors.grey[100],
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: colors.grey[100],
                    },
                    '&:hover > .MuiOutlinedInput-notchedOutline': {
                      borderColor: colors.grey[100],
                    },
                    '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
                      borderColor: colors.grey[100],
                    },
                    '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                      color: colors.grey[100],
                    },
                  }}
                />
                <FormHelperText sx={{ color: colors.grey[100], fontSize: "16px" }}>
                </FormHelperText>
              </FormControl>
              <FormControl sx={{ marginTop: 1, width: "100%" }} variant="outlined">
                <InputLabel
                  htmlFor="upass"
                  sx={{
                    color: colors.grey[100],
                    '&.Mui-focused': {
                      color: colors.grey[100],
                    },
                    '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                      color: colors.grey[100],
                    },
                    '&.MuiInputLabel-outlined': {
                      color: 'grey',
                    },
                  }}
                >
                  Password
                </InputLabel>
                <OutlinedInput
                  id="upass"
                  name="upass"
                  type="password"
                  value={UPASS}
                  onChange={(event) => updateFieldChanged('UPASS', event.target.value)}
                  label="Password"
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      color: colors.grey[100],
                    },
                    '& .MuiOutlinedInput-input::placeholder': {
                      color: colors.grey[100],
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: colors.grey[100],
                    },
                    '&:hover > .MuiOutlinedInput-notchedOutline': {
                      borderColor: colors.grey[100],
                    },
                    '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
                      borderColor: colors.grey[100],
                    },
                    '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                      color: colors.grey[100],
                    },
                  }}
                />
                <FormHelperText sx={{ color: colors.grey[100], fontSize: "16px" }}>
                </FormHelperText>
              </FormControl>
            </>
          )}
          <br></br>
          <FormControlLabel style={{ marginTop: "10px" }}
            control={
              <Switch
                checked={APspec}
                onChange={() => setAPspec(!APspec)}
                name="showListSwitch"
                color="primary"
                sx={{
                  '& .MuiSwitch-thumb': {
                    backgroundColor: APspec ? colors.grey[100] : colors.grey[400],
                  },
                  '& .MuiSwitch-track': {
                    backgroundColor: APspec ? colors.grey[100] : colors.grey[400],
                  },
                  '&:hover .MuiSwitch-thumb': {
                    backgroundColor: APspec ? colors.grey[100] : colors.grey[400],
                  },
                  '&:hover .MuiSwitch-track': {
                    backgroundColor: APspec ? colors.grey[100] : colors.grey[400],
                  },
                }}
              />
            }
            label={<Typography style={{ color: colors.grey[100] }}>Specify AP</Typography>}
          />
          <br></br>
          {APspec && (
            <>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <InputLabel
                  htmlFor="bss"
                  sx={{
                    marginTop: "5px",
                    color: colors.grey[100],
                    '&.Mui-focused': {
                      color: colors.grey[100],
                    },
                    '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                      color: colors.grey[100],
                    },
                    '&.MuiInputLabel-outlined': {
                      color: 'grey',
                    },
                  }}
                >
                  AP
                </InputLabel>
                <OutlinedInput
                  id="bss"
                  name="bss"
                  type='text'
                  value={BSS}
                  onChange={(event) => updateFieldChanged('BSS', event.target.value)}
                  label="AP"
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      color: colors.grey[100],
                    },
                    '& .MuiOutlinedInput-input::placeholder': {
                      color: colors.grey[100],
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: colors.grey[100],
                    },
                    '&:hover > .MuiOutlinedInput-notchedOutline': {
                      borderColor: colors.grey[100],
                    },
                    '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
                      borderColor: colors.grey[100],
                    },
                    '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                      color: colors.grey[100],
                    },
                  }}
                />
                {!isValid && (
                  <FormHelperText sx={{ color: 'red', fontSize: "16px" }}>
                    MAC format: aa:bb:cc:11:22:33
                  </FormHelperText>
                )}
              </FormControl>
              <div style={{ marginBottom: "20px" }}></div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default WifiSettings;
