  import { Box, Typography, useTheme } from "@mui/material";
  import React, { useEffect, useState } from 'react';
  import { tokens } from "../theme";
  import LineChart_Small from "./LineChart_small";

  const StatBox_Line = ({ subtitle, icon, sqlData }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [liMax, setMax] = useState(10);
    const [title, setMs] = useState(0);
    const [liMsColor, setMsColor] = useState({ color: colors.grey[100] });
    const [gateway, setGateway] = useState(null);
    const [custom, setCustom] = useState(null);
    const [stockholm, setStockholm] = useState(null);
    const [newyork, setNewyork] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        let waitdata = await sqlData;
        if (waitdata) {
          var Gateway = [];
          var Stockholm = [];
          var Custom = [];
          var Newyork = [];
          var Time = [];
          var min = 0;
          var max = 0;
          var waitLenght = Number(waitdata.length) - 1;

          waitdata.map((sqlrows) => {
            Gateway.push(sqlrows.ping[5]);
            Stockholm.push(sqlrows.ping[0]);
            Custom.push(sqlrows.ping[1]);
            Newyork.push(sqlrows.ping[3]);
            Time.push(sqlrows.dt[1]);
          });

          if (subtitle === "Gateway") {
            max = Math.max(...Gateway);
            var gw = gateway;

            if (gw > 1.5 && gw <= 2) {
              setMsColor({ color: colors.redAccent[300] });
            } else if (gw > 2 || gw == 0) {
              setMsColor({ color: colors.redAccent[500] });
            } else {
              setMsColor({ color: colors.greenAccent[500] });
            }
            setMs(Math.round(gw * 10) / 10 + " ms");
          } else if (subtitle === "Stockholm") {
            max = Math.max(...Stockholm);
            var sthlm = stockholm;
            if (sthlm > 21 && sthlm <= 25) {
              setMsColor({ color: colors.redAccent[300] });
            } else if (sthlm > 25 || sthlm == 0) {
              setMsColor({ color: colors.redAccent[500] });
            } else {
              setMsColor({ color: colors.greenAccent[500] });
            }
            setMs(Math.round(sthlm * 10) / 10 + " ms");
          }  else if (subtitle === "Newyork") {
            max = Math.max(...Newyork);
            var ny = newyork;

            if (ny > 100 && ny <= 120) {
              setMsColor({ color: colors.redAccent[300] });
            } else if (ny > 120 || ny == 0) {
              setMsColor({ color: colors.redAccent[500] });
            } else {
              setMsColor({ color: colors.greenAccent[500] });
            }
            setMs(Math.round(ny * 10) / 10 + " ms");
          }
          else{
            max = Math.max(...Custom);
            if (custom > 30 && custom <= 35) {
              setMsColor({ color: colors.redAccent[300] });
            } else if (custom > 35 || custom == 0) {
              setMsColor({ color: colors.redAccent[500] });
            } else {
              setMsColor({ color: colors.greenAccent[500] });
            }
            setMs(Math.round(custom * 10) / 10 + " ms");
          }
        }
      };
      fetchData().catch(console.error);
    }, [sqlData, gateway, custom, stockholm, newyork]);

    return (
      <Box width="100%" m="0 30px">
        <Box display="flex" justifyContent="space-between">
          <Box mt="20px">
            {icon}
            <Typography variant="h4" fontWeight="bold" sx={liMsColor}>
              {title}
            </Typography>
            <Typography variant="h5" sx={{ color: colors.grey[100] }}>
              {subtitle}
            </Typography>
          </Box>
          <Box ml="8px" mb="10px" mt="20px" height="100px" width="70%">
            <LineChart_Small chdata={subtitle} sqlData={sqlData} setGateway={setGateway} setCustom={setCustom} setStockholm={setStockholm} setNewyork={setNewyork} />
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" mt="2px"></Box>
      </Box>
    );
  };

  export default StatBox_Line;
