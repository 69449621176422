import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import WifiIcon from '@mui/icons-material/Wifi';
import { collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import {db} from '../../firebase_config';
import { Box, Divider, Typography, useTheme, CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from 'react';
import ReactSpeedometer from "react-d3-speedometer";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import StatBox_Line from "../../components/StatBox_line_wifi";
import { FirebaseContext } from '../../data/provide_firestore';
import { tokens } from "../../theme";
import Overview_Dashboard from "./dashbord";

const Wifi_Overview = ({ scanner, scannerdata }) => {
  const { id } = useParams();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const UL = [];
  const DL = [];

  const [google, setMs] = useState();
  const [liNetwork, setNetwork] = useState();
  const [liDL, setDL] = useState();
  const [liUL, setUL] = useState();
  const [liSignal, setSignal] = useState(-100);
  const [minSignal, setMinSignal] = useState(-40);
  const [getConnected, setConnected] = useState(false);
  const [liBssid, setBssid] = useState();
  const [liSsid, setSsid] = useState();
  const [liFreq, setFreq] = useState();
  const [liMaxDL, setMaxDL] = useState();
  const [liMaxUL, setMaxUL] = useState();
  const [liGoogleColor, setGoogleColor] = useState({ color: colors.grey[100] });
  const [topTitle, setTopTitle] = useState(id);
  const [getCustomIP, setCustomIP] = useState("Loading...");
  const [liAllData, setAllData] = useState();
  const [Loading, setLoading] = useState(false);
  const [googleNow, setGoogleNow] = useState("Loading");

  const {
    liWifiDataFire,
    getScannerInfo,
    liWifiSpeedFire,
    trigger,
  } = useContext(FirebaseContext);

  useEffect(() => {
    if (id != "dashboard") {
      setLiDataStates();
      fetchScannerInfo()
    }
  }, [liWifiDataFire, liWifiSpeedFire, trigger, scanner]);

  useEffect(() => {
    fetchData();
    fetchToday();
  }, [liAllData]);

  useEffect(() => {
    funcGoogleNow();
  }, [googleNow]);

  useEffect(() => {
    if(liWifiSpeedFire?.[id]){
      fetchToday()
      setLoading(false)
    }

    
  }, [liWifiSpeedFire]);

  useEffect(() => {
    if (id) {
      scannerdata?.map((rows) => {
        if (rows.scanner === id) {
          if (rows.alias) {
            setTopTitle(rows.alias);
          } else if (!rows.alias) {
            setTopTitle(rows.scanner);
          }
        }
      });
    }
  }, [scanner]);

  const handleClick = async (event) => {
    
    event.preventDefault();

    await updateDoc(doc(db, "Scanners", id), {
      updateBW_wifi: true,
    });
    setLoading(true);

  };

  function setLiDataStates() {
    if (liWifiDataFire) {
      setAllData(liWifiDataFire[id]);
    }
  }

  async function fetchScannerInfo() {
    if (getScannerInfo?.[id]) {
      setCustomIP(getScannerInfo?.[id]?.customIP || null);
      setConnected(getScannerInfo?.[id]?.ssid ? true : false);
    }
  }

  function funcGoogleNow() {
    if (googleNow > 16.0 && googleNow <= 20) {
      setGoogleColor({ color: colors.redAccent[300] });
    }

    if (googleNow > 20 || googleNow === 0) {
      setGoogleColor({ color: colors.redAccent[500] });
    } else {
      setGoogleColor({ color: colors.greenAccent[500] });
    }
    setMs(
      Math.round(googleNow * 10) / 10 + " ms"
    );
  }
  

  function fetchData() {
    if (liAllData) {
      setSignal(liAllData?.[liAllData.length - 1]?.wifi?.[3]);
      if(liAllData[liAllData.length - 1]?.wifi?.[3] > -40){
        setMinSignal(liAllData[liAllData.length - 1]?.wifi?.[3])
      }else{
        setMinSignal(-40)
      }
      
      setBssid(liAllData?.[liAllData.length - 1]?.wifi?.[0]);
      setSsid(liAllData?.[liAllData.length - 1]?.wifi?.[1]);
      setFreq(liAllData?.[liAllData.length - 1]?.wifi?.[2]);
      var waitLenght = Number(liAllData?.length) - 1;
      setNetwork(liAllData?.[waitLenght]?.network[1]);

    }
  }

  function fetchToday() {
    if (liWifiSpeedFire?.[id]) {
      const SpeedData = liWifiSpeedFire[id]
      Object.values(SpeedData).map((sqlrows) => {
        UL.push(sqlrows?.speedtest[1]);
        DL.push(sqlrows?.speedtest[0]);
      });
      if (DL?.[0]) {
        const MaxDL = Math.round(Math.max(...DL));
        const MaxUL = Math.round(Math.max(...UL));
        setMaxDL(MaxDL);
        setMaxUL(MaxUL);
        const ST_data = Object.values(SpeedData)[[SpeedData.length - 1]];
        setUL(Math.round(ST_data?.speedtest[1]));
        setDL(Math.round(ST_data?.speedtest[0]));
      } else {
        setMaxDL(0);
        setMaxUL(0);
        setUL(0);
        setDL(0);
      }
    }
  }
  if (id === "dashboard") {
    return <Overview_Dashboard />;
  }

  return (
    <Box ml="20px" mr="20px" mt="-10px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        
        <Header title={topTitle} subtitle={"WiFi : " + liNetwork} />
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="135px"
        gap="20px"
        mt="-20px"
      >
        {/* ROW 1 */}
        <Box
        className="box" 
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox_Line
            subtitle="Gateway"
            sqlData={liAllData}
            icon={
              <SsidChartIcon
                sx={{ color: colors.grey[100], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
        className="box" 
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox_Line
            subtitle={getCustomIP}
            sqlData={liAllData}
            icon={
              <SsidChartIcon
                sx={{ color: colors.grey[100], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
        className="box" 
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox_Line
            subtitle="Stockholm"
            sqlData={liAllData}
            icon={
              <SsidChartIcon
                sx={{ color: colors.grey[100], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
        className="box" 
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox_Line
            subtitle="Newyork"
            sqlData={liAllData}
            icon={
              <SsidChartIcon
                sx={{ color: colors.grey[100], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        <Box
        className="box" 
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography variant="h5" sx={{ color: colors.greenAccent[500] }}>
                Google
              </Typography>

              <SsidChartIcon
                sx={{ color: colors.grey[100], fontSize: "26px" }}
              />
            </Box>
            <Box>
              <Typography variant="h4" fontWeight="bold" sx={liGoogleColor}>
                {google}
              </Typography>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart isDashboard={true} sqlData={liAllData} setGoogleNow={setGoogleNow} />
          </Box>
        </Box>
        <Box
        className="box" 
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="hidden"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            colors={colors.grey[100]}
            p="10px"
          >
            <Typography color={colors.grey[100]} variant="h3" fontWeight="600">
              WiFI
            </Typography>
            <Box>
              <Typography
                color={colors.greenAccent[500]}
                variant="h5"
                fontWeight="600"
              >
                Info
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p="7px"
          >
            <Box>
              <Typography
                color={colors.greenAccent[500]}
                variant="h5"
                fontWeight="600"
              >
                BSSID
              </Typography>

              <Typography color={colors.grey[100]}>AP mac</Typography>
            </Box>
            <Box
              backgroundColor={
                getConnected ? colors.blueAccent[800] : colors.redAccent[500]
              }
              p="5px 10px"
              borderRadius="4px"
            >
              {getConnected ? liBssid : ''}
            </Box>
          </Box>
          <Divider />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p="7px"
          >
            <Box>
              <Typography
                color={colors.greenAccent[500]}
                variant="h5"
                fontWeight="600"
              >
                SSID
              </Typography>
              <Typography color={colors.grey[100]}>connected</Typography>
            </Box>
            <Box
              backgroundColor={
                getConnected ? colors.blueAccent[800] : colors.redAccent[500]
              }
              p="5px 10px"
              borderRadius="4px"
            >
              {getConnected ? liSsid : 'Disconnected'}
            </Box>
          </Box>
          <Divider />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p="7px"
          >
            <Box>
              <Typography
                color={colors.greenAccent[500]}
                variant="h5"
                fontWeight="600"
              >
                Freq
              </Typography>

              <Typography color={colors.grey[100]}>Ghz</Typography>
            </Box>
            <Box
              backgroundColor={
                getConnected ? colors.blueAccent[800] : colors.redAccent[500]
              }
              p="5px 10px"
              borderRadius="4px"
            >
              {getConnected ? liFreq : ''}
            </Box>
          </Box>
          <Divider />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p="7px"
          >
            <Box>
              <Typography
                color={colors.greenAccent[500]}
                variant="h5"
                fontWeight="600"
              >
                Signal Strenght
              </Typography>

              <Typography color={colors.grey[100]}>dBm</Typography>
            </Box>
            <Box
              backgroundColor={
                getConnected ? colors.blueAccent[800] : colors.redAccent[500]
              }
              p="5px 10px"
              borderRadius="4px"
            >
              {getConnected ? liSignal : ''}
            </Box>
          </Box>
        </Box>

        {/* ROW 3 */}
        <Box
        className="box" 
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Box display="flex" flexDirection="column" alignItems="left">
            <Typography variant="h4" fontWeight="600">
              Download
            </Typography>
          </Box>

                    {Loading ? (
          <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "rgba(0,0,0,0)",
              height: "100%",
            }}
          >
            <CircularProgress sx={{ color: colors.greenAccent[500] }} />
            <h3
                  className="settings has-text-centered"
                  style={{ color: colors.grey[100] }}
                >
                  Speedtest in progress
                </h3>
          </Box>

                </>
        ) : (
          <>
                    <Box
            mt="-50px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <ReactSpeedometer
              forceRender={true}
              ringWidth={5}
              minValue={0}
              maxValue={liMaxDL}
              value={liDL}
              maxSegmentLabels={1}
              segments={3000}
              needleColor="steelblue"
              currentValueText=""
              //needleTransitionDuration={4000}
              //needleTransition="easeElastic"
            />
          </Box>
          <Box
            mt="-130px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography
              variant="h4"
              align="center"
              fontWeight="bold"
              sx={{ color: colors.grey[100] }}
            >
              {" " + liDL + " Mbps"}
            </Typography>
            <Typography variant="h7">
              {"Max todat: " + liMaxDL + " mbps"}
            </Typography>
            <Typography mt="10px">
              <CloudDownloadIcon
              onClick={handleClick}
                sx={{ color: colors.greenAccent[500], fontSize: "36px", cursor: 'pointer' }}
              />
            </Typography>
          </Box>
          </>
        )}
        </Box>
        <Box
        className="box" 
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Box display="flex" flexDirection="column" alignItems="left">
            <Typography variant="h4" fontWeight="600">
              Upload
            </Typography>
          </Box>
          {Loading ? (
          <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "rgba(0,0,0,0)",
              height: "100%",
            }}
          >
            <CircularProgress sx={{ color: colors.greenAccent[500] }} />
            <h3
                  className="settings has-text-centered"
                  style={{ color: colors.grey[100] }}
                >
                  Speedtest in progress
                </h3>
          </Box>

                </>
        ) : (
          <>
          <Box
            mt="-50px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <ReactSpeedometer
              forceRender={true}
              ringWidth={5}
              minValue={0}
              maxValue={liMaxUL}
              value={liUL}
              maxSegmentLabels={1}
              segments={3000}
              needleColor="steelblue"
              currentValueText=""
              // needleTransitionDuration={4000}
              // needleTransition="easeElastic"
            />
          </Box>
          <Box
            mt="-130px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography
              variant="h4"
              align="center"
              fontWeight="bold"
              sx={{ color: colors.grey[100] }}
            >
              {" " + liUL + " Mbps"}
            </Typography>
            <Typography variant="h7">
              {"Max todat: " + liMaxUL + " mbps"}
            </Typography>
            <Typography mt="10px">
              <CloudUploadIcon
              onClick={handleClick}
              sx={{ color: colors.greenAccent[500], fontSize: "36px", cursor: 'pointer' }}
            />
            </Typography>
          </Box>
          </>
        )}
        </Box>
        <Box
        className="box" 
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="0px"
        >
          <Box display="flex" flexDirection="column" alignItems="left">
            <Typography variant="h4" fontWeight="600" p="20px">
              WiFi <br></br> Signal
            </Typography>
          </Box>
          <Box
            mt="-85px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <ReactSpeedometer
              forceRender={true}
              ringWidth={5}
              minValue={-100}
              maxValue={minSignal}
              value={liSignal}
              maxSegmentLabels={1}
              segments={3000}
              needleColor="steelblue"
              currentValueText=""
              //needleTransitionDuration={4000}
              //needleTransition="easeElastic"
            />
          </Box>
          <Box
            mt="-135px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography mt="20px">
              <WifiIcon
                sx={{ color: colors.greenAccent[500], fontSize: "36px" }}
              />
            </Typography>
            <Typography
              variant="h4"
              align="center"
              fontWeight="bold"
              sx={{ color: colors.grey[100] }}
            >
              {" " + liSignal + " dBm"}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Wifi_Overview;
