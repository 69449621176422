import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';

const LogDialog = ({ open, onClose, logs }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Switch Logs</DialogTitle>
      <DialogContent>
        <List>
          {Array.isArray(logs) && logs.length > 0 ? (
            logs.map((log, index) => (
              <ListItem key={index}>
                <ListItemText primary={log} />
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText primary="No logs available" />
            </ListItem>
          )}
        </List>
      </DialogContent>
      <Button onClick={onClose}>Close</Button>
    </Dialog>
  );
};

export default LogDialog;
