import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase_config";
import { collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import {db} from '../../firebase_config';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import { Box, Divider, Typography, useTheme, CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from 'react';
import ReactSpeedometer from "react-d3-speedometer";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import StatBox_Line from "../../components/StatBox_line";
import { FirebaseContext } from '../../data/provide_firestore';
import { tokens } from "../../theme";

const Eth_Overview = ({ scanner, scannerdata }) => {
  const { id } = useParams();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const UL = [];
  const DL = [];

  const [google, setMs] = useState();
  const [liNetwork, setNetwork] = useState();
  const [liDL, setDL] = useState();
  const [liUL, setUL] = useState();
  const [liMaxDL, setMaxDL] = useState();
  const [liMaxUL, setMaxUL] = useState();
  const [liClients, setClients] = useState();
  const [liCircleClients, setCircleClients] = useState();
  const [liGoogleColor, setGoogleColor] = useState({ color: colors.grey[100] });
  const [liHiGoogle, setHiGoogle] = useState();
  const [liCustomData, setCustomData] = useState();
  const [liGoogleData, setGoogleData] = useState();
  const [liErrorData, setErrorData] = useState();
  const [liHiDl, setHiDl] = useState();
  const [liHiUl, setHiUl] = useState();
  const [liHiClients, setHiClients] = useState();
  const [getLLDP, setLLDP] = useState();
  const [topTitle, setTopTitle] = useState(id);
  const [getNumPosts, setNumPosts] = useState(15);
  const [getCustomIP, setCustomIP] = useState("Loading...");

  const [liAllHist, setAllHist] = useState();
  const [liAllSpeed, setAllSpeed] = useState();
  const [liAllData, setAllData] = useState();
  const [liAllPen, setAllPen] = useState();
  const [liAllDevices, setAllDevices] = useState();
  const [Loading, setLoading] = useState(false);
  const [googleNow, setGoogleNow] = useState("Loading...");

  const {
    liDataFire,
    liHistoryFire,
    liPenFire,
    liSpeedFire,
    liDevicesFire,
    getScannerInfo,
    trigger,
  } = useContext(FirebaseContext);

  useEffect(() => {
    if (id != "dashboard") {
      setLiDataStates();
      setLiHistoryStates();
      setLiPenStates();
      setLiDevicesStates();
      fetchCustomIP();
      
    }
  }, [liDataFire, trigger, scanner]);

  useEffect(() => {
    fetchHistory();
    fetchData();
    fetchToday();
  }, [liAllData]);

  useEffect(() => {
    if(liSpeedFire?.[id]){
      setAllSpeed(liSpeedFire[id]);
      fetchToday()
      setLoading(false)
    }

    
  }, [liSpeedFire]);

  useEffect(() => {
    funcGoogleNow();
  }, [googleNow]);

  useEffect(() => {
    fetchLLDP();
    if (id) {
      scannerdata?.map((rows) => {
        if (rows.scanner === id) {
          if (rows.alias) {
            setTopTitle(rows.alias);
          } else if (!rows.alias) {
            setTopTitle(rows.scanner);
          }
        }
      });
    }
  }, [scanner]);


  const handleClick = async (event) => {
    
    event.preventDefault();

    await updateDoc(doc(db, "Scanners", id), {
      updateBW_wire: true,
    });
    setLoading(true);

  };

  function setLiDataStates() {
    if (liDataFire) {
      setAllData(liDataFire[id]);
      
    }
  }
  function setLiHistoryStates() {
    if (liHistoryFire) {
      setAllHist(liHistoryFire[id]);
    }
  }
  function setLiPenStates() {
    if (liPenFire) {
      setAllPen(liPenFire[id]);
    }
  }

  function setLiDevicesStates() {
    if (liDevicesFire) {
      setAllDevices(liDevicesFire[id]);
    }
  }

  async function fetchCustomIP() {
    if(getScannerInfo?.id){
      setCustomIP(getScannerInfo[id]?.customIP)
    }
  }

  function fetchHistory() {
    if (liAllData) {
      
      var waitLenght = Number(liAllData.length) - 1;
      setCustomData(liAllData[waitLenght]?.ping_data[0]);
      setGoogleData(liAllData[waitLenght]?.ping_data[0])
      setErrorData(liAllData[waitLenght]?.errors[0])
    }
    if (liAllHist) {
      setHiClients(liAllHist[0]?.devices);
      setHiGoogle(liAllHist[0]?.ping_google);
      setHiDl(liAllHist[0]?.dl);
      setHiUl(liAllHist[0]?.ul);
    }
  }

  function fetchData() {
    if (liAllData) {
      var waitLenght = Number(liAllData.length) - 1;
      setNetwork(liAllData[waitLenght]?.network[1]);

    }
  }

  function funcGoogleNow() {
    if (googleNow > 16.0 && googleNow <= 20) {
      setGoogleColor({ color: colors.redAccent[300] });
    }

    if (googleNow > 20 || googleNow === 0) {
      setGoogleColor({ color: colors.redAccent[500] });
    } else {
      setGoogleColor({ color: colors.greenAccent[500] });
    }
    setMs(
      Math.round(googleNow * 10) / 10 + " ms"
    );
  }

  const bigQuery = async () => {
    const doBigQuery = httpsCallable(functions, "get_lldp");
    var data = {
      scanner: id,
    };
    const fullResult = await doBigQuery(data);
    return fullResult.data;
  };

  const fetchLLDP = async () => {


    const response = await bigQuery();
    if (response?.[0]?.[0]?.data) {
      const data = JSON.parse(response[0][0].data);

      setLLDP(data);
    } else {
      setLLDP([""]);
    }
  };

  function fetchToday() {
    if (liSpeedFire?.[id] && liAllDevices) {
      const SpeedData = liSpeedFire?.[id]
      Object.values(SpeedData).map((sqlrows) => {
        UL.push(sqlrows?.speedtest[1]);
        DL.push(sqlrows?.speedtest[0]);
      });
      if (DL?.[0]) {
        const MaxDL = Math.round(Math.max(...DL));
        const MaxUL = Math.round(Math.max(...UL));
        setMaxDL(MaxDL);
        setMaxUL(MaxUL);
        const ST_data = Object.values(SpeedData)[SpeedData.length - 1];
        setUL(Math.round(ST_data?.speedtest[1]));
        setDL(Math.round(ST_data?.speedtest[0]));
      } else {
        setMaxDL(0);
        setMaxUL(0);
        setUL(0);
        setDL(0);
      }
      setClients(liAllDevices[0]?.devices);
      setCircleClients(liAllDevices[0]?.devices / liAllDevices[0]?.pool);
    }
  }

  return (
    <Box ml="20px" mr="20px" mt="-10px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={topTitle} subtitle={"Ethernet : " + liNetwork} />
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="135px"
        gap="20px"
        mt="-20px"
      >
        {/* ROW 1 */}
        <Box
        className="box" 
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox_Line
            subtitle="Gateway"
            sqlData={liAllData}
            icon={
              <SsidChartIcon
                sx={{ color: colors.grey[100], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          className="box" 
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox_Line
            subtitle={getCustomIP}
            sqlData={liAllData}
            icon={
              <SsidChartIcon
                sx={{ color: colors.grey[100], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
        className="box" 
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox_Line
            subtitle="Stockholm"
            sqlData={liAllData}
            icon={
              <SsidChartIcon
                sx={{ color: colors.grey[100], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
        className="box" 
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox_Line
            subtitle="Newyork"
            sqlData={liAllData}
            icon={
              <SsidChartIcon
                sx={{ color: colors.grey[100], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        <Box
        className="box" 
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography variant="h5" sx={{ color: colors.greenAccent[500] }}>
                Google
              </Typography>

              <SsidChartIcon
                sx={{ color: colors.grey[100], fontSize: "26px" }}
              />
            </Box>
            <Box>
              <Typography variant="h4" fontWeight="bold" sx={liGoogleColor}>
                {google}
              </Typography>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart isDashboard={true} sqlData={liAllData} setGoogleNow={setGoogleNow} />
          </Box>
        </Box>
        <Box
        className="box" 
          gridColumn="span 4"
          gridRow="span 4"
          backgroundColor={colors.primary[400]}
          overflow="hidden"
        >
<Box
  display="flex"
  justifyContent="space-between"
  alignItems="center"
  colors={colors.grey[100]}
  p="10px"
>
  <Typography color={colors.grey[100]} variant="h3" fontWeight="600">
    Switch
  </Typography>
  {
  !getLLDP?.SysName ? (
    <Box
      backgroundColor={colors.redAccent[500]}
      p="5px 10px"
      borderRadius="4px"
      mr={0}
    >
      {'LLDP DISABLE'}
    </Box>
  ) : null
}
  <Box display="flex" alignItems="center">
    <Typography
      color={colors.greenAccent[500]}
      variant="h5"
      fontWeight="600"
      mr={1}
    >
      Info
    </Typography>

  </Box>
</Box>
          <Divider />
          <Box
  display="flex"
  justifyContent="space-between"
  alignItems="center"
  p="6px"
>
  <Box flex="1">
    <Typography
      color={colors.greenAccent[500]}
      variant="h5"
      fontWeight="600"
    >
      Hostname
    </Typography>

    <Typography color={colors.grey[100]}>System</Typography>
  </Box>
  <Box display="flex" flexDirection="row">
    <Box
      backgroundColor={colors.blueAccent[800]}
      p="5px 10px"
      borderRadius="4px"
      mr={0}
    >
      {getLLDP?.SysName ? getLLDP?.SysName : 'N/A'}
      
    </Box>
  </Box>
</Box>
<Divider />
<Box
  display="flex"
  justifyContent="space-between"
  alignItems="center"
  p="6px"
>
  <Box flex="1">
    <Typography
      color={colors.greenAccent[500]}
      variant="h5"
      fontWeight="600"
    >
      Management
    </Typography>

    <Typography color={colors.grey[100]}>IP</Typography>
  </Box>
  <Box display="flex" flexDirection="row">
    <Box
      backgroundColor={colors.blueAccent[800]}
      p="5px 10px"
      borderRadius="4px"
      mr={0}
    >
      {getLLDP?.MgmtIP ? getLLDP?.MgmtIP : 'N/A'}
      
    </Box>
  </Box>
</Box>
<Divider />
          <Box
  display="flex"
  justifyContent="space-between"
  alignItems="center"
  p="6px"
>
  <Box flex="1">
    <Typography
      color={colors.greenAccent[500]}
      variant="h5"
      fontWeight="600"
    >
      Switchport
    </Typography>

    <Typography color={colors.grey[100]}>Port : Description</Typography>
  </Box>
  <Box display="flex" flexDirection="row">
    <Box
      backgroundColor={colors.blueAccent[800]}
      p="5px 10px"
      borderRadius="4px"
      mr={0}
    >
      {getLLDP?.PortID ? getLLDP?.PortID : 'N/A'} : {getLLDP?.PortDescr ? getLLDP?.PortDescr : 'N/A'}
    </Box>
  </Box>
</Box>

<Divider />
          <Box
  display="flex"
  justifyContent="space-between"
  alignItems="center"
  p="6px"
>
  <Box flex="1">
    <Typography
      color={colors.greenAccent[500]}
      variant="h5"
      fontWeight="600"
    >
      Vlan
    </Typography>

    <Typography color={colors.grey[100]}>ID</Typography>
  </Box>
  <Box display="flex" flexDirection="row">
    <Box
      backgroundColor={colors.blueAccent[800]}
      p="5px 10px"
      borderRadius="4px"
      mr={0}
    >
      {getLLDP?.VLAN ? getLLDP?.VLAN : 'N/A'}
    </Box>
  </Box>
</Box>

<Divider />
          <Box
  display="flex"
  justifyContent="space-between"
  alignItems="center"
  p="6px"
>
  <Box flex="1">
    <Typography
      color={colors.greenAccent[500]}
      variant="h5"
      fontWeight="600"
    >
      PoE
    </Typography>

    <Typography color={colors.grey[100]}>Supported / Enabled</Typography>
  </Box>
  <Box display="flex" flexDirection="row">
    <Box
      backgroundColor={colors.blueAccent[800]}
      p="5px 10px"
      borderRadius="4px"
      mr={0}
    >
    {getLLDP?.MDI_Power ? getLLDP?.MDI_Power?.match(/supported: (\w+)/)[1] : 'N/A'} / {getLLDP?.MDI_Power ? getLLDP?.MDI_Power?.match(/enabled: (\w+)/)[1] : 'N/A'}
    </Box>
  </Box>
</Box>

<Divider />
<Box
  display="flex"
  justifyContent="space-between"
  alignItems="center"
  p="6px"
>
  <Box flex="1">
    <Typography
      color={colors.greenAccent[500]}
      variant="h5"
      fontWeight="600"
    >
      Cable
    </Typography>

    <Typography color={colors.grey[100]}>Info</Typography>
  </Box>
  <Box display="flex" flexDirection="row">
    <Box
      backgroundColor={colors.blueAccent[800]}
      p="5px 10px"
      borderRadius="4px"
      mr={0}
    >
            {getLLDP?.MAU_oper_type ? (
          <span title={getLLDP?.MAU_oper_type}>
            {getLLDP?.MAU_oper_type?.slice(0, 32)}
          </span>
        ) : (
          'N/A'
        )}
    </Box>
  </Box>
</Box>

<Divider />
          <Box
  display="flex"
  justifyContent="space-between"
  alignItems="center"
  p="6px"
>
  <Box flex="1">
    <Typography
      color={colors.greenAccent[500]}
      variant="h5"
      fontWeight="600"
    >
      System 
    </Typography>

    <Typography color={colors.grey[100]}>Description</Typography>
  </Box>
  <Box display="flex" flexDirection="row">
    <Box
      backgroundColor={colors.blueAccent[800]}
      p="5px 10px"
      borderRadius="4px"
      mr={0}
    >
              {getLLDP?.SysDescr ? (
          <span title={getLLDP?.SysDescr}>
            {getLLDP?.SysDescr?.slice(0, 32)}
          </span>
        ) : (
          'N/A'
        )}
    </Box>
  </Box>
</Box>


          <Divider />
          <Box
  display="flex"
  justifyContent="space-between"
  alignItems="center"
  p="6px"
>
  <Box flex="1">
    <Typography
      color={colors.greenAccent[500]}
      variant="h5"
      fontWeight="600"
    >
      Packet Loss
    </Typography>

    <Typography color={colors.grey[100]}>Google | Custom</Typography>
  </Box>
  <Box display="flex" flexDirection="row">
    <Box
      backgroundColor={colors.blueAccent[800]}
      p="5px 10px"
      borderRadius="4px"
      mr={3}
    >
      {liGoogleData?.packet_loss}%
    </Box>
    <Box
      backgroundColor={colors.blueAccent[800]}
      p="5px 10px"
      borderRadius="4px"
      mr={0.5}
    >
      {liCustomData?.packet_loss}%
    </Box>
  </Box>
</Box>



<Divider />

<Box
  display="flex"
  justifyContent="space-between"
  alignItems="center"
  p="6px"
>
  <Box flex="1">
    <Typography
      color={colors.greenAccent[500]}
      variant="h5"
      fontWeight="600"
    >
      RX
    </Typography>

    <Typography color={colors.grey[100]}>Errors | Dropps</Typography>
  </Box>
  <Box display="flex" flexDirection="row">
    <Box
      backgroundColor={colors.blueAccent[800]}
      p="5px 10px"
      borderRadius="4px"
      mr={3}
    >
       {liErrorData?.rx_errors}
    </Box>
    <Box
      backgroundColor={colors.blueAccent[800]}
      p="5px 10px"
      borderRadius="4px"
      mr={0.5}
    >
      {liErrorData?.rx_dropped}
    </Box>
  </Box>
</Box>

<Divider />

<Box
  display="flex"
  justifyContent="space-between"
  alignItems="center"
  p="6px"
>
  <Box flex="1">
    <Typography
      color={colors.greenAccent[500]}
      variant="h5"
      fontWeight="600"
    >
      TX
    </Typography>

    <Typography color={colors.grey[100]}>Errors | Dropps</Typography>
  </Box>
  <Box display="flex" flexDirection="row">
    <Box
      backgroundColor={colors.blueAccent[800]}
      p="5px 10px"
      borderRadius="4px"
      mr={3}
    >
       {liErrorData?.tx_errors}
    </Box>
    <Box
      backgroundColor={colors.blueAccent[800]}
      p="5px 10px"
      borderRadius="4px"
      mr={0.5}
    >
      {liErrorData?.tx_dropped}
    </Box>
  </Box>
</Box>


</Box>

        {/* ROW 3 */}
        <Box
        className="box" 
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Box display="flex" flexDirection="column" alignItems="left">
            <Typography variant="h4" fontWeight="600">
              Download
            </Typography>
          </Box>
          {Loading ? (
          <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "rgba(0,0,0,0)",
              height: "100%",
            }}
          >
            <CircularProgress sx={{ color: colors.greenAccent[500] }} />
            <h3
                  className="settings has-text-centered"
                  style={{ color: colors.grey[100] }}
                >
                  Speedtest in progress
                </h3>
          </Box>

                </>
        ) : (
          <>
          <Box
            mt="-50px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <ReactSpeedometer
              forceRender={true}
              ringWidth={5}
              minValue={0}
              maxValue={liMaxDL}
              value={liDL}
              maxSegmentLabels={1}
              segments={3000}
              needleColor="steelblue"
              currentValueText=""
              //needleTransitionDuration={4000}
              //needleTransition="easeElastic"
            />
          </Box>
          <Box
            mt="-130px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography
              variant="h4"
              align="center"
              fontWeight="bold"
              sx={{ color: colors.grey[100] }}
            >
              {" " + liDL + " Mbps"}
            </Typography>
            <Typography variant="h7">
              {"Max todat: " + liMaxDL + " mbps"}
            </Typography>
            <Typography mt="10px">
              <CloudDownloadIcon
              onClick={handleClick}
              sx={{ color: colors.greenAccent[500], fontSize: "36px", cursor: 'pointer' }}
            />
            </Typography>
          </Box>
          </>
        )}
        </Box>
        <Box
        className="box" 
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Box display="flex" flexDirection="column" alignItems="left">
            <Typography variant="h4" fontWeight="600">
              Upload
            </Typography>
          </Box>
          {Loading ? (
          <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "rgba(0,0,0,0)",
              height: "100%",
            }}
          >
            <CircularProgress sx={{ color: colors.greenAccent[500] }} />
            <h3
                  className="settings has-text-centered"
                  style={{ color: colors.grey[100] }}
                >
                  Speedtest in progress
                </h3>
          </Box>

                </>
        ) : (
          <>
          <Box
            mt="-50px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <ReactSpeedometer
              forceRender={true}
              ringWidth={5}
              minValue={0}
              maxValue={liMaxUL}
              value={liUL}
              maxSegmentLabels={1}
              segments={3000}
              needleColor="steelblue"
              currentValueText=""
              // needleTransitionDuration={4000}
              // needleTransition="easeElastic"
            />
          </Box>
          <Box
            mt="-130px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography
              variant="h4"
              align="center"
              fontWeight="bold"
              sx={{ color: colors.grey[100] }}
            >
              {" " + liUL + " Mbps"}
            </Typography>
            <Typography variant="h7">
              {"Max todat: " + liMaxUL + " mbps"}
            </Typography>
            <Typography mt="10px">
              <CloudUploadIcon
              onClick={handleClick}
              sx={{ color: colors.greenAccent[500], fontSize: "36px", cursor: 'pointer' }}
            />
            </Typography>
          </Box>
          </>
        )}
        </Box>
        {/* <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Typography variant="h4" fontWeight="600">
            Devices
          </Typography>
          <Box display="flex" flexDirection="column" alignItems="center">
            <ProgressCircle size="125" progress={liCircleClients} />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="15px"
          >
            <GroupsIcon
              sx={{ color: colors.greenAccent[500], fontSize: "36px" }}
            />
            <Typography
              variant="h4"
              align="center"
              fontWeight="bold"
              sx={{ color: colors.grey[100] }}
            >
              {" " + liClients + " Active"}
            </Typography>
            <Typography variant="h7">
              {Math.round(liCircleClients * 100) + "% full"}
            </Typography>
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};
export default Eth_Overview;
