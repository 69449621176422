import React, { useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tooltip,
  Typography,
  CircularProgress
} from '@mui/material';

const MultiUpgradeDialog = ({ 
    multiUpgradeModalOpen, 
    setMultiUpgradeModalOpen, 
    completeSwitches, 
    upgradeData, 
    selectedSwitchesForUpgrade, 
    setSelectedSwitchesForUpgrade, 
    isUpgradeAvailable,
    availableVersions,
    handleMultiUpgradeConfirm, 
    loadingUpgrade 
  }) => {
    const isVersionLessThan = (version, target) => {
      // Implement your version comparison logic here
    };
  
    const eligibleSwitches = completeSwitches.filter(switchNode => 
      upgradeData.some(data => data.ip === switchNode.ip) && 
      switchNode.sw_status === 0 && 
      switchNode.api_access === 1 &&
      !(switchNode.version.startsWith('WC') && isVersionLessThan(switchNode.version, '16.10.0011'))
    );
  
    const handleCheckboxChange = useCallback((switchId) => {
        setSelectedSwitchesForUpgrade(prevSelected =>
          prevSelected.includes(switchId)
            ? prevSelected.filter(id => id !== switchId)
            : [...prevSelected, switchId]
        );
      }, []);
    
      const handleSelectAll = useCallback(() => {
        const filteredSwitches = completeSwitches
          .filter(switchNode => isUpgradeAvailable(switchNode.version, availableVersions).available && switchNode.sw_status === 0)
          .map(switchNode => switchNode.id);
        console.log(filteredSwitches);
        setSelectedSwitchesForUpgrade(prevSelected => 
          prevSelected.length === filteredSwitches.length ? [] : filteredSwitches
        );
      }, [completeSwitches, isUpgradeAvailable, availableVersions]);
  
    return (
        <Dialog 
        open={multiUpgradeModalOpen} 
        onClose={() => setMultiUpgradeModalOpen(false)} 
        maxWidth="md" 
        fullWidth
        PaperProps={{
          style: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          },
        }}
      >
        <DialogTitle>Upgrade Multiple Switches</DialogTitle>
        <DialogContent style={{ flex: 1, overflowY: 'auto' }}>
          <p>Select the switches you want to upgrade:</p>
          <table style={{ width: "100%", borderRadius: '10px' }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>IP Address</th>
                <th>Current Firmware</th>
                <th>Upgrade To</th>
                <th>
                  <input
                    type="checkbox"
                    checked={selectedSwitchesForUpgrade.length === eligibleSwitches.length && eligibleSwitches.length > 0}
                    onChange={handleSelectAll}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {completeSwitches
                .filter(switchNode => upgradeData.some(data => data.ip === switchNode.ip) && switchNode.sw_status === 0 && switchNode.api_access === 1)
                .map((switchNode, index) => {
                  const upgradeInfo = upgradeData.find(data => data.ip === switchNode.ip);
                  const versionCheck = switchNode.version.startsWith('WC') && isVersionLessThan(switchNode.version, '16.10.0011');
  
                  return (
                    <tr key={index} style={{ opacity: versionCheck ? 0.5 : 1 }}>
                      <td>{switchNode.name}</td>
                      <td>{switchNode.ip}</td>
                      <td>
                        <Tooltip
                          placement="top"
                          title={
                            versionCheck ? (
                              <Typography fontSize="12px">
                                Upgrade disabled for 2930F &lt; WC.16.10.0011, API issues
                              </Typography>
                            ) : (
                              ''
                            )
                          }
                        >
                          <div
                            style={{
                              textAlign: 'center',
                              color: versionCheck ? 'red' : 'inherit',
                              cursor: versionCheck ? 'not-allowed' : 'default',
                            }}
                          >
                            {switchNode.version}
                          </div>
                        </Tooltip>
                      </td>
                      <td>{upgradeInfo?.latestVersion}</td>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedSwitchesForUpgrade.includes(switchNode.id)}
                          onChange={() => handleCheckboxChange(switchNode.id)}
                          disabled={versionCheck}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
            </table>
        </DialogContent>
        <DialogActions style={{ padding: '16px', borderTop: '1px solid rgba(0, 0, 0, 0.12)', position: 'sticky', bottom: 0, backgroundColor: 'white' }}>
          <Button onClick={handleMultiUpgradeConfirm} color="primary" disabled={loadingUpgrade || selectedSwitchesForUpgrade.length === 0}>
            {loadingUpgrade ? <CircularProgress size={24} /> : 'Start Firmware Upgrade'}
          </Button>
          <Button onClick={() => setMultiUpgradeModalOpen(false)} color="secondary" disabled={loadingUpgrade}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  export default MultiUpgradeDialog;
