import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { onAuthStateChanged } from 'firebase/auth';
import { useState } from 'react';
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { AuthProvider } from './AuthContext';
import Dashboard from "./components/Dashboard";
import Login from './components/Login';
import Register from './components/Register';
import { FirebaseProvider } from './data/provide_firestore';
import { auth } from './firebase_config';
import PrivateRoute from './PrivateRoute';
import Profile from './Profile';
import VerifyEmail from './VerifyEmail';
import { ToastContainer } from 'react-toastify';

function App() {
  const [currentUser, setCurrentUser] = useState("init");
  const [timeActive, setTimeActive] = useState(false);

  const ProtectedRoutes = () => {
    return currentUser?.emailVerified ? (
      <Outlet />
    ) : (
      <Navigate to="/login" replace />
    );
  };

  onAuthStateChanged(auth, (user) => {
    setCurrentUser(user);
  });

  if (currentUser === "init") {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <AuthProvider value={{ currentUser, timeActive, setTimeActive }}>
      <FirebaseProvider>
      <ToastContainer />
        <Routes>
          <Route
            exact
            path="/"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path="/login"
            element={
              !currentUser?.emailVerified ? (
                <Login />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/register"
            element={
              !currentUser?.emailVerified ? (
                <Register />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route element={<ProtectedRoutes />}>
          <Route
              path="/dashboard"
              element={
                <Dashboard
                  site={"dashboard"}
                  value={currentUser?.emailVerified}
                />
              }
            />
            <Route
              path="/:id"
              element={
                <Dashboard
                  site={"eth"}
                  value={currentUser?.emailVerified}
                />
              }
            />
            <Route
              path="/:id/eth"
              element={
                <Dashboard site={"eth"} value={currentUser?.emailVerified} />
              }
            />
            <Route
              path="/:id/wifi"
              element={
                <Dashboard site={"wifi"} value={currentUser?.emailVerified} />
              }
            />

            <Route
              path="/:id/pentest"
              element={
                currentUser?.emailVerified ? (
                  <Dashboard site={"pen"} />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />

            <Route
              path="/:id/swmgmt"
              element={
                currentUser?.emailVerified ? (
                  <Dashboard site={"swmgmt"} />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />

            <Route
              path="/:id/scan"
              element={
                currentUser?.emailVerified ? (
                  <Dashboard site={"scan"} />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />

            <Route
              path="/:id/history"
              element={
                currentUser?.emailVerified ? (
                  <Dashboard site={"history"} />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />

            <Route
              path="/:id/settings"
              element={
                currentUser?.emailVerified ? (
                  <Dashboard site={"settings"} />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/:id/switchSettings"
              element={
                currentUser?.emailVerified ? (
                  <Dashboard site={"swSettings"} />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />

            <Route
              path="/addScanner"
              element={
                currentUser?.emailVerified ? (
                  <Dashboard site={"addScanner"} />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />

            <Route
              path="/UserSettings"
              element={
                currentUser?.emailVerified ? (
                  <Dashboard site={"UserSettings"} />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
          </Route>
        </Routes>
      </FirebaseProvider>
    </AuthProvider>
  );
}

export default App;
