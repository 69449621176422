import React from 'react';
import { Box, Button, TextField, CircularProgress, Typography, LinearProgress, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';

const SwitchModalSNMPAdd = ({
  network,
  setNetwork,
  mask,
  setMask,
  snmpCommunity,
  setSnmpCommunity,
  networkError,
  maskError,
  loading,
  loadingProgress,
  updateScan,
  filter,
  setFilter,
  filteredSwitches,
  selectedSwitches,
  handleSelectAll,
  handleSwitchSelection,
  completeSwitches,
  sanitizeDescription
}) => {
  return (
    <>
      <div style={{ display: 'flex', gap: '10px' }}>
        <TextField
          label="Network"
          value={network}
          onChange={(e) => setNetwork(e.target.value)}
          fullWidth
          margin="normal"
          error={!!networkError}
          helperText={networkError || 'Example: 192.168.0.0'}
        />
        <TextField
          label="Mask"
          value={mask}
          onChange={(e) => setMask(e.target.value)}
          fullWidth
          margin="normal"
          error={!!maskError}
          helperText={maskError || 'Example: 255.255.255.0'}
        />
      </div>
      <TextField
        label="SNMP Community String"
        value={snmpCommunity}
        onChange={(e) => setSnmpCommunity(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button onClick={updateScan} variant="contained" color="primary" disabled={loading}>
        {loading ? <CircularProgress size={24} /> : 'Search'}
      </Button>
      <TextField
        label="Filter by IP, Name, Model, Description"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        fullWidth
        margin="normal"
      />
      {loading && (
        <div className="overlay">
          <LinearProgress variant="determinate" value={loadingProgress} />
          <Typography variant="body1" style={{ marginTop: '10px' }}>
            {loadingProgress}%
          </Typography>
        </div>
      )}
      {filteredSwitches.length > 0 && (
        <div style={{ maxHeight: '400px', overflowY: 'auto', marginTop: '20px' }} className={loading ? 'blur' : ''}>
          <Table style={{ backgroundColor: "rgba(0,0,0,0.05)", width: "100%", borderRadius: '10px' }}>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>IP Address</TableCell>
                <TableCell>Model</TableCell>
                <TableCell>Platform</TableCell>
                <TableCell>Firmware</TableCell>
                <TableCell>
                  <input
                    type="checkbox"
                    checked={selectedSwitches.length === filteredSwitches.length}
                    onChange={handleSelectAll}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredSwitches.map((switchNode, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{switchNode.sysName}</TableCell>
                  <TableCell>{switchNode.ip}</TableCell>
                  <TableCell>{switchNode.model}</TableCell>
                  <TableCell>{switchNode.description}</TableCell>
                  <TableCell style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} title={sanitizeDescription(switchNode.software_version)}>
                    {sanitizeDescription(switchNode.software_version)}
                  </TableCell>
                  <TableCell>
                    <input
                      type="checkbox"
                      checked={selectedSwitches.includes(switchNode) || completeSwitches.some(completeSwitch => completeSwitch.ip === switchNode.ip)}
                      onChange={() => handleSwitchSelection(switchNode)}
                      disabled={completeSwitches.some(completeSwitch => completeSwitch.ip === switchNode.ip)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </>
  );
};

export default SwitchModalSNMPAdd;
