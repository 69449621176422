import { CssBaseline, ThemeProvider } from "@mui/material";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FirebaseContext, FirebaseProvider } from '../data/provide_firestore';
import { db } from '../firebase_config';
import "../index.css";
import AddScanner from '../scenes/addscanner';
import Eth_Overview from '../scenes/dashboard/index_eth';
import Overview_Dashboard from "../scenes/dashboard/dashbord";
import Wifi_Overview from '../scenes/dashboard/index_wifi';
import History from '../scenes/dashboard/scanner_history';
import SwMGMT from "../scenes/dashboard/scanner_sw_mgmt";
import Pen from '../scenes/dashboard/scanner_pen';
import Scan from '../scenes/dashboard/scanner_scan';
import Settings from '../scenes/dashboard/scanner_settings';
import SwitchSettings from "../scenes/dashboard/swMgmt_settings";
import Sidebar from "../scenes/global/Sidebar";
import Topbar from "../scenes/global/Topbar";
import UserSettings from '../settings';
import { ColorModeContext, useMode } from "../theme";
import FormDialog from './modal_company';

export const ScannersContext = createContext();

const Dashboard = ({ site, value }) => {
  const [theme, colorMode] = useMode();
  const [name, setName] = useState([]);
  const [token, setToken] = useState(null);
  const [getUid, setUid] = useState("");
  const [expire, setExpire] = useState("");
  const [company, setCompany] = useState("");
  const [companyModal, setCompanyModal] = useState(false);
  const [scanners, setScanners] = useState([]);
  const [swManagers, setSwManagers] = useState([]);
  const [isSidebar, setIsSidebar] = useState(true);
  const [scannerData, setScannerData] = useState({});
  const [swData, setSwData] = useState({});

  console.log(site);

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const navigate = useNavigate();
  let { id } = useParams();
  const { userCompany } = useContext(FirebaseContext);

  useEffect(() => {
    if (useContext != "initial") refreshToken();
  }, [userCompany]);

  const refreshToken = async () => {
    if (userCompany) {
      setCompany(userCompany);
      setToken(true);
      var dataRef = [];
      var swRef = [];
      const tempScanners = [];
      const switchManagers = [];
      if (userCompany.toLowerCase() === "admin") {
        dataRef = query(collection(db, "Scanners"));
        swRef = query(collection(db, "SwitchMgmt"));
      } else {
        dataRef = query(
          collection(db, "Scanners"),
          where("company", "==", userCompany.toLowerCase())
        );
        swRef = query(
          collection(db, "SwitchMgmt"),
          where("company", "==", userCompany.toLowerCase())
        );
      }
      const dataSnap = await getDocs(dataRef);
      const swSnap = await getDocs(swRef);
      dataSnap.docs.map((y) => {
        if (y.data().scanner !== "hsINIT") {
          tempScanners.push({
            scanner: y.data().scanner,
            alias: y.data().alias,
          });
        }
      });
      swSnap.docs.map((y) => {
        console.log(y.data());
          switchManagers.push({
            name: y.data().name,
            alias: y.data().alias,
          });
      });
      setScannerData(dataSnap)
      setSwData(swSnap);
      setScanners(tempScanners);
      setSwManagers(switchManagers)
    } else {
      setToken(false);
    }
  };

  const isIdValid = (id, scanners) => {
    return scanners.some((scanner) => scanner.scanner === id);
  };

  if (userCompany == "initial") {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ScannersContext.Provider value={scanners}>
        <FirebaseProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {token == false && <FormDialog setCompanyModal={setCompanyModal} />}
            <div className="app">
              {isSidebar && (
                <Sidebar
                  isSidebar={isSidebar}
                  name={name}
                  probes={scanners}
                  swManagers={swManagers}
                  scannerData={scannerData}
                  company={company}
                  id={id}
                />
              )}
              <main className={`content ${!isSidebar ? 'full-width' : ''}`}>
                <Topbar setIsSidebar={setIsSidebar} toggleSidebar={toggleSidebar} />
                {site === "dashboard" && (
                  <Overview_Dashboard
                    company={userCompany}
                    scanner={id}
                    scannerdata={scanners}
                  ></Overview_Dashboard>
                )}
                {((site === "dashboard") || isIdValid(id, scanners)) ? (
                  <>
                {site === "settings" && (
                  <Settings
                    company={company}
                    id={id}
                    site={"settings"}
                    scannerData={scannerData}
                  ></Settings>
                )}
                {site === "swSettings" && (
                  <SwitchSettings
                    company={company}
                    id={id}
                    site={"swSettings"}
                    swData={swData}
                  ></SwitchSettings>
                )}
                {site === "pen" && (
                  <Pen company={company} id={id} site={"pen"}></Pen>
                )}
                {site === "swmgmt" && (
                  <SwMGMT company={company} id={id} site={"pen"}></SwMGMT>
                )}
                {site === "scan" && (
                  <Scan company={company} id={id} site={"scan"}></Scan>
                )}
                {site === "eth" && (
                  <Eth_Overview
                    company={userCompany}
                    scanner={id}
                    scannerdata={scanners}
                  ></Eth_Overview>
                )}
                {site === "wifi" && (
                  <Wifi_Overview
                    company={userCompany}
                    scanner={id}
                    scannerdata={scanners}
                  ></Wifi_Overview>
                )}
                {site === "addScanner" && (
                  <AddScanner
                    company={company}
                    scanner={id}
                    scannerdata={scanners}
                  ></AddScanner>
                )}
                {site === "UserSettings" && (
                  <UserSettings
                    company={company}
                    scanner={id}
                    scannerdata={scanners}
                  ></UserSettings>
                )}
                {site === "history" && (
                  <History
                    company={company}
                    id={id}
                    scannerdata={scanners}
                  ></History>
                )}
                </>
                ) : (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <h2>No access</h2>
                  </div>
                )}
              </main>
            </div>
          </ThemeProvider>
        </FirebaseProvider>
      </ScannersContext.Provider>
    </ColorModeContext.Provider>
  );
};

export default Dashboard;
