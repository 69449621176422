import React, { useEffect } from 'react';
import { FormControl, InputLabel, OutlinedInput, FormControlLabel, Typography, Switch, InputAdornment, Tooltip, IconButton } from '@mui/material';
import { tokens } from '../theme';
import { useTheme } from '@mui/system';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const SwitchManagement = ({ apiUsername, setApiUsername, apiPassword, setApiPassword, useSameForAOS, setuseSameForAOS, apiUsernameAOS, setApiUsernameAOS, apiPasswordAOS, setApiPasswordAOS, sshUsername, setSshUsername, sshPassword, setSshPassword, useSameForSSH, setUseSameForSSH, snmpCommunity, setSnmpCommunity }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const updateFieldChanged = (name, value) => {
    switch (name) {
      case 'apiUsername':
        setApiUsername(value);
        break;
      case 'apiPassword':
        setApiPassword(value);
        break;
      case 'apiUsernameAOS':
        setApiUsernameAOS(value);
        break;
      case 'apiPasswordAOS':
        setApiPasswordAOS(value);
        break;
      case 'sshUsername':
        setSshUsername(value);
        break;
      case 'sshPassword':
        setSshPassword(value);
        break;
      case 'snmpCommunity':
        setSnmpCommunity(value);
        break;
      default:
        break;
    }
  };

  return (
    <div>


      <h4 className="settings" style={{ color: colors.blueAccent[100], marginBottom:"0px" }}>
        API Authentication Aruba CX
      </h4>
      <FormControl sx={{ marginTop: 1, width: "100%" }} variant="outlined">
        <InputLabel
          htmlFor="apiUsername"
          sx={{
            color: colors.grey[100],
            '&.Mui-focused': {
              color: colors.grey[100],
            },
            '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
              color: colors.grey[100],
            },
            '&.MuiInputLabel-outlined': {
              color: 'grey',
            },
          }}
        >
          API Username
        </InputLabel>
        <OutlinedInput
  id="apiUsername"
  name="apiUsername"
  type="text"
  value={apiUsername}
  readOnly
  onChange={(event) => updateFieldChanged('apiUsername', event.target.value)}
  label="API Username"
  sx={{
    "& .MuiOutlinedInput-input": {
      color: colors.grey[100],
    },
    '& .MuiOutlinedInput-input::placeholder': {
      color: colors.grey[100],
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.grey[100],
    },
    '&:hover > .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.grey[100],
    },
    '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.grey[100],
    },
    '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: colors.grey[100],
    },
  }}
  endAdornment={
    <InputAdornment position="end" >
      <Tooltip title="Must be local admin account">
        <IconButton>
          <HelpOutlineIcon sx={{ color: colors.grey[100] }} />
        </IconButton>
      </Tooltip>
    </InputAdornment>
  }
/>
      </FormControl>
      <FormControl sx={{ marginTop: 1, width: "100%" }} variant="outlined">
        <InputLabel
          htmlFor="apiPassword"
          sx={{
            color: colors.grey[100],
            '&.Mui-focused': {
              color: colors.grey[100],
            },
            '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
              color: colors.grey[100],
            },
            '&.MuiInputLabel-outlined': {
              color: 'grey',
            },
          }}
        >
          API Password
        </InputLabel>
        <OutlinedInput
          id="apiPassword"
          name="apiPassword"
          type="password"
          value={apiPassword}
          onChange={(event) => updateFieldChanged('apiPassword', event.target.value)}
          label="API Password"
          sx={{
            "& .MuiOutlinedInput-input": {
              color: colors.grey[100],
            },
            '& .MuiOutlinedInput-input::placeholder': {
              color: colors.grey[100],
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.grey[100],
            },
            '&:hover > .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.grey[100],
            },
            '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.grey[100],
            },
            '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
              color: colors.grey[100],
            },
          }}
        />
      </FormControl>
      <FormControlLabel
        control={
          <Switch
            checked={useSameForAOS}
            onChange={() => setuseSameForAOS(!useSameForAOS)}
            name="useSameForAOS"
            color="primary"
            sx={{
              '& .MuiSwitch-thumb': {
                backgroundColor: useSameForAOS ? colors.greenAccent[400] : colors.grey[400],
              },
              '& .MuiSwitch-track': {
                backgroundColor: useSameForAOS ? colors.greenAccent[400] : colors.grey[400],
              },
              '&:hover .MuiSwitch-thumb': {
                backgroundColor: useSameForAOS ? colors.greenAccent[400] : colors.grey[400],
              },
              '&:hover .MuiSwitch-track': {
                backgroundColor: useSameForAOS ? colors.greenAccent[400] : colors.grey[400],
              },
            }}
          />
        }
        label={<Typography style={{ color: colors.grey[100] }}>Use same for AOS-S</Typography>}
      />
      {!useSameForAOS && (
        <>
          <FormControl sx={{ marginTop: 1, width: "100%" }} variant="outlined">
            <InputLabel
              htmlFor="apiUsernameAOS"
              sx={{
                color: colors.grey[100],
                '&.Mui-focused': {
                  color: colors.grey[100],
                },
                '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                  color: colors.grey[100],
                },
                '&.MuiInputLabel-outlined': {
                  color: 'grey',
                },
              }}
            >
              API Username AOS-S
            </InputLabel>
            <OutlinedInput
              id="apiUsernameAOS"
              name="apiUsernameAOS"
              type='text'
              value={apiUsernameAOS}
              onChange={(event) => updateFieldChanged('apiUsernameAOS', event.target.value)}
              label="API Username AOS-S"
              sx={{
                "& .MuiOutlinedInput-input": {
                  color: colors.grey[100],
                },
                '& .MuiOutlinedInput-input::placeholder': {
                  color: colors.grey[100],
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.grey[100],
                },
                '&:hover > .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.grey[100],
                },
                '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.grey[100],
                },
                '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                  color: colors.grey[100],
                },
              }}
            />
          </FormControl>
          <FormControl sx={{ marginTop: 1, width: "100%" }} variant="outlined">
            <InputLabel
              htmlFor="apiPasswordAOS"
              sx={{
                color: colors.grey[100],
                '&.Mui-focused': {
                  color: colors.grey[100],
                },
                '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                  color: colors.grey[100],
                },
                '&.MuiInputLabel-outlined': {
                  color: 'grey',
                },
              }}
            >
              API Password AOS-S
            </InputLabel>
            <OutlinedInput
              id="apiPasswordAOS"
              name="apiPasswordAOS"
              type="password"
              value={apiPasswordAOS}
              onChange={(event) => updateFieldChanged('apiPasswordAOS', event.target.value)}
              label="API Password AOS-S"
              sx={{
                "& .MuiOutlinedInput-input": {
                  color: colors.grey[100],
                },
                '& .MuiOutlinedInput-input::placeholder': {
                  color: colors.grey[100],
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.grey[100],
                },
                '&:hover > .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.grey[100],
                },
                '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.grey[100],
                },
                '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                  color: colors.grey[100],
                },
              }}
            />
          </FormControl>
        </>
      )}
              <div style={{ textAlign: 'left', marginTop: '20px' }}>
              <hr style={{ width: "50%", margin: 0, borderTop: `1px solid ${colors.blueAccent[100]}` }} />
        <h4 className="settings" style={{ color: colors.blueAccent[100], marginBottom:"0px" }}>
        
            SSH Authentication
        </h4>
        <FormControlLabel
        control={
          <Switch
            checked={useSameForSSH}
            onChange={() => setUseSameForSSH(!useSameForSSH)}
            name="useSameForSSH"
            color="primary"
            sx={{
              '& .MuiSwitch-thumb': {
                backgroundColor: useSameForSSH ? colors.greenAccent[400] : colors.grey[400],
              },
              '& .MuiSwitch-track': {
                backgroundColor: useSameForSSH ? colors.greenAccent[400] : colors.grey[400],
              },
              '&:hover .MuiSwitch-thumb': {
                backgroundColor: useSameForSSH ? colors.greenAccent[400] : colors.grey[400],
              },
              '&:hover .MuiSwitch-track': {
                backgroundColor: useSameForSSH ? colors.greenAccent[400] : colors.grey[400],
              },
            }}
          />
        }
        label={<Typography style={{ color: colors.grey[100] }}>Use same as CX API Authentication</Typography>}
      />
        </div>
        {!useSameForSSH && (
            <>
      <FormControl sx={{ marginTop: 1, width: "100%" }} variant="outlined">
        <InputLabel
          htmlFor="sshUsername"
          sx={{
            color: colors.grey[100],
            '&.Mui-focused': {
              color: colors.grey[100],
            },
            '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
              color: colors.grey[100],
            },
            '&.MuiInputLabel-outlined': {
              color: 'grey',
            },
          }}
        >
          SSH Username
        </InputLabel>
        <OutlinedInput
          id="sshUsername"
          name="sshUsername"
          type='text'
          value={sshUsername}
          onChange={(event) => updateFieldChanged('sshUsername', event.target.value)}
          label="SSH Username"
          sx={{
            "& .MuiOutlinedInput-input": {
              color: colors.grey[100],
            },
            '& .MuiOutlinedInput-input::placeholder': {
              color: colors.grey[100],
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.grey[100],
            },
            '&:hover > .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.grey[100],
            },
            '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.grey[100],
            },
            '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
              color: colors.grey[100],
            },
          }}
        />
      </FormControl>
      <FormControl sx={{ marginTop: 1, width: "100%" }} variant="outlined">
        <InputLabel
          htmlFor="sshPassword"
          sx={{
            color: colors.grey[100],
            '&.Mui-focused': {
              color: colors.grey[100],
            },
            '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
              color: colors.grey[100],
            },
            '&.MuiInputLabel-outlined': {
              color: 'grey',
            },
          }}
        >
          SSH Password
        </InputLabel>
        <OutlinedInput
          id="sshPassword"
          name="sshPassword"
          type="password"
          value={sshPassword}
          onChange={(event) => updateFieldChanged('sshPassword', event.target.value)}
          label="SSH Password"
          sx={{
            "& .MuiOutlinedInput-input": {
              color: colors.grey[100],
            },
            '& .MuiOutlinedInput-input::placeholder': {
              color: colors.grey[100],
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.grey[100],
            },
            '&:hover > .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.grey[100],
            },
            '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.grey[100],
            },
            '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
              color: colors.grey[100],
            },
          }}
        />
      </FormControl>
      </>
        )}
        <div style={{ textAlign: 'left', marginTop: '20px' }}>
        <hr style={{ width: "50%", margin: 0, borderTop: `1px solid ${colors.blueAccent[100]}` }} />
        <h4 className="settings" style={{ color: colors.blueAccent[100], marginBottom:"0px" }}>
        SNMP Configuration
        </h4>
        </div>
      <FormControl sx={{ marginTop: 1, width: "100%" }} variant="outlined">
        <InputLabel
          htmlFor="snmpCommunity"
          sx={{
            color: colors.grey[100],
            '&.Mui-focused': {
              color: colors.grey[100],
            },
            '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
              color: colors.grey[100],
            },
            '&.MuiInputLabel-outlined': {
              color: 'grey',
            },
          }}
        >

          SNMP Community String
        </InputLabel>
        <OutlinedInput
          id="snmpCommunity"
          name="snmpCommunity"
          type='text'
          value={snmpCommunity}
          onChange={(event) => updateFieldChanged('snmpCommunity', event.target.value)}
          label="SNMP Community String"
          sx={{
            "& .MuiOutlinedInput-input": {
              color: colors.grey[100],
            },
            '& .MuiOutlinedInput-input::placeholder': {
              color: colors.grey[100],
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.grey[100],
            },
            '&:hover > .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.grey[100],
            },
            '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.grey[100],
            },
            '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
              color: colors.grey[100],
            },
          }}
        />
      </FormControl>
    </div>
  );
};

export default SwitchManagement;
