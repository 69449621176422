import React, { useState, useEffect, useContext, useMemo, useCallback, useRef } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { httpsCallable } from "firebase/functions";
import { functions, db, callCloudFunction } from "../../firebase_config";
import { useTheme, Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Typography, IconButton } from "@mui/material";
import { tokens } from "../../theme";
import { updateDoc, doc } from "firebase/firestore";
import SwitchModal from '../../components/SwitchModal';
import { FirebaseContext } from '../../data/provide_firestore';
import NetworkGraph from '../../components/NetworkGraph';
import _ from 'lodash';
import SwitchTable from '../../components/SwitchTable';
import './sw_mgmt.css';
import useOptimizedDataFetching from '../../data/fetch_data';
import MultiUpgradeDialog from '../../components/SwitchMultipleUpgrade';
import { Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogDialog from '../../components/Log_Dialog';

const SwMGMT = ({ company, id, site }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [tabValue, setTabValue] = useState(1);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const {
    completeSwitches,
    onlineStatus,
    apiStatus,
    neighborsStatus,
    switchStatus,
    fetchAllData,
    setCompleteSwitches
  } = useOptimizedDataFetching(id, useContext(FirebaseContext).getScannerInfo);

  const [dialogData, setDialogData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [usedLicenses, setUsedLicenses] = useState([]);
  const [ownedLicenses, setOwnedLicenses] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState('');
  const [selectedSwitch, setSelectedSwitch] = useState(null);
  const [availableVersions, setAvailableVersions] = useState([]);
  const [loadingUpgrade, setLoadingUpgrade] = useState(false);
  const [loadingRefresh, setLoadingRefresh] = useState(false);
  const [multiUpgradeModalOpen, setMultiUpgradeModalOpen] = useState(false);
  const [selectedSwitchesForUpgrade, setSelectedSwitchesForUpgrade] = useState([]);
  const [rebootDialogOpen, setRebootDialogOpen] = useState(false);
  const [switchToReboot, setSwitchToReboot] = useState(null);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [selectedFailedSwitch, setSelectedFailedSwitch] = useState(null);
  const [enableApiModalOpen, setEnableApiModalOpen] = useState(false);
  const [selectedSwitchesForApi, setSelectedSwitchesForApi] = useState([]);
  const [loadingApi, setLoadingApi] = useState(false);
  const [upgradeData, setUpgradeData] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [selectedSwitchInTable, setSelectedSwitchInTable] = useState(null);
  const [userManualSwitches, setUserManualSwitches] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [switchVersions, setSwitchVersions] = useState({});
  const [isUpgradeDisabled, setIsUpgradeDisabled] = useState(false);
  const [newSwitchLoading, setNewSwitchLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'ascending' });
  const [scannerInfo, setScannerInfo] = useState(null);
  const [isNetworkFullscreen, setIsNetworkFullscreen] = useState(false);
  const [isGraphInitialized, setIsGraphInitialized] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openLogDialog, setOpenLogDialog] = useState(false);
  const [logs, setLogs] = useState([]);
  const [graphLoading, setGraphLoadning] = useState(true);

  const { getScannerInfo } = useContext(FirebaseContext);


  useEffect(() => {
    if (getScannerInfo) {
      setScannerInfo(getScannerInfo);
    }
  }, [getScannerInfo]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsNetworkFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  useEffect(() => {
    if (completeSwitches) {
      setUsedLicenses(completeSwitches.length);
    }
  }, [completeSwitches]);

  const sortedSwitches = useMemo(() => {
    return [...completeSwitches]
      .filter(switchNode => switchNode.api_access === 0 && switchNode.api_model === "AOS-S")
      .sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
  }, [completeSwitches, sortConfig]);

  const requestSort = useCallback((key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  }, [sortConfig]);

  const fetchAvailableVersions = useCallback(async () => {
    try {
      const data = { data: {} };
      const result = await callCloudFunction('get_img_versions', data);
      setAvailableVersions(result.result.versions);
    } catch (error) {
      console.error('Failed to fetch available versions:', error);
    }
  }, []);

  const fetchConfigAndDownload = useCallback(async (ip, scanner, switchnode) => {
    try {
      const data = { data: { 'ip': ip, 'scanner': scanner } };
      const response = await callCloudFunction('get_config', data);

      const resp_data = response;
      const config = resp_data?.result?.[0]?.[0]?.config;

      const blob = new Blob([config], { type: 'text/plain' });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = `${ip}.cfg`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error('Error fetching config:', error);
    }
  }, []);

  const isUpgradeAvailable = useCallback((currentVersion, availableVersions) => {
    if (typeof currentVersion !== 'string') {
      console.error('currentVersion is not a string:', currentVersion);
      return { available: false, latestVersion: null };
    }
  
    const currentPrefix = currentVersion.slice(0, 2);
    const matchingVersions = availableVersions.filter(ver => {
      if (typeof ver.sw_version !== 'string') {
        console.error('availableVersion is not a string:', ver.sw_version);
        return false;
      }
      return ver.sw_version.startsWith(currentPrefix) && currentVersion >= ver.min_version;
    });
  
    const latestVersion = matchingVersions.reduce((latest, ver) => {
      return ver.sw_version > latest.sw_version ? ver : latest;
    }, { sw_version: '0.0' });
  
    const available = matchingVersions.some(ver => ver.sw_version > currentVersion);
  
    return { available, latestVersion };
  }, []);

  const refresh_switches = useCallback(async () => {
    setLoadingRefresh(true);
    await updateDoc(doc(db, "Scanners", id), {
      check_API_status: 1,
      updateSwitches: 1,
      updateNeighbors: 1,
    });
  }, [id]);

  useEffect(() => {
    const versions = {};
    completeSwitches
      .filter(switchNode => upgradeData.some(data => data.ip === switchNode.ip) && switchNode.sw_status === 0)
      .forEach((switchNode) => {
        const upgradeInfo = upgradeData.find(data => data.ip === switchNode.ip);
        versions[switchNode.id] = upgradeInfo?.latestVersion;
      });
    setSwitchVersions(versions);
  }, [completeSwitches, upgradeData]);

  const handleRequestSort = useCallback((header) => {
    const property = headerToPropertyMap[header];
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }, [order, orderBy]);

  useEffect(() => {
    console.log(getScannerInfo?.[id]?.switchesFirmware);
    if (getScannerInfo?.[id]?.switchesFirmware === 1) {
      setIsUpgradeDisabled(true);
    } else {
      setIsUpgradeDisabled(false);
    }
  }, [getScannerInfo?.[id]?.switchesFirmware, id]);

  useEffect(() => {
    if (getScannerInfo?.[id]?.newSwitchCheck === 1) {
      setNewSwitchLoading(true);
    } else {
      setNewSwitchLoading(false);
    }
  }, [getScannerInfo?.[id]?.newSwitchCheck, id]);

  useEffect(() => {
    setOwnedLicenses(getScannerInfo?.[id]?.Licenses);
  }, [getScannerInfo, id]);

  useEffect(() => {
    if (getScannerInfo?.[id]?.updateNeighbors === 0) {
      setLoadingRefresh(false);
    }
  }, [getScannerInfo?.[id]?.updateNeighbors, id]);

  const refresh_reboot_switches = useCallback(async () => {
    await updateDoc(doc(db, "Scanners", id), {
      updateRebootSwitch: 1,
    });
  }, [id]);

  useEffect(() => {
    const data = completeSwitches
      .filter(switchNode => {
        const upgradeStatus = isUpgradeAvailable(switchNode.version, availableVersions);
        return upgradeStatus.available;
      })
      .map(switchNode => {
        const upgradeStatus = isUpgradeAvailable(switchNode.version, availableVersions);
        return {
          ip: switchNode.ip,
          latestVersion: upgradeStatus.latestVersion.sw_version || 'N/A'
        };
      });
    setUpgradeData(data);
  }, [completeSwitches, availableVersions, isUpgradeAvailable]);

  const getApiAccessStatus = useCallback((value, loading = false) => {
    switch (value) {
      case 0:
        return { text: "Disabled", color: "red", tooltip: "", status: 0 };
      case 1:
        return { text: "Enabled", color: "green", tooltip: "", status: 1 };
      case 2:
        return { text: loading ? <CircularProgress size={24} /> : "Unknown", color: "yellow", tooltip: "", status: 2 };
      case 5:
        return { text: "API Login failed", color: "red", tooltip: "Go to settings and check your API credentials", status: 5 };
      case 6:
        return { text: "SSH Login failed", color: "red", tooltip: "Go to settings and check your SSH credentials", status: 6 };
      case 10:
        return { text: "API Failed", color: "red", tooltip: "Go to settings and check your API credentials", status: 10 };
      case 11:
        return { text: "SSH Failed", color: "red", tooltip: "Go to settings and check your SSH credentials", status: 11 };
      default:
        return { text: "Unknown", color: "gray", tooltip: "" };
    }
  }, []);

  const handleCheckboxChangeApi = useCallback((switchIp) => {
    setSelectedSwitchesForApi(prevSelected =>
      prevSelected.includes(switchIp)
        ? prevSelected.filter(ip => ip !== switchIp)
        : [...prevSelected, switchIp]
    );
  }, []);

  const handleSelectAllApi = useCallback(() => {
    const filteredSwitches = completeSwitches
      .filter(switchNode => switchNode.api_access === 0)
      .map(switchNode => switchNode.ip);

    setSelectedSwitchesForApi(prevSelected => 
      prevSelected.length === filteredSwitches.length ? [] : filteredSwitches
    );
  }, [completeSwitches]);

  const enable_API = useCallback(async (selectedIps) => {
    setLoadingApi(true);

    const results = selectedIps.map(ip => ({ ip, api_status: 4 }));
    const data = {
      action: "api_status",
      switch_data: {
        [id]: results
      }
    };

    try {
      const response = await fetch('https://europe-west1-scanner-fb148.cloudfunctions.net/mysql-snmp-network', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        const errorData = await response.text();
        console.error(`Failed to enable API: ${errorData}`);
      } else {
        console.log('API enabled successfully');
        await updateDoc(doc(db, "Scanners", id), {
          enable_API: 1,
        });
      }
    } catch (error) {
      console.error(`Failed to fetch: ${error.message}`);
    } finally {
      setLoadingApi(false);
      setEnableApiModalOpen(false);
    }
  }, [id]);

  const updateApi = async (ip) => {
    const apiUrl = 'https://europe-west1-scanner-fb148.cloudfunctions.net/mysql-snmp-network';
    const results = [{ ip: ip, api_status: 2 }];
  
    if (results.length > 0) {
      const data = {
        action: "api_status",
        switch_data: {
          [id]: results
        }
      };
  
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const result = await response.json();
        console.log('API update successful:', result);
      } catch (error) {
        console.error('Error updating API:', error);
      }finally {
      await updateDoc(doc(db, "Scanners", id), {
        check_API_status: 1,
      });
      }
    }
  };

  const delete_id = async (sw_id) => {
    const apiUrl = 'https://europe-west1-scanner-fb148.cloudfunctions.net/mysql-snmp-network';
  
      const data = {
        action: "delete",
        switch_data: 
          {id : sw_id} 
      };
  
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const result = await response.json();
        console.log('API update successful:', result);
      } catch (error) {
        console.error('Error updating API:', error);
      }finally {
        setCompleteSwitches(prevSwitches => prevSwitches.filter(sw => sw.id !== sw_id));
      }
  };

  const handleRetryUpgrade = useCallback(async () => {
    setLoadingUpgrade(true);
    const API = httpsCallable(functions, "mysql-switches-upgrade");
    const data = { scanner: id, id: selectedFailedSwitch.id, action: 'upgrade', version: selectedVersion };

    try {
      const fullResult = await API({ data });
      if (fullResult.data) {
        console.log(`Switch state updated successfully`);

        await updateDoc(doc(db, "Scanners", id), {
          switchesFirmware: 1,
        });

        setErrorDialogOpen(false);
        fetchAllData();
      } else {
        console.error(`Failed to update switch state for switch: ${selectedFailedSwitch.id}`);
      }
    } catch (error) {
      console.error(`Failed to update switch state: ${error.message}`);
    } finally {
      setLoadingUpgrade(false);
    }
  }, [id, selectedFailedSwitch, selectedVersion, fetchAllData]);

  const handleRebootConfirm = useCallback(async () => {
    if (switchToReboot) {
      const API = httpsCallable(functions, "mysql-switches-upgrade");
      const data = { scanner: id, id: switchToReboot.id, action: 'reboot', version: switchToReboot.version };

      try {
        await API({ data });
        await refresh_reboot_switches();
        console.log(`Switch ${switchToReboot.name} rebooted successfully.`);
        fetchAllData();
      } catch (error) {
        console.error(`Failed to reboot switch: ${error.message}`);
      } finally {
        setRebootDialogOpen(false);
      }
    }
  }, [id, switchToReboot, refresh_reboot_switches, fetchAllData]);



  const handleUpgradeClick = useCallback((switchNode, latestVersion) => {
    setSelectedSwitch(switchNode);
    setSelectedVersion(latestVersion);
    setUpgradeModalOpen(true);
  }, []);

  const handleUpgradeConfirm = useCallback(async () => {
    setLoadingUpgrade(true);
    const API = httpsCallable(functions, "mysql-switches-upgrade");
    const data = { scanner: id, id: selectedSwitch.id, action: 'upgrade', version: selectedVersion };

    try {
      const fullResult = await API({ data });

      if (fullResult.data) {
        console.log(`Switch state updated successfully`);

        await updateDoc(doc(db, "Scanners", id), {
          switchesFirmware: 1,
        });

        setUpgradeModalOpen(false);
        fetchAllData();
      } else {
        console.error(`Failed to update switch state for switch: ${selectedSwitch.id}`);
      }
    } catch (error) {
      console.error(`Failed to update switch state: ${error.message}`);
    } finally {
      setLoadingUpgrade(false);
    }
  }, [id, selectedSwitch, selectedVersion, fetchAllData]);

  const handleMultiUpgradeConfirm = useCallback(async () => {
    setLoadingUpgrade(true);

    try {
      const API = httpsCallable(functions, "mysql-switches-upgrade");
      for (const switchId of selectedSwitchesForUpgrade) {
        const switchNode = completeSwitches.find(sw => sw.id === switchId);
        const latestVersion = switchVersions[switchNode.id];

        const data = { scanner: id, id: switchNode.id, action: 'upgrade', version: latestVersion };
        await API({ data });
      }

      await updateDoc(doc(db, "Scanners", id), {
        switchesFirmware: 1,
      });

      setMultiUpgradeModalOpen(false);
      fetchAllData();

    } catch (error) {
      console.error('Failed to upgrade switches:', error);
    } finally {
      setLoadingUpgrade(false);
    }
  }, [id, selectedSwitchesForUpgrade, completeSwitches, switchVersions, fetchAllData]);

  const headerToPropertyMap = {
    'NAME': 'name',
    'IP': 'ip',
    'VERSION': 'version',
    'MODEL': 'model',
    'PLATFORM': 'platform',
    'STATUS': 'sw_status',
    'APIACCESS': 'api_access',
    'Config': 'config'
  };

  const getApiAccessStatusText = useCallback((value) => {
    return getApiAccessStatus(value).text.toLowerCase();
  }, [getApiAccessStatus]);

  const filteredSwitches = useMemo(() => {
    const lowerCaseFilter = filter.toLowerCase();
    return completeSwitches.filter(switchNode => {
      return (
        switchNode.ip.toLowerCase().includes(lowerCaseFilter) ||
        switchNode.model.toLowerCase().includes(lowerCaseFilter) ||
        switchNode.name.toLowerCase().includes(lowerCaseFilter) ||
        switchNode.version.toLowerCase().includes(lowerCaseFilter) ||
        switchNode.label.toLowerCase().includes(lowerCaseFilter) ||
        switchNode.status.toLowerCase().includes(lowerCaseFilter) ||
        getApiAccessStatusText(switchNode.api_access).toLowerCase().includes(lowerCaseFilter)
      );
    });
  }, [completeSwitches, filter, getApiAccessStatusText]);

  const sortedFilteredSwitches = useMemo(() => {
    const comparator = (a, b) => {
      let comparison = 0;

      if (orderBy === 'ip') {
        const ipA = a.ip.split('.').map(Number);
        const ipB = b.ip.split('.').map(Number);
        for (let i = 0; i < ipA.length; i++) {
          if (ipA[i] !== ipB[i]) {
            comparison = (ipA[i] - ipB[i]);
            break;
          }
        }
      } else if (orderBy === 'sw_status') {
        const statusA = a.status.toLowerCase();
        const statusB = b.status.toLowerCase();
        comparison = statusA.localeCompare(statusB);
      } else if (orderBy === 'api_access') {
        const apiAccessTextA = getApiAccessStatusText(a.api_access).toLowerCase();
        const apiAccessTextB = getApiAccessStatusText(b.api_access).toLowerCase();
        comparison = apiAccessTextA.localeCompare(apiAccessTextB);
      } else {
        const valueA = a[orderBy]?.toLowerCase() || '';
        const valueB = b[orderBy]?.toLowerCase() || '';
        if (valueA < valueB) {
          comparison = -1;
        } else if (valueA > valueB) {
          comparison = 1;
        }
      }

      return order === 'asc' ? comparison : -comparison;
    };

    return [...filteredSwitches].sort(comparator);
  }, [filteredSwitches, order, orderBy, getApiAccessStatusText]);

  const handleRebootClick = useCallback((switchNode) => {
    setSwitchToReboot(switchNode);
    setRebootDialogOpen(true);
  }, []);

  const handleRowClick = useCallback((chassis_id) => {
    console.log(chassis_id);
    setSelectedSwitchInTable(chassis_id);
    setTabValue(0);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);


  const get_loggs = useCallback(
    async (sw_id) => {
      try {

        const switchNode = completeSwitches.find((sw) => sw.id === sw_id);

        if (switchNode && typeof switchNode.logs === 'string') {
          setLogs(JSON.parse(switchNode.logs));
        } else if (switchNode && Array.isArray(switchNode.logs)) {
          setLogs(switchNode.logs);
        } else {
          setLogs([]); // Ensure logs is an empty array if not found or not an array
        }

        setOpenLogDialog(true);
      } catch (error) {
        console.error('Failed to get logs:', error);
        setLogs([]); // Ensure logs is an empty array on error
      }
    },
    [completeSwitches]
  );

  useEffect(() => {
    fetchAllData();
    fetchAvailableVersions();
  }, [modalOpen, fetchAllData, fetchAvailableVersions]);

  const useStyles = makeStyles((theme) => ({
    activeSortLabel: {
      color: 'black !important',
    },
  }));

  return (
    <section>
      <Box sx={{ width: '95%', height: 'auto', margin: '0 auto' }}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab 
            label="Network Visualization"
            sx={{
              backgroundColor: tabValue === 0 ? colors.grey[900] : 'none',
              color: tabValue === 0 ? colors.grey[400] : colors.grey[400],
              fontWeight: tabValue === 0 ? 'bold' : 'normal',
              borderRadius: '6px 6px 0 0',
              border: tabValue === 0 ? `2px solid ${colors.primary[600]}` : `1px solid ${colors.primary[800]}`,
              borderBottom: tabValue === 0 ? `2px solid ${colors.primary[600]}` : `1px solid ${colors.primary[800]}`,
              padding: '10px 20px'
            }}
          />
          <Tab 
            label="Switch Table"
            sx={{
              backgroundColor: tabValue === 1 ? colors.grey[900] : 'none',
              color: tabValue === 1 ? colors.grey[400] : colors.grey[400],
              fontWeight: tabValue === 1 ? 'bold' : 'normal',
              borderRadius: '6px 6px 0 0',
              border: tabValue === 1 ? `2px solid ${colors.primary[600]}` : `1px solid ${colors.primary[800]}`,
              borderBottom: tabValue === 1 ? `2px solid ${colors.primary[600]}` : `1px solid ${colors.primary[800]}`,
              padding: '10px 20px'
            }}
          />
        </Tabs>
        <div style={{ display: tabValue === 0 ? 'block' : 'none' }}>
          <NetworkGraph
            completeSwitches={completeSwitches}
            setDialogData={setDialogData}
            highlightedNode={selectedSwitchInTable}
            isGraphInitialized={isGraphInitialized}
            setIsGraphInitialized={setIsGraphInitialized}
            setGraphLoadning={setGraphLoadning}
          />
                        {dialogData && (
                <div
                  style={{
                    position: 'absolute',
                    top: dialogData.y,
                    left: dialogData.x,
                    background: 'white',
                    border: '1px solid black',
                    padding: '10px',
                    borderRadius: '5px',
                    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                    zIndex: 1000,
                    maxWidth: '300px',
                    wordWrap: 'break-word',
                    whiteSpace: 'normal'
                  }}
                >
                  {dialogData.type === 'warning' ? (
                    <>
                      <p><strong>Name:</strong> {dialogData.name}</p>
                      <p><strong>IP Address:</strong> {dialogData.ip}</p>
                      <p><strong>Model:</strong> {dialogData.model}</p>
                      <p><strong>Info:</strong> {dialogData.info}</p>
                    </>
                  ) : dialogData.type === 'neighbor' ? (
                    <>
                      <p><strong>Name:</strong> {dialogData.name}</p>
                      <p><strong>IP Address:</strong> {dialogData.ip}</p>
                      <p><strong>Model:</strong> {dialogData.model}</p>
                    </>
                  ) : (
                    <>
                      <p><strong>Name:</strong> {dialogData.name}</p>
                      <p><strong>IP Address:</strong> {dialogData.ip}</p>
                      <p><strong>Firmware:</strong> {dialogData.version}</p>
                      <p><strong>Model:</strong> {dialogData.model}</p>
                      <p><strong>Status:</strong> {dialogData.status}</p>
                    </>
                  )}
                </div>
              )}
        </div>
        <div style={{ display: tabValue === 1 ? 'block' : 'none' }}>
          <div style={{ marginTop: "-20px" }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "-20px" }}>
              <div style={{ flexGrow: 1 }}></div>
              <IconButton onClick={handleClick}>
                <Typography style={{ marginRight: "10px" }} variant="h6">Menu</Typography>
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => { setModalOpen(true); handleClose(); }}>Add Switches</MenuItem>
                <MenuItem onClick={() => { setEnableApiModalOpen(true); setSelectedSwitchesForApi([]); handleClose(); }}>Enable API</MenuItem>
                <MenuItem onClick={() => { setMultiUpgradeModalOpen(true); setSelectedSwitchesForUpgrade([]); handleClose(); }}>Upgrade Multiple Switches</MenuItem>
                <MenuItem onClick={() => { refresh_switches(); handleClose(); }} disabled={loadingRefresh}>
                  {loadingRefresh ? <CircularProgress size={24} /> : 'Refresh Switches'}
                </MenuItem>
              </Menu>
            </div>

            <div style={{ marginTop: "20px" }}>
              <input
                type="text"
                placeholder="Filter by IP, name, Status or label"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                style={{
                  marginBottom: '20px',
                  padding: '10px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  width: '100%'
                }}
              />
              <SwitchTable
                sortedFilteredSwitches={sortedFilteredSwitches}
                handleRequestSort={handleRequestSort}
                orderBy={orderBy}
                order={order}
                headerToPropertyMap={headerToPropertyMap}
                getApiAccessStatus={getApiAccessStatus}
                isUpgradeAvailable={isUpgradeAvailable}
                handleRebootClick={handleRebootClick}
                handleRowClick={handleRowClick}
                selectedSwitchInTable={selectedSwitchInTable}
                handleUpgradeClick={handleUpgradeClick}
                isUpgradeDisabled={isUpgradeDisabled}
                fetchConfigAndDownload={fetchConfigAndDownload}
                setIsDialogOpen={setIsDialogOpen}
                setErrorDialogOpen={setErrorDialogOpen}
                setSelectedFailedSwitch={setSelectedFailedSwitch}
                availableVersions={availableVersions}
                id={id}
                useStyles={useStyles}
                newSwitchLoading={newSwitchLoading}
                get_loggs={get_loggs}
                delete_id={delete_id}
                updateApi={updateApi}
                graphLoading={graphLoading}
              />

              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '-40px' }}>

                <span style={{ marginLeft: '10px', color: (ownedLicenses - usedLicenses <= 5) ? 'red' : 'inherit' }}>
                  License Used: {usedLicenses} / {ownedLicenses}
                </span>
              </div>


            </div>
          </div>

          <SwitchModal open={modalOpen} handleClose={() => setModalOpen(false)} id={id} completeSwitches={completeSwitches} ownedLicenses={ownedLicenses} userManualSwitches={userManualSwitches} />

          <MultiUpgradeDialog
            multiUpgradeModalOpen={multiUpgradeModalOpen}
            setMultiUpgradeModalOpen={setMultiUpgradeModalOpen}
            completeSwitches={completeSwitches}
            upgradeData={upgradeData}
            selectedSwitchesForUpgrade={selectedSwitchesForUpgrade}
            setSelectedSwitchesForUpgrade={setSelectedSwitchesForUpgrade}
            isUpgradeAvailable={isUpgradeAvailable}
            availableVersions={availableVersions}
            handleMultiUpgradeConfirm={handleMultiUpgradeConfirm}
            loadingUpgrade={loadingUpgrade}
          />

          <Dialog open={rebootDialogOpen} onClose={() => setRebootDialogOpen(false)}>
            <DialogTitle>Confirm Reboot</DialogTitle>
            <DialogContent>
              <p>Are you sure you want to reboot switch to complete upgrade?</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleRebootConfirm} color="primary">
                Yes
              </Button>
              <Button onClick={() => setRebootDialogOpen(false)} color="secondary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={upgradeModalOpen} onClose={() => setUpgradeModalOpen(false)}>
            <DialogTitle>Confirm Upgrade</DialogTitle>
            <DialogContent>
              <p>Are you sure you want to upgrade to version {selectedVersion}?</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleUpgradeConfirm} color="primary" disabled={loadingUpgrade}>
                {loadingUpgrade ? <CircularProgress size={24} /> : 'Start Firmware Upgrade'}
              </Button>
              <Button onClick={() => setUpgradeModalOpen(false)} color="secondary" disabled={loadingUpgrade}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={errorDialogOpen} onClose={() => setErrorDialogOpen(false)}>
            <DialogTitle>Upgrade Failed</DialogTitle>
            <DialogContent>
              <p>Image upgrade failed because of API Read-Only access. To enable Read-Write access log in to the switch and type:</p>
              <p><strong>https-server rest access-mode read-write</strong></p>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleRetryUpgrade} color="primary" disabled={loadingUpgrade}>
                {loadingUpgrade ? <CircularProgress size={24} /> : 'Retry upgrade'}
              </Button>
              <Button onClick={() => setErrorDialogOpen(false)} color="secondary" disabled={loadingUpgrade}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={enableApiModalOpen} onClose={() => setEnableApiModalOpen(false)} maxWidth="md" fullWidth>
            <DialogTitle style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}>Enable AOS-Switch API Access</DialogTitle>
            <DialogContent>
              {loadingApi ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '150px' }}>
                  <CircularProgress />
                  <Typography variant="body1" style={{ marginLeft: '10px' }}>Enabling API...</Typography>
                </div>
              ) : (
                <>
                  <div style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '10px' }}>
                    <p>This will automatically enable the following commands in AOS-Switches (not CX switches):</p>
                    <p>"rest-interface"</p>
                    <p>"web-management plaintext"</p>
                    <p>"aaa authentication rest login radius local"</p>
                    <p>"aaa authentication rest enable radius local"</p>
                  </div>
                  <br />
                  <p>Select the AOS-Switches to enable API access:</p>
                  <table style={{ width: "100%", borderRadius: '10px' }}>
                    <thead>
                      <tr>
                        <th onClick={() => requestSort('name')}>Name</th>
                        <th onClick={() => requestSort('version')}>Version</th>
                        <th onClick={() => requestSort('ip')}>IP Address</th>
                        <th onClick={() => requestSort('api_access')}>API Access</th>
                        <th>
                          <input
                            type="checkbox"
                            checked={
                              completeSwitches.filter(switchNode => switchNode.api_access === 0 && switchNode.api_model === "AOS-S").length > 0 &&
                              selectedSwitchesForApi.length === completeSwitches.filter(switchNode => switchNode.api_access === 0 && switchNode.api_model === "AOS-S").map(switchNode => switchNode.ip).length
                            }
                            onChange={handleSelectAllApi}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedSwitches.map((switchNode, index) => (
                        <tr key={index}>
                          <td>{switchNode.name}</td>
                          <td>{switchNode.version}</td>
                          <td>{switchNode.ip}</td>
                          <td>{getApiAccessStatus(switchNode.api_access).text}</td>
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedSwitchesForApi.includes(switchNode.ip)}
                              onChange={() => handleCheckboxChangeApi(switchNode.ip)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => enable_API(selectedSwitchesForApi)} color="primary" disabled={loadingApi}>
                {loadingApi ? <CircularProgress size={24} /> : 'Enable API'}
              </Button>
              <Button onClick={() => setEnableApiModalOpen(false)} color="secondary" disabled={loadingApi}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
            <DialogTitle>API / SSH Access Failed</DialogTitle>
            <DialogContent>
              <Typography>
                API or SSH access is not working correctly.
                Please check the credentials for SSH and API in Settings.
                Ensure that the correct access is enabled for each.
                To enable auto API, click the button below.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => { setEnableApiModalOpen(true); setIsDialogOpen(false) }}>Auto Enable API</Button>
            </DialogActions>
          </Dialog>

          <LogDialog open={openLogDialog} onClose={() => setOpenLogDialog(false)} logs={logs} />

        </div>
      </Box>
    </section>
  );
};

export default SwMGMT;