import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Dialog, DialogActions, DialogContent, Button, Typography, CircularProgress,
  Box, IconButton, Tabs, Tab, DialogTitle
} from '@mui/material';
import { httpsCallable } from "firebase/functions";
import { functions, db } from "../firebase_config";
import { updateDoc, doc } from "firebase/firestore";
import { FirebaseContext } from '../data/provide_firestore';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';
import SwitchModalManualAdd from './SwitchModalManualAdd';
import SwitchModalSNMPAdd from './SwitchModalSNMPAdd';
import { toast } from 'react-toastify';
import './SwitchModal.css';

const SwitchModal = ({ open, handleClose, id, completeSwitches, ownedLicenses, userManualSwitches }) => {
  const [network, setNetwork] = useState('');
  const [mask, setMask] = useState('');
  const [loading, setLoading] = useState(false);
  const [switches, setSwitches] = useState([]);
  const [selectedSwitches, setSelectedSwitches] = useState([]);
  const [selectedManualSwitches, setSelectedManualSwitches] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [networkError, setNetworkError] = useState('');
  const [maskError, setMaskError] = useState('');
  const [exceedsLicense, setExceedsLicense] = useState(false);
  const [totalSelected, setTotalSelected] = useState(0);
  const [snmpCommunity, setSnmpCommunity] = useState('');
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [openApiStatusDialog, setOpenApiStatusDialog] = useState(false);
  const [preselectedSwitches, setPreselectedSwitches] = useState([]);
  const [newSelectedSwitches, setNewSelectedSwitches] = useState([]);
  const [loadingApiStatus, setLoadingApiStatus] = useState(false);
  const [prevCheckApiStatus, setPrevCheckApiStatus] = useState();
  const [filter, setFilter] = useState('');
  const [activeTab, setActiveTab] = useState(0);

  const { getScannerInfo } = useContext(FirebaseContext);
  const prevCheckApiStatusRef = useRef();
  const navigate = useNavigate();

  const handleSettingsClick = () => {
    navigate(`/${id}/settings`);
  };

  useEffect(() => {
    handleSearch();
    setLoadingProgress(getScannerInfo?.[id]?.Loading_snmp);
  }, []);

  useEffect(() => {
    setSnmpCommunity(getScannerInfo?.[id]?.snmp);
  }, [getScannerInfo?.[id]?.snmp]);

  useEffect(() => {
    setNetwork(getScannerInfo?.[id]?.switchesNetwork);
  }, [getScannerInfo?.[id]?.switchesNetwork]);

  useEffect(() => {
    setMask(getScannerInfo?.[id]?.switchesNetmask);
  }, [getScannerInfo?.[id]?.switchesNetmask]);

  useEffect(() => {
    if (getScannerInfo?.[id]?.updateSNMPNetwork === 0) {
      handleSearch();
      setLoading(false);
    }
  }, [getScannerInfo?.[id]?.updateSNMPNetwork]);

  useEffect(() => {
    if (switches.length > 0) {
      const preSelected = switches.filter(switchNode =>
        completeSwitches.some(completeSwitch => completeSwitch.ip === switchNode.ip)
      );
      setPreselectedSwitches(preSelected);
      setSelectedSwitches(preSelected);
    }
  }, [switches, completeSwitches]);

  useEffect(() => {
    const uniqueSelectedSwitches = new Set([...selectedSwitches.map(sw => sw.ip), ...completeSwitches.map(sw => sw.ip)]);
    setTotalSelected(uniqueSelectedSwitches.size);
    setExceedsLicense(uniqueSelectedSwitches.size > ownedLicenses);
  }, [selectedSwitches, completeSwitches, ownedLicenses]);

  useEffect(() => {
    setLoadingProgress(getScannerInfo?.[id]?.Loading_snmp);
  }, [getScannerInfo?.[id]?.Loading_snmp, id]);

  useEffect(() => {
    const currentCheckApiStatus = getScannerInfo?.[id]?.check_API_status;
    if (prevCheckApiStatusRef.current === 1 && currentCheckApiStatus === 0) {
      setLoadingApiStatus(false);
      setOpenApiStatusDialog(false);
      handleClose();
    }
    prevCheckApiStatusRef.current = currentCheckApiStatus;
  }, [getScannerInfo?.[id]?.check_API_status, getScannerInfo, id]);

  useEffect(() => {
    if (loadingProgress === 100) {
      setLoading(false);
    }
  }, [loadingProgress]);

  const handleSelectAll = () => {
    // Filter out the switches that are not already preselected
    const selectableFilteredSwitches = filteredSwitches.filter(switchNode =>
      !completeSwitches.some(completeSwitch => completeSwitch.ip === switchNode.ip)
    );
  
    // If all selectable switches are selected, deselect all
    if (selectedSwitches.length === selectableFilteredSwitches.length) {
      setSelectedSwitches([]);
    } else {
      // Otherwise, select all selectable switches
      if (ownedLicenses > completeSwitches.length + selectedSwitches.length) {
        setSelectedSwitches(selectableFilteredSwitches);
      }
    }
  };
  
  
  

  const handleSearch = async () => {
    setLoading(true);
    setSwitches([]);
    const response = await bigQuery();
    if (response?.[0]?.[0]?.data) {
      const data = JSON.parse(response[0][0].data);
      setSwitches(data);
    }
    setLoading(false);
  };

  const bigQuery = async () => {
    const doBigQuery = httpsCallable(functions, "get_snmp_switches");
    const data = { scanner: id };
    const fullResult = await doBigQuery(data);
    return fullResult.data;
  };

  const handleSwitchSelection = (switchNode) => {
    const isSelected = selectedSwitches.some(sw => sw.ip === switchNode.ip);
    if (!completeSwitches.some(completeSwitch => completeSwitch.ip === switchNode.ip)) {
      let newSelectedSwitches;
      if (isSelected) {
        newSelectedSwitches = selectedSwitches.filter(sw => sw.ip !== switchNode.ip);
      } else {
        newSelectedSwitches = [...selectedSwitches, switchNode];
      }

      const uniqueSelectedSwitches = new Set([...newSelectedSwitches.map(sw => sw.ip), ...completeSwitches.map(sw => sw.ip)]);
      if (uniqueSelectedSwitches.size > ownedLicenses) {
        setExceedsLicense(true);
      } else {
        setExceedsLicense(false);
        setSelectedSwitches(newSelectedSwitches);
      }
    }
  };

  function convertIpToSortable(ip) {
    return ip.split('.').map(num => (`000${num}`).slice(-3)).join('');
  }

  const sortedSwitches = [...switches].sort((a, b) => {
    const ipA = convertIpToSortable(a.ip);
    const ipB = convertIpToSortable(b.ip);
    return ipA.localeCompare(ipB);
  });

  const filteredSwitches = sortedSwitches.filter(switchNode => 
    switchNode.sysName.toLowerCase().includes(filter.toLowerCase()) ||
    switchNode.ip.includes(filter) ||
    switchNode.model.toLowerCase().includes(filter.toLowerCase()) ||
    switchNode.description.toLowerCase().includes(filter.toLowerCase())
  );

  const Check_API_status = async () => {
    if (selectedManualSwitches.length > 0) {
      handleAddSwitches(selectedManualSwitches);
      await updateDoc(doc(db, "Scanners", id), {
        newSwitchCheck: 1,
      });
      setSelectedSwitches([]);
    }
    else {
      setLoadingApiStatus(true);
      const nonPreselectedSwitches = selectedSwitches.filter(switchNode =>
        !preselectedSwitches.some(preSwitch => preSwitch.ip === switchNode.ip)
      );
      await updateDoc(doc(db, "Scanners", id), {
        newSwitchCheck: 1,
      });
      setNewSelectedSwitches(nonPreselectedSwitches);
      handleAddSwitches(nonPreselectedSwitches);
    }
  };

  const handleAddSwitches = async (nonPreselectedSwitches) => {
    const url = 'https://europe-west1-scanner-fb148.cloudfunctions.net/mysql-snmp-network';
    const batchSize = 100; // Adjust the batch size as needed
    const batches = [];
  
    // Split nonPreselectedSwitches into batches
    for (let i = 0; i < nonPreselectedSwitches.length; i += batchSize) {
      const batch = nonPreselectedSwitches.slice(i, i + batchSize).map(switchNode => {
        const switchId = `${id.slice(-4)}${switchNode.ip.replace(/\./g, '')}`;
        return {
          id: parseInt(switchId),
          scanner: id,
          api_model: switchNode.api_model || switchNode.type,
          ip: switchNode.ip || 'default_ip',
          model: switchNode.model || 'Unknown',
          platform: switchNode.description || 'Unknown',
          version: switchNode.software_version || 'Unknown',
          description: switchNode.sysDescr || 'Unknown',
          hostname: switchNode.sysName || 'Unknown'
        };
      });
      batches.push(batch);
    }
  
    setLoading(true);
  
    for (const batch of batches) {
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ switches: batch })
        });
  
        if (!response.ok) {
          const errorData = await response.text();
          console.error(`Failed to add batch: ${errorData}`);
        }
      } catch (error) {
        console.error(`Failed to fetch: ${error.message}`);
      }
    }
  
    setLoading(false);
    toast.success("New Switches Added!");
    setSuccessMessage('Switches added successfully.');
    handleClose();
  };
  
  const validateIP = (ip) => {
    const regex = /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}$/;
    return regex.test(ip);
  };

  const validateSubnetMask = (mask) => {
    const regex = /^(255|254|252|248|240|224|192|128|0)(\.(255|254|252|248|240|224|192|128|0)){3}$/;
    if (!regex.test(mask)) {
      return false;
    }

    const parts = mask.split('.').map(Number);
    let binaryStr = '';
    for (const part of parts) {
      binaryStr += part.toString(2).padStart(8, '0');
    }

    const firstZero = binaryStr.indexOf('0');
    const lastOne = binaryStr.lastIndexOf('1');

    return firstZero === -1 || lastOne < firstZero;
  };

  const updateScan = async (event) => {
    event.preventDefault();

    if (!validateIP(network)) {
      setNetworkError('Invalid network format. Example: 192.168.0.0');
      return;
    }

    if (!validateSubnetMask(mask)) {
      setMaskError('Invalid mask format. Example: 255.255.255.0');
      return;
    }

    setNetworkError('');
    setMaskError('');

    setLoading(true);
    await updateDoc(doc(db, "Scanners", id), {
      updateSNMPNetwork: 1,
      switchesNetwork: network,
      switchesNetmask: mask,
      snmp: snmpCommunity,
    });
  };

  const sanitizeDescription = (description) => {
    return description?.replace(/\/.*\)/, '');
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth classes={{ paperFullWidth: 'MuiDialog-paperFullWidth' }}>
      <Box className="modal-header">
        <Tabs value={activeTab} onChange={handleTabChange} className="tabs-container">
          <Tab label="Search for Switches" className={activeTab === 0 ? 'tab tab-active' : 'tab'} />
          <Tab label="Add Manual" className={activeTab === 1 ? 'tab tab-active' : 'tab'} />
        </Tabs>
        <Box display="flex" alignItems="center" sx={{ ml: 'auto', pr: 2 }}>
          <Typography variant="body1" color="error" sx={{ fontWeight: 'bold' }}>
            IMPORTANT: Remember to set authentication details for API and SSH in Settings.
          </Typography>
          <IconButton onClick={handleSettingsClick}>
            <SettingsIcon />
          </IconButton>
        </Box>
      </Box>
      <DialogContent>
        {activeTab === 0 && (
          <SwitchModalSNMPAdd
            network={network}
            setNetwork={setNetwork}
            mask={mask}
            setMask={setMask}
            snmpCommunity={snmpCommunity}
            setSnmpCommunity={setSnmpCommunity}
            networkError={networkError}
            maskError={maskError}
            loading={loading}
            loadingProgress={loadingProgress}
            updateScan={updateScan}
            filter={filter}
            setFilter={setFilter}
            filteredSwitches={filteredSwitches}
            selectedSwitches={selectedSwitches}
            handleSelectAll={handleSelectAll}
            handleSwitchSelection={handleSwitchSelection}
            completeSwitches={completeSwitches}
            sanitizeDescription={sanitizeDescription}
          />
        )}
        {activeTab === 1 && (
          <SwitchModalManualAdd
            id={id}
            selectedManualSwitches={selectedManualSwitches}
            setSelectedManualSwitches={setSelectedManualSwitches}
            getScannerInfo={getScannerInfo}
            completeSwitches={completeSwitches}
            userManualSwitches={userManualSwitches}
          />
        )}
      </DialogContent>
      <DialogActions>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Typography 
            variant="body1" 
            style={{ 
              marginLeft: '20px', 
              marginTop: '10px', 
              color: (ownedLicenses - totalSelected <= 5) ? 'red' : 'inherit'
            }}
          >
            {totalSelected} / {ownedLicenses} licenses used.
          </Typography>
          <div style={{ marginBottom: '10px' }}>
            {selectedSwitches.length > 0 && !exceedsLicense && (
              <Button onClick={Check_API_status} color="primary" disabled={loading}>
                {loading ? <CircularProgress size={24} /> : 'Add Switches'}
              </Button>
            )}
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default SwitchModal;
