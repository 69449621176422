import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import { updateDoc, doc } from "firebase/firestore";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { db } from '../firebase_config';

const SwitchModalManualAdd = ({id, selectedManualSwitches, setSelectedManualSwitches, getScannerInfo, completeSwitches, userManualSwitches }) => {
  const [manualIP, setManualIP] = useState('');
  const [manualType, setManualType] = useState('');
  const [manualSwitches, setManualSwitches] = useState([]);
  const [manualSwitchError, setManualSwitchError] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect (() => {
    if (getScannerInfo?.[id]?.manualCheck == 0) {
      setFormSubmitted(false);
    }
  }, [getScannerInfo?.[id]?.manualCheck]);

  const getStatusAndColor = (api_access) => {
    let status = "Pending...";
    let color = 'black'; // default color
  
    if (api_access === 20) {
      status = "Pending...";
    } else if (api_access === 21) {
      status = "No ICMP access to switch";
      color = 'red';
    } else if (api_access === 22) {
      status = "No API access to switch or wrong type";
      color = 'red';
    } else if (api_access === 25) {
      status = "OK";
      color = 'green';
    }
  
    return { status, color };
  };
  
  const updateManualSwitchStatus = () => {
    const updatedManualSwitches = userManualSwitches
      .filter(completeSwitch => completeSwitch.api_access >= 20 && completeSwitch.api_access <= 29)
      .map(completeSwitch => {
        const { status, color } = getStatusAndColor(completeSwitch.api_access);
        return {
          id: completeSwitch.id,
          ip: completeSwitch.ip,
          type: completeSwitch.api_model, // Or any other relevant field from completeSwitch
          status: status,
          color: color,
          selected: false, 
          api_access: completeSwitch.api_access
        };
      });
  
    setManualSwitches(updatedManualSwitches);
  };
  
  useEffect(() => {
    updateManualSwitchStatus();
    console.log(manualSwitches);
  }, [userManualSwitches, completeSwitches]);  
  
  const handleManualSwitchSelection = (switchNode) => {
    setManualSwitches(prevManualSwitches => prevManualSwitches.map(sw => {
      if (sw.ip === switchNode.ip) {
        return { ...sw, selected: !sw.selected };
      }
      return sw;
    }));
    const isSelected = selectedManualSwitches.some(sw => sw.ip === switchNode.ip);
    if (switchNode.api_access == 25) {
      let newSelectedSwitches;
      if (isSelected) {
        newSelectedSwitches = selectedManualSwitches.filter(sw => sw.ip !== switchNode.ip);
      } else {
        newSelectedSwitches = [...selectedManualSwitches, switchNode];
      }
      console.log(newSelectedSwitches);
      setSelectedManualSwitches(newSelectedSwitches);
    }
  };

  const handleAddManualSwitch = async () => {
    setFormSubmitted(true);

    if (manualType === '') {
      setManualSwitchError('Please choose a switch model.');
      return;
    }

    if (!manualIP.match(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/)) {
      setManualSwitchError('Please enter a valid IP address.');
      return;
    }
    const ipExists = userManualSwitches.some(switchNode => switchNode.ip === manualIP);
    if (ipExists) {
      setManualSwitchError('The Switch already added.');
      return;
    }
    const switchId = `${id.slice(-4)}${manualIP.replace(/\./g, '')}`;    
    let apiModel = '';
    console.log(switchId);
    if (manualType.includes("AOS")) {
      apiModel = "AOS-S";
    } else if (manualType.includes("CX")) { 
      apiModel = "CX";
    }

    const results = { ip: manualIP, api_status: 20, id: parseInt(switchId), 	api_model: apiModel }

    const data = {
      action: "manual_switch_add",
      switch_data: {
        [id]: results
      }
    };

    try {
      const response = await fetch('https://europe-west1-scanner-fb148.cloudfunctions.net/mysql-snmp-network', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        const errorData = await response.text();
        console.error(`Failed to enable API: ${errorData}`);
      } else {
        console.log('API enabled successfully');
        await updateDoc(doc(db, "Scanners", id), {
          manualCheck: 1,
        });
      }
    } catch (error) {
      console.error(`Failed to fetch: ${error.message}`);
    } finally {
      console.log(`Switch ${manualIP} added successfully.`);;
    }

    const newSwitch = {
      ip: manualIP,
      type: apiModel,
      status: 'Pending',
      selected: false,
    };

    setManualSwitches([...manualSwitches, newSwitch]);
    setManualIP('');
    setManualType('');
    setFormSubmitted(false);
  };

  const deleteManualSwitch = async (switchNode) => {
    console.log(switchNode);
    const data = {
      action: "delete_manual_switch",
      switch_data: {
        id: switchNode.id
      }
    };
    console.log(data);
    try {
      const response = await fetch('https://europe-west1-scanner-fb148.cloudfunctions.net/mysql-snmp-network', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        const errorData = await response.text();
        console.error(`Failed to enable API: ${errorData}`);
      }
    } catch (error) {
      console.error(`Failed to fetch: ${error.message}`);
    } finally {
      console.log(`Switch ${switchNode.ip} deleted successfully.`);
      setManualSwitches(prevManualSwitches => prevManualSwitches.filter(IWswitch => IWswitch.id !== switchNode.id));
    }
  }

  return (
    <Box>
      <Box display="flex" gap="10px">
        <TextField
          label="IP Address"
          value={manualIP}
          onChange={(e) => setManualIP(e.target.value)}
          fullWidth
          margin="normal"
          error={formSubmitted && !manualIP}
          helperText={formSubmitted && !manualIP ? 'Please enter a valid IP address.' : ''}
        />
        <TextField
          label="Switch Type"
          select
          value={manualType}
          onChange={(e) => setManualType(e.target.value)}
          fullWidth
          margin="normal"
          SelectProps={{
            native: true,
          }}
          error={formSubmitted && !manualType}
          helperText={formSubmitted && !manualType ? 'Please choose a switch model.' : ''}
        >
          <option value=""></option>
          <option value="Aruba CX Switch (eg. CX6000)">Aruba CX Switch (eg. CX6000)</option>
          <option value="Aruba AOS Switch (eg. 2530, 2930)">Aruba AOS Switch (eg. 2530, 2930)</option>
        </TextField>
      </Box>
      {manualSwitchError && (
        <Typography color="error" variant="body1">
          {manualSwitchError}
        </Typography>
      )}
      <Button variant="contained" color="primary" onClick={handleAddManualSwitch}>
        Check Switch
      </Button>
      <Box mt={2}>
      {manualSwitches.length > 0 && (<div style={{ maxHeight: '400px', overflowY: 'auto', marginTop: '20px', width: '100%' }} >
      <table style={{ backgroundColor: "rgba(0,0,0,0.05)", width: '100%', borderRadius: '10px' }}>
  <thead>
    <tr>
      <th style={{ width: '150px', textAlign: 'center' }}>IP</th>
      <th style={{ width: '200px', textAlign: 'center' }}>Type</th>
      <th style={{ width: '250px', textAlign: 'center' }}>Status</th>
      <th style={{ width: '100px', textAlign: 'center' }}>Add</th>
      <th style={{ width: '100px', textAlign: 'center' }}>Delete</th>
    </tr>
  </thead>
  <tbody>
    {manualSwitches.map((switchNode, index) => (
      <tr key={index}>
        <td style={{ textAlign: 'center', width: '150px' }}>{switchNode.ip}</td>
        <td style={{ textAlign: 'center', width: '200px' }}>{switchNode.type}</td>
        <td style={{ textAlign: 'center', width: '250px', color: switchNode.color }}>{switchNode.status}</td>
        <td style={{ textAlign: 'center', width: '50px' }}>
          <input
            type="checkbox"
            checked={switchNode.selected}
            disabled={switchNode.api_access !== 25}
            onChange={() => handleManualSwitchSelection(switchNode)}
          />
        </td>
        <td style={{ textAlign: 'center', width: '50px' }}>
          <DeleteOutlineIcon
            style={{ cursor: 'pointer' }}
            onClick={() => deleteManualSwitch(switchNode)}
          />
        </td>
      </tr>
    ))}
  </tbody>
</table>

</div>


)}

      </Box>
    </Box>
  );
};

export default SwitchModalManualAdd;
