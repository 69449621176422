import React from 'react';
import { FormControl, InputLabel, OutlinedInput, FormHelperText } from '@mui/material';
import { tokens } from "../theme";
import { useTheme } from '@mui/system';

const TraceCustomIP = ({ IP, setIP }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <div>
        <div style={{ textAlign: 'left', marginBottom: "10px" }}>
        <h3 className="settings" style={{ color: colors.blueAccent[100], marginBottom:"0px", marginTop:"20px" }}>
            Trace IP
        </h3>
        <hr style={{ width: "100px", margin: 0, borderTop: `2px solid ${colors.blueAccent[100]}` }} />
        <FormHelperText sx={{ color: colors.grey[100], fontSize: "16px" }}>
          Type an IP that you want to trace
        </FormHelperText>
        </div>
      <FormControl sx={{ marginTop: 1, width: "100%" }} variant="outlined">
        <InputLabel
          htmlFor="ip"
          sx={{
            color: colors.grey[100],
            '&.Mui-focused': {
              color: colors.grey[100],
            },
            '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
              color: colors.grey[100],
            },
            '&.MuiInputLabel-outlined': {
              color: 'grey',
            },
          }}
        >
          IP
        </InputLabel>
        <OutlinedInput
          id="ip"
          name="ip"
          type='text'
          value={IP}
          onChange={(event) => setIP(event.target.value)}
          label="IP"
          sx={{
            "& .MuiOutlinedInput-input": {
              color: colors.grey[100],
            },
            '& .MuiOutlinedInput-input::placeholder': {
              color: colors.grey[100],
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.grey[100],
            },
            '&:hover > .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.grey[100],
            },
            '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.grey[100],
            },
            '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
              color: colors.grey[100],
            },
          }}
        />

      </FormControl>
    </div>
  );
};

export default TraceCustomIP;
